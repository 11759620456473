import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Tabs = styled.div`
  width: ${({ width }) => width ? width : '100%'};
  height: 38px;
  display: flex;
  align-items: center;
  position: ${({ fixedPosition }) => fixedPosition ? 'fixed' : ''};
  background-color: #fff;
  z-index: 99999;
`;

const Tab = styled.div`
  height: 38px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  border-bottom: 1px solid #DCDDE1;
  
  &.active {
     font-weight: 700;
     height: 37px;
     border-bottom: 2px solid #77B36B;
  }
`;

const BaseTabs = ({ tabs, activeTab, switchTab, width, fixedPosition = false }) => {
  return (
    <Tabs
      fixedPosition={fixedPosition}
      width={width}
    >
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          className={activeTab === tab.value ? 'active' : ''}
          onClick={() => switchTab(tab.value)}
        >
          <div>{tab.icon}</div>
          <div>{tab.label}</div>
        </Tab>
      ))}
    </Tabs>
  );
};

BaseTabs.propTypes = {
  tabs: PropTypes.array,
  activeTab: PropTypes.string,
  switchTab: PropTypes.func,
  width: PropTypes.string,
  fixedPosition: PropTypes.bool,
};

export default BaseTabs;
