import React, { Fragment } from 'react';
import styled  from 'styled-components';
import BaseColorPicker from '../Common/BaseColorPicker';
import BaseSlider from '../Common/BaseSlider';
import BaseSwitch from '../Common/BaseSwitch';
import { useDispatch, useSelector } from 'react-redux';
import {
  setShadowBlur,
  setShadowColor,
  setShadowHorizontalOffset,
  setShadowVerticalOffset,
  toggleShadow,
} from '../../store/builder';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding-top: 16px;
`;

const Element = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  padding: 8px 0;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #3C4049;
  flex: 1;
`;

const Slider = styled(BaseSlider)`
  width: 122px;
`;

const TextStylePanelShadow = () => {
  const shadow = useSelector(({ builder }) => builder.text.shadow);
  const dispatch = useDispatch();

  return (
    <Fragment>
      <BaseSwitch
        label="Shadow"
        checked={shadow.applied}
        toggle={() => dispatch(toggleShadow())}
      />
      {
        shadow.applied && <Container>
          <Element>
            <Label>Horizontal offset</Label>
            <Slider
              defaultValue={shadow.horizontalOffset}
              min={-10}
              max={10}
              onChange={(offset) => dispatch(setShadowHorizontalOffset(offset))}
            />
          </Element>
          <Element>
            <Label>Vertical offset</Label>
            <Slider
              defaultValue={shadow.verticalOffset}
              min={-10}
              max={10}
              onChange={(offset) => dispatch(setShadowVerticalOffset(offset))}
            />
          </Element>
          <Element>
            <Label>Blur</Label>
            <Slider
              defaultValue={shadow.blur}
              min={0}
              max={50}
              onChange={(blur) => dispatch(setShadowBlur(blur))}
            />
          </Element>
          <Element>
            <Label>Shadow color</Label>
            <BaseColorPicker
              color={shadow.color}
              setColor={(color) => dispatch(setShadowColor(color))}
            />
          </Element>
        </Container>
      }
    </Fragment>
  );
};

export default TextStylePanelShadow;
