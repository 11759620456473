import React from 'react';
import { Dialog } from '@mui/material';
import NewProjectBasics from './NewProjectBasics';
import NewProjectTemplates from './NewProjectTemplates';
import { useDispatch, useSelector } from 'react-redux';
import { createProject, setStep, toggleNewProjectOpen } from '../../store/project';
import { fetchCategories, fetchTemplates, setSearch, setSelectedCategory } from '../../store/template';

const NewProject = () => {
  const step = useSelector(state => state.project.step);
  const open = useSelector(state => state.project.newProjectOpen);
  const dispatch = useDispatch();

  const save = async () => {
    await dispatch(createProject());
    await dispatch(setSearch(null));
    await dispatch(setSelectedCategory(null));
    dispatch(setStep(1));
  };
  
  const handleNextStep = async () => {
    await dispatch(fetchCategories());
    await dispatch(fetchTemplates());
    dispatch(setStep(2));
  };

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      onClose={() => dispatch(toggleNewProjectOpen())}
    >
      {
        step === 1
          ? <NewProjectBasics
              goToNextStep={handleNextStep}
            />
          : <NewProjectTemplates
              goBack={() => dispatch(setStep(1))}
              save={save}
            />
      }
    </Dialog>
  );
};

export default NewProject;
