import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  visible: true,
};

export const layers = createSlice({
  name: 'layers',
  initialState,
  reducers: {
    toggleVisible(state) {
      state.visible = !state.visible;
    },
  },
});

export const {
  toggleVisible,
} = layers.actions;

export default layers.reducer;
