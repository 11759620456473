import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useMousePosition from '../../hooks/useMousePosition';

const Cursor = ({ show, size = 15 }) => {
  if ('ontouchstart' in window 
    || navigator.MaxTouchPoints > 0 
    || navigator.msMaxTouchPoints > 0
  ) {
    return null;
  }
  
  const { clientX, clientY } = useMousePosition();
  const [isVisible, setIsVisible] = useState(true);
  
  useEffect(() => {
    const handleMouseEnter = () => setIsVisible(true);
    const handleMouseLeave = () => setIsVisible(false);
    document.body.addEventListener('mouseenter', handleMouseEnter);
    document.body.addEventListener('mouseleave', handleMouseLeave);
    return () => {
      document.body.removeEventListener('mouseenter',   handleMouseEnter);
      document.body.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);
  
  const scale = (size * 100 / 15) / 100;
  const strokeWidth = 0.5 * 50 / size;
  
  return (
    <div 
      style={{ 
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        pointerEvents: 'none',
        display: show ? 'initial' : 'none',
      }}
    >
      <svg
        width={50}
        height={50}
        viewBox="0 0 50 50"
        style={{
          position: 'absolute',
          pointerEvents: 'none',
          left: clientX,
          top: clientY,
          transform: `translate(-50%, -50%) scale(${scale})`,
          stroke: '#fff',
          strokeWidth: strokeWidth,
          strokeDasharray: `${strokeWidth},${strokeWidth}`,
          fill: 'rgba(255,255,255,.2)',
          transition: 'transform .2s ease-in-out',
          opacity: isVisible && clientX > 1 ? 1 : 0,
        }}
      >
        <circle
          cx="25"
          cy="25"
          r="8"
        />
      </svg>
    </div>
  );
};

Cursor.propTypes = {
    show: PropTypes.bool,
    size: PropTypes.number,
  };

export default Cursor;