import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as CanvasIcon } from '../../assets/toolbar/canvas.svg';
import { useDispatch, useSelector } from 'react-redux';
import BaseButton from '../Common/BaseButton';
import { addLayer } from '../../store/builder';
import { setPreviewedAsset } from '../../store/library';
import { getImageDimensions, prepareLayerName, recalculateImageDimensions } from '../../helpers';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  position: relative;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 680px;
`;

const Image = styled.img`
  width: 680px;
  border-radius: 6px;
`;

const Actions = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
  gap: 16px;
`;

const Action = styled(BaseButton)`
   background-color: #FFFFFF;
   border: 1px solid rgba(0, 0, 0, 0.2);
   color: #1A1C23;
   
   svg {
    stroke: #1A1C23;
   }
`;

const LibraryPreviewLarge = ({ image, name }) => {
  const project = useSelector(state => state.builder.project);
  const dispatch = useDispatch();

  const addToCanvas = async () => {
    const imageDimensions = await getImageDimensions(image);
    const { width, height } =
      recalculateImageDimensions(
        imageDimensions.w,
        imageDimensions.h,
        project.width / 4,
        project.height / 4,
      );
    await dispatch(addLayer({
      id: project.lastLayerId + 1,
      type: 'image',
      name: prepareLayerName(name),
      content: image,
      left: project.width / 2 - width / 2,
      top: project.height / 2 - height / 2,
      visible: true,
      width,
      height,
    }));

    dispatch(setPreviewedAsset(null));
  };

  return (
    <Container>
      <ImageContainer>
        <Image
          src={image}
          alt="..."
        />
        <Actions>
          <Action
            onClick={addToCanvas}
          >
            <CanvasIcon />
            Add to Canvas
          </Action>
        </Actions>
      </ImageContainer>
    </Container>
  );
};

LibraryPreviewLarge.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
};

export default LibraryPreviewLarge;
