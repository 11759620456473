import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  prompt: '',
  wordsLimit: null,
  chatId: null,
  result: '',
  waitOpened: false,
  generationEnded: false,
};

export const textGenerator = createSlice({
  name: 'textGenerator',
  initialState,
  reducers: {
    clearResult(state) {
      state.result = '';
    },
    updateResult(state, { payload }) {
      state.result += payload;
    },
    setPrompt(state, { payload }) {
      state.prompt = payload;
    },
    setWordsLimit(state, { payload }) {
      state.wordsLimit = payload;
    },
    toggleWordLimit(state) {
      state.wordsLimit = state.wordsLimit === null ? 10 : null;
    },
    setChatId(state, { payload }) {
      state.chatId = payload;
    },
    setGenerationEnded(state, { payload }) {
      state.generationEnded = payload;
    },
    toggleWaitOpened(state) {
      state.waitOpened = !state.waitOpened;
    },
    clearTextGeneratorState(state) {
      state.prompt = '';
      state.wordsLimit = null;
      state.chatId = null;
      state.result = '';
      state.waitOpened = false;
      state.generationEnded = false;
    },
  },
});

export const {
  clearResult,
  setPrompt,
  setWordsLimit,
  setChatId,
  toggleWaitOpened,
  toggleWordLimit,
  setGenerationEnded,
  updateResult,
  clearTextGeneratorState,
} = textGenerator.actions;

export default textGenerator.reducer;
