import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { ReactComponent as GenerateIcon } from '../../assets/toolbar/generate.svg';
import { ReactComponent as CloseIcon } from '../../assets/times.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setPreviewedAsset } from '../../store/library';
import LibraryPreviewLarge from './LibraryPreviewLarge';

const Container = styled(Dialog)`
  .MuiPaper-root {
    width: 712px;
  }
`;

const Title = styled(DialogTitle)`
  display: flex;
  gap: 10px;
  align-items: center;

  .icon {
    stroke: #77B26B;
  }
  
  .title {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #3C4049;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .close {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #3C4049;
    cursor: pointer;
  }
  
  .close-icon {
    cursor: pointer;
  }
`;

const Content = styled(DialogContent)`

`;

const LibraryPreview = () => {
  const assets = useSelector(state => state.library.assets);
  const previewedAssetIndex = useSelector(state => state.library.previewedAsset);
  const dispatch = useDispatch();

  const close = () => {
    dispatch(setPreviewedAsset(null));
  };

  return (
    <Container
      maxWidth="1032px"
      open={previewedAssetIndex !== null}
      onClose={close}
    >
      {
        !!assets.length && previewedAssetIndex !== null &&
        <Fragment>
          <Title>
            <GenerateIcon className="icon" />
            <span className="title">
          { assets[previewedAssetIndex]?.name }
        </span>
            <span
              className="close"
              onClick={close}
            >
          Close
        </span>
            <CloseIcon className="close-icon" onClick={close} />
          </Title>
          <Content>
            <LibraryPreviewLarge
              image={assets[previewedAssetIndex]?.content}
              name={assets[previewedAssetIndex]?.name}
            />
          </Content>
        </Fragment>
      }
    </Container>
  );
};

export default LibraryPreview;
