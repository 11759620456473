export const STANDARD_PLAN_KEY = 'Standard';
export const BUSINESS_PLAN_KEY = 'Business';
export const IMAGE_KEY = 'image';
export const TEXT_KEY = 'text';

export const PLAN_LIMIT = {
    [STANDARD_PLAN_KEY]: {
        [IMAGE_KEY]: 500,
        [TEXT_KEY]: 5000,
    },
    [BUSINESS_PLAN_KEY]: {
        [IMAGE_KEY]: 5000,
        [TEXT_KEY]: 50000,
    },
};

export const CREDITS_PRICES = {
    [IMAGE_KEY]: {
        price: 0.01,
        min: 10,
        step: 10,
        default: 100,
        max: 500,
    },
    [TEXT_KEY]: {
        price: 0.01,
        min: 100,
        step: 100,
        default: 500,
        max: 5000,
    },
};

export const TEXT_LIMITS = {
    strokeWidth: {
        min: 0,
        max: 10,
    },
    gradientAngle: {
        min: 0,
        max: 360,
    },
};
