import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ReactComponent as Plus } from '../../assets/plus.svg';
import { useDispatch } from 'react-redux';
import { toggleNewProjectOpen } from '../../store/project';

const Icon = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(36.79deg, #D5FDC9 -7.15%, #F5FBE2 88.31%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: #3C4049;
`;

const Container = styled.div` 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 16px;
  width: 234px;
  height: 230px;
  background-color: #fff;
  border-radius: 6px;
  cursor: pointer;
  
  &:hover {
    ${Label} {
        color: #77B36B;
    }
  }
`;

const RecentProjectsNew = () => {
  const dispatch = useDispatch();

  return (
    <Fragment>
      <Container onClick={() => dispatch(toggleNewProjectOpen())}>
        <Icon>
          <Plus />
        </Icon>
        <Label>Create new project</Label>
      </Container>
    </Fragment>
  );
};

export default RecentProjectsNew;
