import React from 'react';
import PropTypes from 'prop-types';
import { setDraggedImage } from '../../store/builder';
import { useDispatch } from 'react-redux';
import BaseImageItem from '../Common/BaseImageItem';

const ImagePanelImporterImage = ({ image }) => {
  const dispatch = useDispatch();

  return (
    <BaseImageItem
      image={image}
      draggable
      onDragStart={() => {
        dispatch(setDraggedImage({ content: image.content, name: image.name }));
      }}
    />
  );
};

ImagePanelImporterImage.propTypes = {
  image: PropTypes.object,
};

export default ImagePanelImporterImage;
