import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ReactComponent as CaretLeftIcon } from '../../assets/caret-left.svg';
import { ReactComponent as CaretRightIcon } from '../../assets/caret-right.svg';

const StyledPanel = styled.div`
  position: fixed;
  left: 0;
  top: 90px;
  overflow-y: auto;
  z-index: 999;
  background: ${props => props.collapsable ? 'transparent' : '#FFFFFF'};
  width: calc(${props => props.collapsable ? 24 : 0}px + ${props => props.width ? props.width : 280}px);
  height: calc(100vh - 90px);
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);

  ${props => props.collapsable ? 
    `&.slide-out {
      left: 24px;
    }` 
    :
    `&.slide-out {
      > div:first-child {
        &:has(> svg:first-child) {
          display: none;
        }
      }
    }`
  }
`;

const Container = styled.div`
  background: #FFFFFF;
  width: ${props => props.width ? props.width : 280}px;
  overflow-y: auto;
  height: calc(100vh - 90px);
`;

const Collapser = styled.div`
  position: absolute;
  right: 0;
  top: 14px;
  background: #FFFFFF;
  border-radius: 0px 4px 4px 0px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
`;

const BasePanel = ({ children, panel, width, collapsable = false, ...rest }) => {
  const selectedPanel = useSelector(state => state.builder.panel);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (selectedPanel === panel)
      setVisible(true);
  }, [selectedPanel]);

  if (selectedPanel !== panel)
    return <></>;

  let className = 'slide-in';
  if (!visible) {
    className = 'slide-out';
  }

  if (!collapsable)
    return ( 
      <StyledPanel
        { ...rest }
        collapsable={collapsable}
        width={width}
        className={`${className} ${rest?.className ? rest.className : '' }`}
      >
        { children }
      </StyledPanel>
    );

  return (
    <StyledPanel
      { ...rest }
      collapsable={collapsable}
      width={width}
      className={`${className} ${rest?.className ? rest.className : '' }`}
    >
      <Collapser onClick={() => setVisible(!visible)}>
        { visible ?  <CaretLeftIcon /> : <CaretRightIcon /> }
      </Collapser>
      <Container width={width}>
        { children }
      </Container>
    </StyledPanel>
  );
};

BasePanel.propTypes = {
  children: PropTypes.any,
  panel: PropTypes.string,
  width: PropTypes.number,
  collapsable: PropTypes.bool,
};

export default BasePanel;
