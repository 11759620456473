import React, { useRef } from 'react';
import BaseTextBox from '../Common/BaseTextBox';
import { SimpleButton } from '../Common/BaseButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { updatePasword } from '../../store/resetPassword';
import FormError from '../Common/FormError';
    
const validationSchema = yup.object({
  password: yup.string().required('Required field'),
  confirmPassword: 
    yup.string()
    .required('Required field')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const PasswordForm = () => {
  const error = useSelector(({ resetPassword }) => resetPassword.passwordError);
    const dispatch = useDispatch();
    const passRef = useRef();
    const confirmRef = useRef();
    const submitRef = useRef();
    const formik = useFormik({
      initialValues: {
        password: '',
        confirmPassword: '',
      },
      onSubmit: values => {
        dispatch(updatePasword(values.password));
        formik.handleReset();
      },
      validationSchema,
    });
    return (
      <>
        <h2>Create new password.</h2>
        {error?.length &&
            <FormError text={error} />
        }
        <BaseTextBox
          autoFocus
          ref={passRef}
          label="Password"
          placeholder="Enter password"
          background="transparent"
          inputBackground="#fff"
          type="password"
          name="password"
          value={formik.values.password}
          errorText={formik.touched.password && formik.errors.password}
          onChangeFormik={formik.handleChange}
          onFormikBlur={formik.handleBlur}
          onEnterClick={() => confirmRef.current.focus()}
          onTabClick={() => confirmRef.current.focus()}
        />
        <BaseTextBox
          ref={confirmRef}
          label="Confirm password"
          placeholder="Confirm password"
          background="transparent"
          inputBackground="#fff"
          type="password"
          name="confirmPassword"
          value={formik.values.confirmPassword}
          errorText={formik.touched.confirmPassword && formik.errors.confirmPassword}
          onChangeFormik={formik.handleChange}
          onFormikBlur={formik.handleBlur}
          onEnterClick={formik.submitForm}
          onTabClick={() => formik.isValid ? submitRef.current.focus() : passRef.current.focus()}
          />
        <SimpleButton 
          ref={submitRef}
          onTabClick={() => passRef.current.focus()}
          width={400} 
          disabled={formik.touched.password && formik.touched.confirmPassword && !formik.isValid} 
          onClick={formik.submitForm}
        >
            Change password
        </SimpleButton>
      </>
    );
};

export default PasswordForm;