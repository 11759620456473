import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as CaretIcon } from '../../assets/caret-down.svg';
import { SketchPicker } from 'react-color';
import { Popover } from '@mui/material';
import { rgbaToHex } from '../../helpers';

const Container = styled.div`
  position: relative;
`;

const Button = styled.div`
  width: 80px;
  height: 40px;
  background: #F5F5F5;
  border: 1px solid #DCDDE1;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  gap: 2px;
  cursor: pointer;
  
  svg {
    stroke: #1A1C23;
  }
`;

const Display = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 2px;
`;

const TransparentWrapper = styled.div`
  position: relative;
`;

const Transparent = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 2px;
  border: 1px solid #7C8394;
`;

const RedLine = styled.div`
  border-bottom: 1px solid #f37171;
  width: calc(26px*1.33);
  border-radius: 1px;
  transform: rotate(-45deg);
  transform-origin: left;
  bottom: 0;
  padding: 1px;
  position: absolute;
`;

const BaseColorPicker = ({ color, setColor }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(color);
  const containerRef = useRef();

  useEffect(() => {
    if(color !== value) setValue(color);
  }, [color]);

  const handleChangeComplete = (color) => {
    const hexA = rgbaToHex(color.rgb);
    setValue(hexA);
    setColor(hexA);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const isTransparent = color.length === 9 && color[7] === '0' && color[8] === '0';
  const open = Boolean(anchorEl);

  useEffect(() => {
    setTimeout(() => {
      const container = document.querySelector('[title="transparent"]');
      if (container) container.className = container.className + ' redCrossLine';
    }, 100);
  }, [anchorEl]);

  return (
    <Container ref={containerRef}>
      <Button onClick={handleClick}>
        {isTransparent ? 
          <TransparentWrapper>
            <Transparent/>
            <RedLine />
          </TransparentWrapper> :
          <Display style={{ backgroundColor: color }} />
        }
        <CaretIcon />
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SketchPicker
          color={value}
          onChange={(color) => setValue(rgbaToHex(color.rgb))}
          onChangeComplete={handleChangeComplete}
          presetColors={[{ color: '#00000000', title: 'transparent' }, '#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF']}
        />
      </Popover>

    </Container>
  );
};

BaseColorPicker.propTypes = {
  color: PropTypes.string,
  setColor: PropTypes.func,
  onChangeComplete: PropTypes.func,
};

export default BaseColorPicker;
