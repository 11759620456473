import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import admin from './admin';
import builder from './builder';
import filter from './filter';
import layers from './layers';
import library from './library';
import loader from './loader';
import pixabay from './pixabay';
import project from './project';
import user from './user';
import imageGenerator from './imageGenerator';
import imageImporter from './imageImporter';
import resetPassword from './resetPassword';
import template from './template';
import textGenerator from './textGenerator';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['token'],
};

export const store = configureStore({
  reducer: {
    admin,
    builder,
    filter,
    imageGenerator,
    imageImporter,
    layers,
    library,
    loader,
    pixabay,
    project,
    resetPassword,
    template,
    textGenerator,
    user: persistReducer(persistConfig, user),
  },
});

export const persistor = persistStore(store);
