import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Custom from '../../assets/project-types/custom.svg';
import Facebook from '../../assets/project-types/facebook.svg';
import Instagram from '../../assets/project-types/instagram.svg';
import Youtube from '../../assets/project-types/youtube.svg';
import Logo from '../../assets/project-types/logo.svg';
import Banner from '../../assets/project-types/banner.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setProjectHeight, setProjectSize, setProjectWidth } from '../../store/project';
import BaseTextBox from '../Common/BaseTextBox';

const ICONS = {
  custom: Custom,
  facebook: Facebook,
  instagram: Instagram,
  youtube: Youtube,
  logo: Logo,
  banner: Banner,
};

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 4px 8px 16px;
  gap: 2px;
  width: 412px;
  height: 48px;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #989FAD;
  cursor: pointer;
  
  &:hover, &.selected {
    border: 2px solid #77B36B;
  }
`;

const Image = styled.img`
  margin-right: 14px;
`;

const Name = styled.div`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: #1A1C23;
  flex: 1;
`;

const Size = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #7C8394;
  margin-right: 12px;
`;

const SizeForm = styled.div`
  display: flex;
  gap: 8px;
`;

const TextBox = styled(BaseTextBox)`
  margin-bottom: 0;
`;

const NewProjectBasicsSizesOption = ({ id, icon, label, width, height }) => {
  const size = useSelector(({ project }) => project.project.size);
  const customWidth = useSelector(({ project }) => project.project.width);
  const customHeight = useSelector(({ project }) => project.project.height);
  const dispatch = useDispatch();

  const hasPredefinedSize = width !== null && height !== null;

  return (
    <Container
      className={id === size ? 'selected' : ''}
      onClick={() => dispatch(setProjectSize(id))}
    >
      <Image src={ICONS[icon]} alt={label} />
      <Name>{label}</Name>
      { hasPredefinedSize && <Size>{width + ' x ' + height}</Size> }
      {
        !hasPredefinedSize &&
        <SizeForm>
          <TextBox
            value={customWidth}
            width={79}
            height={36}
            bottom={0}
            before="W"
            background={'#F5F5F5'}
            onChange={v => dispatch(setProjectWidth(v))}
          />
          <TextBox
            value={customHeight}
            width={79}
            height={36}
            bottom={0}
            before="H"
            background={'#F5F5F5'}
            onChange={v => dispatch(setProjectHeight(v))}
          />
        </SizeForm>
      }
    </Container>
  );
};

NewProjectBasicsSizesOption.propTypes = {
  id: PropTypes.number,
  icon: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default NewProjectBasicsSizesOption;
