import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api';

const initialState = {
  categories: [],
  selectedCategory: null,
  search: null,
  templates: [],
  pagination: {
    page: 1,
    perPage: 9,
    isLastPage: false,
    loading: false,
  },
};

export const fetchCategories = createAsyncThunk(
  'template/fetchCategories',
  async (arg, { getState }) => {
    const { project } = getState();

    const size = project.project?.size || 1;
    return await api.templateCategory.fetch({ size });
  },
);

export const fetchTemplates = createAsyncThunk(
  'template/fetchTemplates',
  async (arg, { getState }) => {
    const { template, project } = getState();

    const size = project.project?.size || 1;
    return await api.template.fetch({
      size,
      category: template.selectedCategory,
      search: template.search,
      limit: template.pagination.perPage,
    }, '', false);
  },
);

export const fetchTemplatesNextPage = createAsyncThunk(
  'template/fetchTemplatesNextPage',
  async (arg, { getState }) => {
    const { template, project } = getState();

    const size = project.project?.size || 1;
    return await api.template.fetch({
      size,
      category: template.selectedCategory,
      search: template.search,
      limit: template.pagination.perPage,
      offset: template.pagination.perPage * template.pagination.page,
    }, '', false);
  },
);

export const template = createSlice({
  name: 'template',
  initialState,
  reducers: {
    setSelectedCategory(state, { payload }) {
      state.selectedCategory = payload;
    },
    setSearch(state, { payload }) {
      state.search = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.fulfilled, (state, { payload }) => {
      const categoriesToSet = payload.filter(c => c.templatesCount > 0);
      state.categories = [
        {
          id: null,
          name: 'All templates',
          templatesCount: payload.reduce((count, el) => count + parseInt(el.templatesCount), 1),
        },
        ...categoriesToSet,
      ];
    });

    builder.addCase(fetchTemplates.pending, (state) => {
      state.templates = [];
      state.pagination.loading = true;
    });

    builder.addCase(fetchTemplates.fulfilled, (state, { payload }) => {
      state.templates = payload;
      state.pagination.page = 1;
      state.pagination.isLastPage = false;
      state.pagination.loading = false;
    });

    builder.addCase(fetchTemplatesNextPage.pending, (state) => {
      state.pagination.loading = true;
    });

    builder.addCase(fetchTemplatesNextPage.fulfilled, (state, { payload }) => {
      state.templates = [...state.templates, ...payload];
      state.pagination.page += 1;
      if (payload.length < state.pagination.perPage) state.pagination.isLastPage = true;
      state.pagination.loading = false;
    });
  },
});

export const { setSelectedCategory, setSearch } = template.actions;

export default template.reducer;
