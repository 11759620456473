import React from 'react';
import styled from 'styled-components';
import BaseTabs from '../Common/BaseTabs';
import ImageImporterUpload from './ImageImporterUpload';
import ImageImporterUrl from './ImageImporterUrl';
import ImageImporterPixabay from './ImageImporterPixabay';
import { Dialog, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as PixabayIcon } from '../../assets/pixabay.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setReferenceImageSource } from '../../store/imageGenerator';

const StyledImageImporter = styled(Dialog)`
  .MuiPaper-root {
    width: 460px;
  }
`;

const Content = styled(DialogContent)`
  padding: 0 !important;
`;

const ImageImporter = ({ open, handleClose }) => {
  const referenceImageSource = useSelector(state => state.imageGenerator.referenceImageSource);
  const dispatch = useDispatch();

  const components = {
    upload: <ImageImporterUpload handleClose={handleClose} />,
    url: <ImageImporterUrl handleClose={handleClose} />,
    pixabay: <ImageImporterPixabay handleClose={handleClose} />,
  };

  const tabs = [
    { label: 'Import image', value: 'upload' },
    { label: 'import by Url', value: 'url' },
    { label: 'Pixabay', value: 'pixabay', icon: <PixabayIcon /> },
  ];

  return (
    <StyledImageImporter
      maxWidth="xl"
      open={open}
      onClose={handleClose}
    >
      <Content>
        <BaseTabs
          tabs={tabs}
          activeTab={referenceImageSource}
          switchTab={(source) => dispatch(setReferenceImageSource(source))}
        />
        { components[referenceImageSource] }
      </Content>
    </StyledImageImporter>
  );
};

ImageImporter.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default ImageImporter;
