import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/times.svg';
import { ReactComponent as LockIcon } from '../../assets/lock.svg';
import { CancelButton } from '../Common/BaseButton';
import { SimpleButton } from '../Common/BaseButton';
import { useDispatch, useSelector } from 'react-redux';
import { setExportDialog, setExportRequested } from '../../store/builder';
import BaseRadioButtonGroup from '../Common/BaseRadioButtonGroup';
import BaseTextBox from '../Common/BaseTextBox';

const Container = styled(Dialog)`
  .MuiPaper-root {
    width: 460px;
    height: 304px;
  }
`;

const Header = styled(DialogTitle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  svg {
    cursor: pointer;
  }
`;

const Content = styled(DialogContent)`

`;

const Actions = styled(DialogActions)`
  display: flex;
  gap: 16px;
  border-top: 1px solid #DCDDE1;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #3C4049;
  flex: 1;
`;

const SelectSizeWrap = styled.div`
  display: flex;
  border-top: 1px solid #DCDDE1;
  padding-top: 20px;
`;

const SelectSizeInputs = styled.div`
  width: 50%;
  > div {
    display: flex;
    align-items: center;
    padding: 6px;
  }
`;

const SelectSizeNote = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  > div {
    &:first-child {
      flex-direction: column;
      justify-content: center;
      svg {
        margin-left: 4px;
      }
    }
  }
`;

const LockBox = styled.div`
  height: 100%;
  display: flex;
  span {
    border-right: 1px solid #7c8394;
    width: 14px;
    height: 14px;
  }
  span:first-child {
    margin-bottom: 2px;
    border-top: 1px solid #7c8394;
    border-radius: 0 5px 0 0;
  }
  span:last-child {
    margin-top: 2px;
    border-bottom: 1px solid #7c8394;
    border-radius: 0 0 5px 0;
  }
`;

const ErrorBox = styled.div`
  background: #fbe3e3;
  border-radius: 6px;
  color: #f37171;
  display: block;
  padding: 10px;
  font-size: 0.75rem;
  line-height: 0.975rem;
  margin-left: 10px;
  height: fit-content;
`;

const ExportDialog = () => {
  const [fileFormat, setFileFormat] = useState('PNG');
  const [error, setError] = useState('');
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const open = useSelector(({ builder }) => builder.exportDialog);
  const project = useSelector(({ builder }) => builder.project);
  const dispatch = useDispatch();

  useEffect(() => {
    setWidth(project.width);
  }, [project.width]);

  useEffect(() => {
    setHeight(project.height);
  }, [project.height]);

  const handleWidth = (v) => {
    if (error) setError('');
    if (v < 0) return;
    if (v > project.width) setError('width');
    const newHeight = project.height * v / project.width;
    setWidth(v);
    setHeight(newHeight);
  };

  const handleHeight = (v) => {
    if (error) setError('');
    if (v < 0) return;
    if (v > project.height) setError('height');
    const newWidth = project.width * v / project.height;
    setHeight(v);
    setWidth(newWidth);
  };

  const close = () => {
    dispatch(setExportDialog(false));
  };

  const requestExport = () => {
    dispatch(setExportRequested({
      fileFormat,
      height,
      width,
    }));
  };

  return (
    <Container
      open={open}
      onClose={close}
    >
      <Header>
        <span>Export</span>
        <CloseIcon className="close-icon" onClick={close} />
      </Header>
      <Content>
        <BaseRadioButtonGroup
          label="File format:"
          options={['PNG', 'JPG']}
          value={fileFormat}
          onChange={setFileFormat}
        />
        <SelectSizeWrap>
          <SelectSizeInputs>
            <div>
              <Label>Width</Label>
              <BaseTextBox
                width={80}
                value={width}
                onChange={handleWidth}
                background={'rgb(245, 245, 245)'}
                bottom={0}
                type='number'
                borderColor={error === 'width' && '#f37171'}
                color={error === 'width' && 'error'}
              />
            </div>
            <div>
              <Label>Height</Label>
              <BaseTextBox
                width={80}
                value={height}
                onChange={handleHeight}
                background={'rgb(245, 245, 245)'}
                bottom={0}
                type='number'
                borderColor={error === 'height' && '#f37171'}
                color={error === 'height' && 'error'}
              />
            </div>
          </SelectSizeInputs>
          <SelectSizeNote>
            <LockBox>
              <span />
              <LockIcon/>
              <span />
            </LockBox>
            {!!error && 
              <ErrorBox>
                <b>Note: </b>
                To prevent a reduction of image quality, you may scale the image down, but not up
              </ErrorBox>
            }
          </SelectSizeNote>
        </SelectSizeWrap>
      </Content>
      <Actions>
        <CancelButton
          width={206}
          onClick={close}
        />
        <SimpleButton
          width={206}
          onClick={requestExport}
          disabled={error}
        >
          Export
        </SimpleButton>
      </Actions>
    </Container>
  );
};

export default ExportDialog;
