import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CanvasImage from './CanvasImage';
import CanvasText from './CanvasText';
import { Group, Layer } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import { emptySelectedLayer, mergeLayers, setMergeDownLayer, setPanel, setSelectedLayer } from '../../store/builder';
import { getImageDimensions } from '../../helpers';

const CanvasGroup = ({ layers }) => {
  const mergeDownLayer = useSelector(state => state.builder.mergeDownLayer);
  const zoom = useSelector(state => state.builder.zoom);
  const groupRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if(!groupRef.current) return;
    setTimeout(async () => {
      const updatedContent = groupRef.current.toDataURL();
      const imageDimensions = await getImageDimensions(updatedContent);
      dispatch(emptySelectedLayer());
      dispatch(mergeLayers({
        data: {
        id: mergeDownLayer,
        type: 'image',
        content: updatedContent,
        name: layers[1].name,
        left: Math.min(layers[0].left, layers[1].left),
        top: Math.min(layers[0].top, layers[1].top),
        visible: true,
        width: imageDimensions.w * 100 / zoom,
        height: imageDimensions.h * 100 / zoom,
      }, layers }));
      setTimeout(() => { 
        dispatch(setSelectedLayer({ id: mergeDownLayer }));
        dispatch(setPanel('filter'));
      });
      dispatch(setMergeDownLayer(null));
    }, 500);
  }, [groupRef.current]);

  return (
    <Layer> 
      <Group ref={groupRef} >
      { layers.map((layer, index) => {
          if (layer.type === 'image') {
            return (
              <CanvasImage
                key={index}
                layer={{ ...layer, id: 0 }}
                lines={[]}
              />
            );
          } else if (layer.type === 'text') {
            return (
              <CanvasText
                key={index}
                layer={{ ...layer, id: 0 }}
              />
            );
          }
        })}
      </Group>
    </Layer>
  );
};

CanvasGroup.propTypes = {
    layers: PropTypes.array,
};

export default CanvasGroup;