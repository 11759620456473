import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { OutlinedButton } from '../Common/BaseButton';
import { ReactComponent as GenerateIcon } from '../../assets/toolbar/generate.svg';
import { ReactComponent as ImportIcon } from '../../assets/toolbar/import.svg';
import { setPanel } from '../../store/builder';

const ActionsContainer = styled.div`
  position: absolute;
  display: flex;    
  align-items: center;
  column-gap: 15px;
  z-index: 10;
  h5 {
    color: #3C4049
  }
`;

const InitialActions = () => {
  const layers = useSelector(state => state.builder.project.content);
  const projectId = useSelector(state => state.builder.project.id);
  const panel = useSelector(state => state.builder.panel);
  const dispatch = useDispatch();

  const selectPanel = (newPanel) => {
    if (panel === newPanel) {
      return;
    }

    dispatch(setPanel(newPanel));
  };

  if (layers.length || !projectId) return;

  return (
    <ActionsContainer>
    <OutlinedButton width={200}  onClick={() => selectPanel('generate')}>
      <GenerateIcon stroke="#77B36B" /> Generate AI Image
    </OutlinedButton>
    <h5>
      OR
    </h5>
    <OutlinedButton width={200}  onClick={() => selectPanel('image')}>
      <ImportIcon stroke="#77B36B" /> Import Image
    </OutlinedButton>
    </ActionsContainer>
  );
};

export default InitialActions;
