import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { setDraggedImage } from '../../store/builder';
import { useDispatch, useSelector } from 'react-redux';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ImageContainer = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F5F5F5;
  padding: 10px;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 6px;
  cursor: pointer;
  
  &:hover {
    opacity: 0.8;
  }
`;

const Name = styled.div`
  width: ${props => props.width}px;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #3C4049;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LibraryPanelAsset = ({ asset, handleClick }) => {
  const enlarged = useSelector(state => state.library.enlarged);
  const dispatch = useDispatch();
  const { content, name } = asset;

  return (
    <Container>
      <ImageContainer
        width={enlarged ? 178 : 84}
        height={enlarged ? 150 : 80}
      >
        <Image
          draggable
          src={content}
          alt={name}
          onDragStart={() => {
            dispatch(setDraggedImage({ content, name }));
          }}
          onClick={handleClick}
        />
      </ImageContainer>
      { enlarged && <Name width={158}>
        { asset.name }
      </Name> }
    </Container>
  );
};

LibraryPanelAsset.propTypes = {
  asset: PropTypes.object,
  handleClick: PropTypes.func,
};

export default LibraryPanelAsset;
