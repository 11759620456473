import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import BasePanel from '../Common/BasePanel';
import BaseTextBox from '../Common/BaseTextBox';
import { useDispatch, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import {
  fetchAssetCategories,
  fetchAssets,
  fetchAssetsNextPage,
  setSearch,
  setSelectedCategory,
  toggleEnlarged,
  setSelectedType,
  setPreviewedAsset,
} from '../../store/library';
import LibraryPanelAsset from './LibraryPanelAsset';
import { ReactComponent as CaretLeft } from '../../assets/caret-left.svg';
import { ReactComponent as CaretRight } from '../../assets/caret-right.svg';
import Loader from '../Common/Loader';
import LibraryPreview from './LibraryPreview';

const Container = styled(BasePanel)`
  display: flex;
  padding: 16px;
  transition: 0.05s;
`;

const CaretContainer = styled.div`
  position: absolute;
  top: 62px;
  z-index: 1000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #DCDDE1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;

const Categories = styled.div`
  flex: 0 0 160px;
  display: flex;
  flex-direction: column;
  gap: 0;
`;

const CategoryContainer = styled.div`
  
`;

const Category = styled.div` 
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #3C4049;
  padding: 4px 8px;
  width: 148px;
  height: 30px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  
  &:hover, &.selected {
    background: #F4FCE1;
    color: #66995C;
  }
`;

const SubCategory = styled.div`
  width: 148px;
  height: 30px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #3C4049;
  padding-left: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  
  &:hover, &.selected {
    background: #F4FCE1;
    color: #66995C;
  }
`;

const RightContainer = styled.div`
  flex: 1;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #3C4049;
  margin: 0 0 4px;
`;

const Subtitle = styled.h4`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #7C8394;
  margin: 0 0 6px;
`;

const Assets = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: ${props => props.rowHeight}px;
  grid-gap: 8px;
  margin-top: 8px;
  height: calc(100vh - 275px);
  overflow-y: auto;
`;

const LoaderWrap = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  height: 80px;
`;

const Tabs = styled.div`
  display: flex;
`;

const Tab = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #3C4049;
  width: ${props => props.width}px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  &.selected {
    font-weight: 700;
    border-bottom: 2px solid #77B36B;
  }
`;

let timeout;

const LibraryPanel = () => {
  const selectedPanel = useSelector(state => state.builder.panel);
  const assets = useSelector(state => state.library.assets);
  const categories = useSelector(state => state.library.categories);
  const enlarged = useSelector(state => state.library.enlarged);
  const selectedCategory = useSelector(state => state.library.selectedCategory);
  const pagination = useSelector(state => state.library.pagination);
  const search = useSelector(state => state.library.search);
  const selectedType = useSelector(state => state.library.selectedType);
  const dispatch = useDispatch();

  const { ref } = useInView({
    onChange: (inView) => {
      if (inView && !pagination.loading) {
        dispatch(fetchAssetsNextPage());
      }
    },
  });

  useEffect(() => {
    dispatch(fetchAssetCategories());
  }, []);

  useEffect(() => {
    dispatch(fetchAssets());
  }, [selectedCategory]);

  useEffect(() => {
    clearTimeout(timeout);
    timeout = setTimeout(() => dispatch(fetchAssets()), 300);
  }, [search]);

  useEffect(() => {
    clearTimeout(timeout);
    timeout = setTimeout(() => dispatch(fetchAssets()), 300);
  }, [selectedType]);

  return (
    <Fragment>
      { selectedPanel === 'library' &&
        <CaretContainer
          style={{ left: enlarged ? 728 : 448 }}
          onClick={() => dispatch(toggleEnlarged())}
        >
          { enlarged && <CaretLeft /> }
          { !enlarged && <CaretRight /> }
        </CaretContainer>
      }
      <Container
        panel="library"
        width={enlarged ? 740 : 460}
      >
        <Categories>
          {
            categories.map((category, index) => (
              <CategoryContainer key={index}>
                <Category
                  className={category.id === selectedCategory ? 'selected' : ''}
                  onClick={() => dispatch(setSelectedCategory(category.id))}
                >
                  { category.name }
                </Category>
                { category.subCategories.length > 0  && <Fragment>
                  {
                    category.subCategories.map((subCategory, index) => (
                      <SubCategory
                        className={subCategory.id === selectedCategory ? 'selected' : ''}
                        onClick={() => dispatch(setSelectedCategory(subCategory.id))}
                        key={index}
                      >
                        { subCategory.name }
                      </SubCategory>
                    ))
                  }
                </Fragment>}
              </CategoryContainer>
            ))
          }
        </Categories>
        <RightContainer>
          <Title>Assets library</Title>
          <Subtitle>You can drag assets in to canvas</Subtitle>
          <BaseTextBox
            placeholder="Search assets"
            width={ enlarged ? 548 : 268 }
            value={search}
            onChange={(value) => dispatch(setSearch(value))}
          />
          <Tabs>
            <Tab
              width={ enlarged ? 273 : 134}
              className={selectedType === 1 ? 'selected' : ''}
              onClick={() => dispatch(setSelectedType(1))}
            >
              2D images
            </Tab>
            <Tab
              width={ enlarged ? 273 : 134}
              className={selectedType === 2 ? 'selected' : ''}
              onClick={() => dispatch(setSelectedType(2))}
            >
              3D images
            </Tab>
          </Tabs>
          <Assets
            rowHeight={enlarged ? 178 : 80}
          >
            {
              assets.map((asset, index) => (
                <LibraryPanelAsset
                  key={index}
                  asset={asset}
                  handleClick={() => dispatch(setPreviewedAsset(index))}
                />
              ))
            }
            {(!!assets.length || pagination.loading) && !pagination.isLastPage &&
            <LoaderWrap>
              <Loader ref={ref} />
            </LoaderWrap>
            }
          </Assets>
        </RightContainer>
      </Container>
      <LibraryPreview />
    </Fragment>
  );
};

export default LibraryPanel;
