import React, { Fragment, useEffect, useState } from 'react';
import styled  from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import BaseColorPicker from '../Common/BaseColorPicker';
import BaseSlider from '../Common/BaseSlider';
import BaseSwitch from '../Common/BaseSwitch';
import { useDispatch, useSelector } from 'react-redux';
import {
  setGradientAngle,
  toggleGradient,
  setGradientColorA,
  setGradientColorB,
  setStartPoint,
  setEndPoint,
  setEditLayerId,
} from '../../store/builder';
import BaseTextBox from '../Common/BaseTextBox';
import { Slider } from '@mui/material';
import { TEXT_LIMITS } from '../../constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding-top: 16px;
`;

const Element = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  padding: 8px 0;
`;

const PickersElement = styled(Element)`
  justify-content: space-between;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #3C4049;
  flex: 1;
`;

const StyledSlider = styled(BaseSlider)`
  width: 122px;
  margin-right: 4px;
`;

const GradientSlider = muiStyled(Slider)((props) => ({
  height: '10px',
  borderRadius: '4px',
  '& .MuiSlider-rail': {
    backgroundImage: `linear-gradient(.25turn, ${props.colora}, ${props.colorb})`,
  },
  '& .MuiSlider-track': {
    color: 'transparent',
    backgroundImage: `linear-gradient(.25turn, ${props.colora}, ${props.colorb})`,
  },
  '& .MuiSlider-thumb': {
    backgroundColor: props.colorb,
    border: '1.5px solid #fff',
    "&[data-index='0']": {
      backgroundColor: props.colora,
    },
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
}));

let timeout;

const TextStylePanelGradient = () => {
  const gradient = useSelector(({ builder }) => builder.text.gradient);
  const selectedText = useSelector(({ builder }) => builder.selectedText);
  const editLayerId = useSelector(({ builder }) => builder.editLayerId);
  const [angle, setAngle] = useState(0);
  const dispatch = useDispatch();
  const handlePointChange = ([start, end]) => {
    if (start < end) {
      dispatch(setStartPoint(start));
    }
    if (end > start) {
      dispatch(setEndPoint(end));
    }
  };

  const handleAngleChange = (value) => {
    clearTimeout(timeout);
    setAngle(value);
    timeout = setTimeout(() => {
      const valueToSet = `${Math.min(Math.max(+value, TEXT_LIMITS.gradientAngle.min), TEXT_LIMITS.gradientAngle.max)}`;
      if (valueToSet !== value) setAngle(valueToSet);
      dispatch(setGradientAngle(valueToSet));
    }, 500);
  };

  const handleClick = () => {
    if (!selectedText || editLayerId === selectedText) return;
    dispatch(setEditLayerId(selectedText));
  };

  const handleBlur = () => {
    dispatch(setEditLayerId(false));
  };

  useEffect(() => {
    if(angle !== gradient.angle) setAngle(gradient.angle);
  }, [gradient.angle]);

  return (
    <Fragment>
      <BaseSwitch
        label="Gradient color"
        checked={gradient.applied}
        toggle={() => dispatch(toggleGradient())}
      />
      {
        gradient.applied && <Container>
          <PickersElement>
            <BaseColorPicker
              color={gradient.colorA}
              setColor={(color) => dispatch(setGradientColorA(color))}
            />
            <BaseColorPicker
              color={gradient.colorB}
              setColor={(color) => dispatch(setGradientColorB(color))}
            />
          </PickersElement>
          <Element>
            <GradientSlider 
              min={0}
              max={100} 
              onChange={({ target: { value } }) => handlePointChange(value)}
              value={[gradient.startPoint, gradient.endPoint]}
              colora={gradient.colorA}
              colorb={gradient.colorB}
            />
          </Element>
          <Element>
            <Label>Angle</Label>
            <StyledSlider
              value={gradient.angle}
              min={TEXT_LIMITS.gradientAngle.min}
              max={TEXT_LIMITS.gradientAngle.max}
              onChange={(angle) => dispatch(setGradientAngle(angle))}
            />
            <BaseTextBox
              type="number"
              min={TEXT_LIMITS.gradientAngle.min}
              max={TEXT_LIMITS.gradientAngle.max}
              width={70}
              onClick={handleClick}
              onBlur={handleBlur}
              onChange={handleAngleChange}
              value={angle}
              background={'rgb(245, 245, 245)'}
              bottom={0}
            />
          </Element>
        </Container>
      }
    </Fragment>
  );
};

export default TextStylePanelGradient;
