import React, { useState, useRef } from 'react';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { LoggedOutActions, LoggedOutContent } from '../layouts/LoggedOutLayout';
import BaseTextBox from '../components/Common/BaseTextBox';
import BaseCheckBox from '../components/Common/BaseCheckBox';
import BaseLink from '../components/Common/BaseLink';
import { SimpleButton } from '../components/Common/BaseButton';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { authenticate } from '../store/user';
import FormError from '../components/Common/FormError';

const StyledLoggedOutContent = styled(LoggedOutContent)`
  .logo {
    width: 400px;
    margin-bottom: 20px;
  }
`;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const passwordRef = useRef(null);
  const emailRef = useRef(null);
  const submitRef = useRef(null);
  const loginError = useSelector(state => state.user.loginError);

  const dispatch = useDispatch();

  const login = () => {
    dispatch(
      authenticate({
        email,
        password,
        rememberMe,
      }),
    );
  };

  return (
    <StyledLoggedOutContent>
      <div className="logo">
        <Logo />
      </div>
      <h1>Welcome to Articus</h1>
      <h2>Enter your login information below to sign in.</h2>
      {loginError && <FormError text={loginError}/>}
      <BaseTextBox
        ref={emailRef}
        label="Email"
        placeholder="Enter email address"
        value={email}
        onChange={(value) => setEmail(value)}
        onEnterClick={() => passwordRef.current.focus()}
        onTabClick={() => passwordRef.current.focus()}
        background="transparent"
        inputBackground="#fff"
      />
      <BaseTextBox
        label="Password"
        placeholder="Enter password"
        type="password"
        value={password}
        onChange={(value) => setPassword(value)}
        ref={passwordRef}
        background="transparent"
        inputBackground="#fff"
        onEnterClick={login}
        onTabClick={() => submitRef.current.focus()}
      />
      { process.env.REACT_APP_AREA !== 'admin' && <LoggedOutActions>
        <BaseCheckBox label="Remember me" onChange={(v) => setRememberMe(v)} />
        <BaseLink text="Forgot password?" to="/login/reset" />
      </LoggedOutActions> }
      <SimpleButton 
        ref={submitRef}
        onTabClick={() => emailRef.current.focus()}
        width={400}
        onClick={login}
      >
        Log in
      </SimpleButton>
    </StyledLoggedOutContent>
  );
};

export default Login;
