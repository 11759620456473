import React from 'react';
import styled from 'styled-components';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/times.svg';
import PropTypes from 'prop-types';

const Container = styled(Dialog)`
  .MuiPaper-root {
    width: ${ ({ width }) => width + 32 }px;
    height: ${ ({ height }) => height + 32 }px;
  }
`;

const Title = styled(DialogTitle)`
  display: flex;
  gap: 10px;
  align-items: center;

  .icon {
    stroke: #77B26B;
  }
  
  .title {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #3C4049;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .close {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #3C4049;
    cursor: pointer;
  }
  
  .close-icon {
    cursor: pointer;
  }
`;

const ProjectPreview = ({ open, handleClose, image, name, width, height }) => {
  return (
    <Container
      maxWidth="2000px"
      width={width}
      height={height}
      open={open}
      onClose={handleClose}
    >
      <Title>
        <span className="title">
          { name }
        </span>
        <span className="close" onClick={handleClose}>
          Close
        </span>
        <CloseIcon className="close-icon" onClick={handleClose} />
      </Title>
      <DialogContent>
        <img
          src={ process.env.REACT_APP_API_URL + 'images/' + image }
          alt="..."
        />
      </DialogContent>
    </Container>
  );
};

ProjectPreview.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  image: PropTypes.string,
  name: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ProjectPreview;
