import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoImg } from '../../assets/logo.svg';
import { ReactComponent as AvatarImg } from '../../assets/user-avatar.svg';
import { useSelector } from 'react-redux';
import { Menu } from '@mui/material';
import MenuContent from './MenuContent';
import MacButtons from './MacButtons';
import WinButtons from './WinButtons';
import EditPassword from './EditPassword';
import EditProfile from './EditProfile';

const Container = styled.div`
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100vw;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  gap: 16px;
  background: #FFFFFF;
  border-bottom: 1px solid #DCDDE1;
  height: 40px;
  -webkit-user-select: none;
  -webkit-app-region: drag;
`;

const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  
  svg {
    height: 24px;
  }
`;

const UserContainer = styled.div`
  position: relative;
  -webkit-app-region: no-drag;
`;

const Avatar = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  
  img, svg {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
`;

const UserMenu = styled(Menu)`
  -webkit-app-region: no-drag;
`;

const Header = () => {
  const id = useSelector(state => state.user.id);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fullScreen, setFullScreen] = useState(null);
  const { avatar } = useSelector(state => state.user);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    window.require('electron')?.ipcRenderer.send('close');
  };
  
  const handleMinimize = () => {
    window.require('electron')?.ipcRenderer.send('minimize');
  };
  
  const handleFullScreen = () => {
    window.require('electron')?.ipcRenderer.send('full-screen');
  };

  useEffect(() => {
    if (!window.require) return;
    const ipcRenderer = window.require('electron')?.ipcRenderer;

    ipcRenderer.send('getFullScreen');
    
    ipcRenderer.on('getFullScreen', (evt, isFullScreen) => {
      setFullScreen(isFullScreen);
    });
  }, []);

  return (
    <Container>
      {!window.electron?.isWindows && 
        <MacButtons 
          close={handleClose}
          minimize={handleMinimize}
          toogleFullScreen={handleFullScreen}
        />
      }
      <LogoContainer>
        <LogoImg />
      </LogoContainer>
      { id && <UserContainer>
        <Avatar
          id="user-avatar"
          aria-controls={open ? 'user-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          className={open && 'opened'}
        >
          { avatar &&
            <img src={process.env.REACT_APP_API_URL + 'images/' + avatar } style={{ objectFit: 'cover' }} alt="" />
          }
          {
            !avatar && <AvatarImg />
          }
        </Avatar>
        <UserMenu
          id="user-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            'aria-labelledby': 'user-avatar',
          }}
        >
          <MenuContent close={() => setAnchorEl(null)} />
        </UserMenu>
      </UserContainer>}
      {!!window.electron?.isWindows && 
        <WinButtons
          close={handleClose}
          minimize={handleMinimize}
          toogleFullScreen={handleFullScreen}
          fullScreen={fullScreen}
        />
      }
      <EditPassword />
      <EditProfile />
    </Container>
  );
};

export default Header;
