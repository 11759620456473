import { Dialog, DialogContent } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseButton, { CancelButton } from '../Common/BaseButton';
import { useDispatch, useSelector } from 'react-redux';
import { setLimitGenerationModal } from '../../store/user';
import { CREDITS_PRICES, PLAN_LIMIT } from '../../constants';
import { ReactComponent as AlertIcon } from '../../assets/alert.svg';
import { ReactComponent as CoinIcon } from '../../assets/coin.svg';
import BaseSlider from '../Common/BaseSlider';
import BaseTextBox from '../Common/BaseTextBox';
 
const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 350px;
  }
`;

const Content = styled(DialogContent)`
  text-align: center;
  h3 {
    color: #77b36b;
  }
  p {
    color: #3C4049;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  justify-content: center;
`;

const SliderWrap = styled.div`
  padding: 10px;
  display: flex;
`;

const CreditsPrice = styled.div`
`;

const StyledSlider = styled(BaseSlider)`
  width: 200px;
  margin-right: 4px;
`;

const LimitGenerationModal = ({ type }) => {
  const [addCredits, setAddCredits] = useState(false);
  const [credits, setCredits] = useState(0);
  const modalType = useSelector(({ user }) => user.limitGenerationModal);
  const plan = useSelector(({ user }) => user.plan);
  const name = useSelector(({ user }) => user.name);
  const email = useSelector(({ user }) => user.email);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setLimitGenerationModal(null));
  };
  const handleSubmit = () => {
    if (addCredits) {
      const quantity = credits / CREDITS_PRICES[type].step;
      const product = type === 'image' ? process.env.REACT_APP_IMAGE_PKS_PRODUCT : process.env.REACT_APP_WORD_PKS_PRODUCT;
      const [firstName, lastName] = name.split(' ');
      window.open(`https://app.paykickstart.com/checkout/${product}?qt=${quantity}&first_name=${firstName}&last_name=${lastName}&email=${email}`);
      handleClose();
    }
    setAddCredits(!addCredits);
  };
  return ( 
      <StyledDialog
        open={modalType === type}
        onClose={handleClose}
      >
          <Content>
            {addCredits ? <CoinIcon /> : <AlertIcon />}
            <h3>{addCredits ? 'Add additional credits' : 'Plan limit reached'}</h3>
            <p>
              The {type} is unable to be generated 
              because you have reached the { plan }{' '}
              plan&apos;s limit of {PLAN_LIMIT[plan] ? PLAN_LIMIT[plan][type] : 0 } credits/month
            </p>
            {addCredits && 
              <>
                <SliderWrap>
                  <StyledSlider
                    value={credits}
                    min={CREDITS_PRICES[type].min}
                    max={CREDITS_PRICES[type].max}
                    onChange={(v) => setCredits(v)}
                    step={CREDITS_PRICES[type].step}
                  />
                  <BaseTextBox
                    width={80}
                    value={credits}
                    disabled
                    background={'rgb(245, 245, 245)'}
                    bottom={0}
                  />
                </SliderWrap>
                <CreditsPrice>
                  {credits} = ${(credits * CREDITS_PRICES[type].price).toFixed(2)}
                </CreditsPrice>
              </>
            }
            <ButtonWrap>
              <CancelButton onClick={handleClose}>
                Close
              </CancelButton>
              <BaseButton onClick={handleSubmit}>
                Purchase credits
              </BaseButton>
            </ButtonWrap>
          </Content>
      </StyledDialog>
  );
};

LimitGenerationModal.propTypes = {
  type: PropTypes.string,
};

export default LimitGenerationModal;