import React from 'react';
import styled from 'styled-components';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as FullScreenIcon } from '../../assets/fullscreen.svg';

const HeaderButtons = styled.div`
  display: flex;
  width: 90px;
  justify-content: space-between;
  -webkit-app-region: no-drag;
`;

const HeaderButton = styled(IconButton)`
  &.MuiButtonBase-root{
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

const WinButtons = ({ close, minimize, toogleFullScreen, fullScreen }) => {
    return (
      <HeaderButtons>
        <HeaderButton onClick={minimize} size="medium">
          <RemoveIcon />
        </HeaderButton>
        <HeaderButton onClick={toogleFullScreen} size="medium">
          {fullScreen ? <FullScreenIcon /> : <CropSquareIcon /> }
        </HeaderButton>
        <HeaderButton onClick={close} size="medium" aria-label="close">
          <CloseIcon />
        </HeaderButton>
      </HeaderButtons>
    );
};

WinButtons.propTypes = {
    close: PropTypes.func,
    minimize: PropTypes.func,
    toogleFullScreen: PropTypes.func,
    fullScreen: PropTypes.bool,
};

export default WinButtons;