import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api';

const initialState = {
  assets: [],
  categories: [],
  pagination: {
    page: 1,
    perPage: 1,
    isLastPage: false,
    loading: false,
  },
  previewedAsset: null,
  enlarged: false,
  selectedCategory: null,
  selectedType: 1,
  search: '',
};

export const fetchAssetCategories = createAsyncThunk(
  'library/fetchAssetCategories',
  async (arg, { getState }) => {
    const { library } = getState();

    if (library.categories.length > 0) {
      return;
    }

    return await api.assetCategory.fetch();
  },
);

export const fetchAssets = createAsyncThunk(
  'library/fetchAssets',
  async (arg, { getState }) => {
    const { library } = getState();

    return await api.asset.fetch({
      category: library.selectedCategory,
      type: library.selectedType,
      page: library.pagination.page,
      search: library.search,
    }, '', false);
  },
);

export const fetchAssetsNextPage = createAsyncThunk(
  'library/fetchAssetsNextPage',
  async (arg, { getState }) => {
    const { library } = getState();

    return await api.asset.fetch({
      category: library.selectedCategory,
      type: library.selectedType,
      page: library.pagination.page + 1,
      search: library.search,
    }, '', false);
  },
);

export const library = createSlice({
  name: 'library',
  initialState,
  reducers: {
    toggleEnlarged(state) {
      state.enlarged = !state.enlarged;
    },
    setSelectedCategory(state, { payload }) {
      if(state.selectedCategory === payload) return;
      state.selectedCategory = payload;
      state.pagination.page = 1;
      state.pagination.isLastPage = false;
      state.search = '';
      state.assets = [];
    },
    setSelectedType(state, { payload }) {
      state.selectedType = payload;
    },
    setSearch(state, { payload }) {
      state.search = payload;
    },
    setPreviewedAsset(state, { payload }) {
      state.previewedAsset = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAssetCategories.fulfilled, (state, { payload }) => {
      state.categories = payload;

      state.categories.unshift({
        id: null,
        name: 'All Assets',
        subCategories: [],
      });
    });

    builder.addCase(fetchAssets.pending, (state) => {
      state.assets = [];
      state.pagination.loading = true;
      state.pagination.page = 1;
    });

    builder.addCase(fetchAssets.fulfilled, (state, { payload }) => {
      state.assets = payload;
      state.pagination.loading = false;
      if(!state.selectedCategory) state.pagination.perPage = payload.length;
      state.pagination.isLastPage = !!state.selectedCategory && payload.length < state.pagination.perPage;
    });

    builder.addCase(fetchAssetsNextPage.pending, (state) => {
      state.pagination.loading = true;
    });

    builder.addCase(fetchAssetsNextPage.fulfilled, (state, { payload }) => {
      state.assets = [...state.assets, ...payload];
      state.pagination.page += 1;
      if(payload.length < state.pagination.perPage) state.pagination.isLastPage = true;
      state.pagination.loading = false;
    });
  },
});

export const {
  toggleEnlarged,
  setSelectedCategory,
  setSelectedType,
  setSearch,
  setPreviewedAsset,
} = library.actions;

export default library.reducer;
