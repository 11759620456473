import React, { useEffect, useState } from 'react';
import styled from 'styled-components'; 
import BaseSlider from '../Common/BaseSlider';
import BaseButton, { CancelButton } from '../Common/BaseButton';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from '../../store/builder';

const Container = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  bottom: 16px;
`;

const Wrapper = styled.div`
  max-width: calc(100vw - 280px - 16px);
  background: #fff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  border-radius: 10px;
  align-items: center;
`;
const SliderWrap = styled.div`
  width: 250px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-left: 1px solid #F5F5F5;
  &:first-child {
    border-left: none
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-left: 1px solid #F5F5F5;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-align: center;
  color: #7C8394;
`;

const Slider = styled(BaseSlider)`
  width: 100%
`;

const FilterConfigs = () => {
  const [show, setShow] = useState(true);
  const selectedLayer = useSelector(state => state.builder.selectedLayer);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!!selectedLayer?.meta?.filter?.parameters?.length && !show)
      setShow(true);
  }, [selectedLayer?.meta?.filter]);

  if (!selectedLayer?.meta?.filter?.parameters?.length || !show) return;

  const handleParameterUpdate = (key, value) => {
    const filterToSet = { ...selectedLayer.meta.filter, [key]: value };
    dispatch(setFilter(filterToSet));
  };

  const handleCancel = () => {
    dispatch(setFilter({ name: null }));
    setShow(false);
  };

  return (
    <Container>
      <Wrapper>
        {selectedLayer.meta.filter.parameters.map((parameter) => (
          <SliderWrap key={parameter.key}>
            <Label>{parameter.label}</Label>
            <Slider
              name="image"
              onChange={(value) => handleParameterUpdate(parameter.key, value)}
              value={selectedLayer.meta.filter[parameter.key]}
              min={parameter.min}
              max={parameter.max}
            />
            <Label>{selectedLayer.meta.filter[parameter.key]}</Label>
          </SliderWrap>
        ))}
        <ButtonWrap>
          <CancelButton onClick={handleCancel}>
            Cancel
          </CancelButton>
          <BaseButton onClick={() => setShow(false)}>
            Apply filter
          </BaseButton>
        </ButtonWrap>
      </Wrapper>
    </Container>
  );
};

export default FilterConfigs;
