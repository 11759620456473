import adminAsset from './services/admin/Asset';
import adminProject from './services/admin/Project';
import adminTemplate from './services/admin/Template';
import adminUser from './services/admin/User';
import asset from './services/Asset';
import assetCategory from './services/AssetCategory';
import imageEditor from './services/ImageEditor';
import imageGenerator from './services/ImageGenerator';
import imageUploader from './services/ImageUploader';
import login from './services/Login';
import password from './services/Password';
import pixabay from './services/Pixabay';
import project from './services/Project';
import projectSize from './services/ProjectSize';
import template from './services/Template';
import templateCategory from './services/TemplateCategory';
import textGenerator from './services/TextGenerator';
import user from './services/User';
import userAgreement from './services/UserAgreement';

export default {
  admin: {
    asset: adminAsset,
    project: adminProject,
    template: adminTemplate,
    user: adminUser,
  },
  asset,
  assetCategory,
  imageEditor,
  imageGenerator,
  imageUploader,
  login,
  password,
  pixabay,
  project,
  projectSize,
  template,
  templateCategory,
  textGenerator,
  user,
  userAgreement,
};
