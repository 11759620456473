import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ProjectMenu from './ProjectMenu';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedProject, updateProjectName } from '../../store/project';
import BaseTextBox from '../Common/BaseTextBox';

const Image = styled.img`
  width: 218px;
  height: 180px;
  border-radius: 4px;
  object-fit: cover;
  cursor: pointer;
`;

const Empty = styled.div`
  width: 218px;
  height: 180px;
  background: #F5F5F5;
  border-radius: 4px;
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;

  &.editing {
    margin-top: 4px;
  }
`;

const Name = styled.div`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: #3C4049;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Card = styled.div`
  width: 234px;
  height: 230px;
  padding: 8px;
  background: #ffffff;
  border-radius: 6px;
  
  &.selected {
    outline: 1px solid #77B36B;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    
    ${Name} {
      color: #77B36B;
    }
  }
`;

function RecentProjectsCard({ id, name, preview }) {
  const [editName, setEditName] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [newName, setNewName] = useState(name);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToBuilder = async () => {
    navigate('/builder/' + id);
  };

  const handleRename = async () => {
    if(editName && newName!== name) 
      await dispatch(updateProjectName({ name: newName }));
    setEditName(false);
    dispatch(setSelectedProject(null));
    setIsSelected(false);
  };

  const openRename = () => {
    setTimeout(() => {
      setEditName(true);
      dispatch(setSelectedProject(id)); 
      setIsSelected(true);
    });
  };

  return (
    <Card
      className={isSelected ? 'selected' : ''}
    >
      { preview && (
        <Image
          src={ process.env.REACT_APP_API_URL + 'images/' + preview }
          alt={name}
          onClick={goToBuilder}
        />
      )}

      { !preview && <Empty onClick={goToBuilder} /> }
      <BottomSection className={editName ? 'editing' : ''}>
        {editName ? 
          <BaseTextBox 
            value={newName} 
            onChange={(value) => setNewName(value)}
            width={190}
            height={28} 
            bottom={4} 
            onEnterClick={handleRename}
            onBlur={handleRename}
            autoFocus
            style={{ cursor: 'pointer' }}
          /> :
          <Name onClick={goToBuilder}>
            {name}
          </Name>
        }
        <ProjectMenu
          id={id}
          name={name}
          preview={preview}
          rename={openRename}
          menuOpened={setIsSelected}
        />
      </BottomSection>
    </Card>
  );
}

RecentProjectsCard.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  preview: PropTypes.string,
};

export default RecentProjectsCard;
