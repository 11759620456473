import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { setSelectedImageIndex } from '../../store/imageGenerator';
import { useDispatch, useSelector } from 'react-redux';

const Thumbnail = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.46;
  
  &.active, &:hover {
    opacity: 1;
  }
`;

const GeneratorPreviewThumbnail = ({ index, image }) => {
  const selectedImageIndex = useSelector(state => state.imageGenerator.selectedImageIndex);
  const dispatch = useDispatch();

  return (
    <Thumbnail
      className={selectedImageIndex === index ? 'active' : ''}
      src={image}
      alt="..."
      onClick={() => dispatch(setSelectedImageIndex(index))}
    />
  );
};

GeneratorPreviewThumbnail.propTypes = {
  image: PropTypes.string,
  index: PropTypes.number,
};

export default GeneratorPreviewThumbnail;
