import React, { Fragment } from 'react';
import styled from 'styled-components';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { SimpleButton, CancelButton } from '../Common/BaseButton';
import BaseTextBox from '../Common/BaseTextBox';
import NewProjectBasicsSizes from './NewProjectBasicsSizes';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setProjectName, toggleNewProjectOpen } from '../../store/project';

const Title = styled(DialogTitle)`
  background: #FFF;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.4375rem;
  color: #3C4049;
  border-bottom: 1.5px solid #DCDDE1;
`;

const StyledDialogContent = styled(DialogContent)`
  height: 470px;
  padding: 28px !important;
`;

const StyledDialogActions = styled(DialogActions)`
  height: 72px;
  border-top: 1.5px solid #DCDDE1;
`;

const NewProjectBasics = ({ goToNextStep }) => {
  const name = useSelector(({ project }) => project.project.name);
  const size = useSelector(({ project }) => project.project.size);
  const customWidth = useSelector(({ project }) => project.project.width);
  const customHeight = useSelector(({ project }) => project.project.height);
  const dispatch = useDispatch();

  return (
    <Fragment>
      <Title>
        Create new project
      </Title>
      <StyledDialogContent>
        <BaseTextBox
          label="Project name"
          value={name}
          onChange={(val) => dispatch(setProjectName(val))}
          width={412}
        />
        <NewProjectBasicsSizes />
      </StyledDialogContent>
      <StyledDialogActions>
        <CancelButton onClick={() => dispatch(toggleNewProjectOpen())} />
        <SimpleButton
          disabled={!name || !size || (size === 1 && !customWidth && !customHeight)}
          onClick={goToNextStep}
        >
          Continue
        </SimpleButton>
      </StyledDialogActions>
    </Fragment>
  );
};

NewProjectBasics.propTypes = {
  goToNextStep: PropTypes.func,
  handleClose: PropTypes.func,
};

export default NewProjectBasics;
