import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";
import BaseListHeaderColumn from "./BaseListHeaderColumn";

const Container = styled.div`
  display: flex;
  gap: 16px;
  height: 14px;
  margin-bottom: 4px;
  position: fixed;
  background-color: #F5F5F5;
  width: calc(100vw - 100px);
`;

const BaseListHeader = ({ columns }) => {
  return (
    <Container>
      {
        columns.map(({ label, width, isMain }, index) => (
          <BaseListHeaderColumn
            key={index}
            width={width}
            isMain={isMain}
          >
            { label }
          </BaseListHeaderColumn>
        ))
      }
    </Container>
  );
};

BaseListHeader.propTypes = {
  columns: PropTypes.array,
};

export default BaseListHeader;