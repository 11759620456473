import React from 'react';
import styled from 'styled-components';
import ProjectsListHeaderColumn from "./ProjectsListHeaderColumn";

const Container = styled.div`
  display: flex;
  padding: 4px 4px 4px 86px;
  gap: 16px;
  height: 14px;
  margin-bottom: 4px;
`;

const ProjectsListHeader = () => (
  <Container>
    <ProjectsListHeaderColumn
      column="name"
      width={667}
    >
      Title
    </ProjectsListHeaderColumn>
    <ProjectsListHeaderColumn
      column="createdAt"
    >
      Created on
    </ProjectsListHeaderColumn>
    <ProjectsListHeaderColumn
      column="updatedAt"
    >
      Modified on
    </ProjectsListHeaderColumn>
    <ProjectsListHeaderColumn
      column="fileSize"
    >
      File size
    </ProjectsListHeaderColumn>
  </Container>
);

export default ProjectsListHeader;
