import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: 0,
};

export const loader = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    decreaseLoading(state) {
      state.loading--;
    },
    increaseLoading(state) {
      state.loading++;
    },
  },
});

export const {
  decreaseLoading,
  increaseLoading,
} = loader.actions;

export default loader.reducer;
