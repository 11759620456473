import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../store/user";
import { Navigate, Outlet } from "react-router-dom";
import Header from "../components/Admin/Header";
import styled from "styled-components";

const Content = styled.div`
  width: calc(100vw - 100px);
  margin: 60px auto 40px;
`;

const AdminLayout = () => {
  const token = useSelector(state => state.user.token);
  const id = useSelector(state => state.user.id);
  const dispatch = useDispatch();

  useEffect(() => {
    if(token && !id) {
      dispatch(fetchUser());
    }
  }, [token, id]);

  if (!token && !id)
    return <Navigate to={'/login'} replace />;

  if(!id)
    return<></>;

  if (process.env.REACT_APP_AREA !== 'admin') {
    return <Navigate to={'/'} replace />;
  }

  return (
    <Fragment>
      <Header />
      <Content>
        <Outlet />
      </Content>
    </Fragment>
  );
};

export default AdminLayout;