import { Dialog, DialogContent } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import BaseTextBox from '../Common/BaseTextBox';
import BaseButton, { CancelButton } from '../Common/BaseButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { setEditLayerId, setEditNameModal, updateLayer } from '../../store/builder';

const EditNameDialog = styled(Dialog)`
  .MuiPaper-root {
  }
`;

const Content = styled(DialogContent)`
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
`;

const validationSchema = yup.object({
    name: yup.string().required('Required field'),
});

const EditName = () => {
  const selectedLayer = useSelector(({ builder }) => builder.selectedLayer);
  const editNameModal = useSelector(({ builder }) => builder.editNameModal);
  const dispatch = useDispatch();
  const btnRef = useRef();
  const formik = useFormik({
    initialValues: {
      name: selectedLayer.meta?.name,
    },
    onSubmit: values => {
      dispatch(updateLayer({
        id: selectedLayer.id,
        name: values.name,
      }));
      formik.handleReset();
      dispatch(setEditLayerId(null));
      dispatch(setEditNameModal(false));
    },
    validationSchema,
  });
  const handleClose = () => {
    formik.handleReset();
    dispatch(setEditNameModal(false));
  };
  useEffect(() => {
    if(selectedLayer.meta?.name) {
      formik.setFieldValue('name', selectedLayer.meta.name);
    }
  }, [selectedLayer]);
  return ( 
      <EditNameDialog
        open={editNameModal}
        onClose={handleClose}
      >
          <Content>
            <h2>Rename layer</h2>
            <BaseTextBox 
              width={300} 
              name="name"
              value={formik.values.name}
              errorText={formik.touched.name && formik.errors.name}
              onChangeFormik={formik.handleChange}
              onFormikBlur={formik.handleBlur}
              onEnterClick={formik.submitForm}
              onTabClick={() => btnRef.current.focus()}
            />
            <ButtonWrap>
              <CancelButton ref={btnRef} onClick={handleClose}>
                Cancel
              </CancelButton>
              <BaseButton disabled={!formik.isValid} onClick={formik.submitForm}>
                Update name
              </BaseButton>
            </ButtonWrap>
          </Content>
      </EditNameDialog>
  );
};

export default EditName;