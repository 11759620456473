import React from 'react';
import styled from 'styled-components';
import { TextareaAutosize } from '@mui/material';
import PropTypes from 'prop-types';

const Label = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #3C4049;
  margin-bottom: 16px;
`;

const Container = styled.div`
  textarea {
    background: #F5F5F5;
    border: 1px solid #DCDDE1;
    border-radius: 6px;
    outline: none;
    padding: 6px;
    
    &:focus {
      background: #FFFFFF;
      outline: 1px solid #77B36B;
      box-shadow: 0 0 5px 4px #77B36B;
      -webkit-box-shadow: 0 0 5px 4px #77B36B;
      -moz-box-shadow: 0 0 5px 4px #77B36B;
    }
  }
`;

const BaseTextArea = ({ label, placeholder, width, value, ...rest }) => {

  return (
    <Container {...rest}>
      <Label>{ label }</Label>
      <TextareaAutosize
        placeholder={placeholder}
        minRows={5}
        style={{ width: width ? width + 'px' : '100%' }}
        defaultValue={value}
      />
    </Container>
  );
};

BaseTextArea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.number,
  value: PropTypes.string,
};

export default BaseTextArea;
