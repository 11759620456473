import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Divider, Menu, MenuItem } from '@mui/material';

const StyledMenu = styled(Menu)`
  width: 180px;
`;

const Item = styled(MenuItem)`
  width: 172px;
  height: 32px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  border-radius: 2px;
  padding: 0 10px;

  &:hover {
    background-color: #F5F5F5;
  }
  
  span {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 140%;
    color: #1A1C23;
  }
`;

const BaseMenu = ({ anchorEl, open, items, onClose  }) => {
  const handleClick = (func) => {
    func();
    onClose();
  };

  return (
    <StyledMenu
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      {
        items.map(
          (item, index) => item.separator ? 
            <Divider key={index} /> :
            <Item onClick={() => handleClick(item.action)} key={index} >
              { item.icon }
              <span>{ item.label } {!!open}</span>
            </Item>,
        )
      }
    </StyledMenu>
  );
};

BaseMenu.propTypes = {
  items: PropTypes.array,
  onClose: PropTypes.func,
  anchorEl: PropTypes.any,
  open: PropTypes.bool,
};

export default BaseMenu;
