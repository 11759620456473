import { store } from '../../store';
import { logout } from '../../store/user';

class BaseService {
  constructor(resource) {
    if (!resource) {
      throw new Error('Resource is not provided');
    }

    this.resource = resource;
  }

  getUrl(suffix = '') {
    return suffix ? `/${this.resource}/${suffix}` : `/${this.resource}`;
  }

  getHeaders(multipart = false) {
    const headers = {};

    const token = store.getState().user.token;

    if (token !== null) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    if (multipart) {
      headers['Content-Type'] = 'multipart/form-data';
    }

    return headers;
  }

  async handleErrors(error) {
    switch (error.response.status) {
      case 401:
        if(store.getState().user.token){
          store.dispatch(logout());
        }
        return;
    }

    if (error.response.status === 404) {
      return false;
    }

    return false;
  }
}

export default BaseService;
