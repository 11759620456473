import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as GenerateIcon } from '../../assets/toolbar/generate.svg';
import BaseButton from '../Common/BaseButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearResult,
  updateResult,
  setPrompt,
  setChatId,
  setGenerationEnded,
  setWordsLimit, toggleWordLimit,
} from '../../store/textGenerator';
import BaseTextArea from '../Common/BaseTextArea';
import { EventSourcePolyfill } from 'event-source-polyfill';
import LimitGenerationModal from './LimitGenerationModal';
import { TEXT_KEY } from '../../constants';
import { setLimitGenerationModal, setUsedWordCredits } from '../../store/user';
import { setEditLayerId } from '../../store/builder';
import BaseTextBox from "../Common/BaseTextBox";
import BaseSlider from "../Common/BaseSlider";
import BaseCheckBox from "../Common/BaseCheckBox";
const StyledPanel = styled.div`
  padding: 16px;
  border-bottom: 1px solid #DCDDE1;
  
  > .separator {
    height: 16px;
  }
`;

const WordsLimitContainer = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
`;

const Slider = styled(BaseSlider)`
  width: 82px;
  margin-right: 15px;
`;

const Label = styled.div`
  flex: 0 0 80px;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.875rem;
  text-align: left;
`;

const TextBox = styled(BaseTextBox)`
  input {
    padding: 0 4px;
  }
`;

const TextGeneratorForm = () => {
  const chatId = useSelector(state => state.textGenerator.chatId);
  const prompt = useSelector(state => state.textGenerator.prompt);
  const wordsLimit = useSelector(state => state.textGenerator.wordsLimit);
  const result = useSelector(state => state.textGenerator.result);
  const generationEnded = useSelector(state => state.textGenerator.generationEnded);
  const [disabled, setDisabled] = useState(false);
  const token = useSelector(state => state.user.token);
  const wordCredits = useSelector(state => state.user.wordCredits);
  const usedWordCredits = useSelector(state => state.user.usedWordCredits);
  const text = useSelector(state => state.builder.text);
  const editLayerId = useSelector(({ builder }) => builder.editLayerId);
  const dispatch = useDispatch();

  let source;

  const generateText = () => {
    if (source) {
      source.close();
    }

    dispatch(clearResult());

    setDisabled(true);

    let url = process.env.REACT_APP_API_URL + 'text-generator?prompt=' + prompt;

    if (wordsLimit) {
      url += '&wordsLimit=' + wordsLimit;
    }

    if (chatId) {
      url += '&chatId=' + chatId;
    }

    source = new EventSourcePolyfill(url, { headers: { Authorization: `Bearer ${token}` } });

    source.onmessage = function(event) {
      if (event.type === 'message') {
        dispatch(updateResult(event.data.replace(/\\n/g, '<br>')));
      }
    };

    source.onerror = function(event) {
      if (event.data === 'Max word amount is exceeded') {
        dispatch(setLimitGenerationModal(TEXT_KEY));
        if(usedWordCredits >= wordCredits)
          source.close();
      }
    };

    source.addEventListener('stop', (event) => {
      dispatch(setChatId(event.data));
      source.close();
      dispatch(setGenerationEnded(true));
      setDisabled(false);
    });
  };

  useEffect(() => {
    if (generationEnded) {
      const updatedUsedWordCredits = usedWordCredits + result.replace(/\\n/g, '').split(' ').length;
      dispatch(setUsedWordCredits(updatedUsedWordCredits));
      dispatch(setGenerationEnded(false));
    }
  }, [generationEnded]);

  const handleClick = () => {
    if (!text.id || editLayerId === text.id) return;
    dispatch(setEditLayerId(text.id));
  };

  const handleBlur = () => {
    if (!editLayerId) return;
    dispatch(setEditLayerId(false));
  };

  return (
    <StyledPanel>
      <BaseTextArea
        label="Generate prompt"
        placeholder="give me 5 headlines that convince people of the importance of having engaging Youtube Thumbnail images for your videos"
        onChange={(e) => dispatch(setPrompt(e.target.value))}
        onClick={handleClick}
        onBlur={handleBlur}
      />
      <div className="separator" />
      <WordsLimitContainer>
        <BaseCheckBox
          onChange={() => dispatch(toggleWordLimit())}
        />
        <Label>
          Words limit
        </Label>
        { wordsLimit !== null && <Slider
          value={wordsLimit}
          min={4}
          max={20}
          onChange={(limit) => dispatch(setWordsLimit(limit))}
        /> }
        { wordsLimit !== null && <TextBox
          type="number"
          min={4}
          max={20}
          width={40}
          bottom={0}
          onChange={(limit) => dispatch(setWordsLimit(limit))}
          value={wordsLimit}
          background={'rgb(245, 245, 245)'}
        /> }
      </WordsLimitContainer>
      <div className="separator" />
      <BaseButton
        width={248}
        onClick={generateText}
        disabled={disabled}
      >
        Generate <GenerateIcon stroke="#fff" />
      </BaseButton>
      <LimitGenerationModal type={TEXT_KEY} />
    </StyledPanel>
  );
};

export default TextGeneratorForm;
