import React from 'react';
import PropTypes from "prop-types";
import BaseListHeader from "./BaseListHeader";
import BaseListRow from "./BaseListRow";
import BaseListFooter from "./BaseListFooter";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: calc(100vh - 135px);
  overflow-y: scroll;
  padding-top: 123px;
`;

const Body = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const BaseList = ({ columns, data, currentPage, previous, next }) => {
  return (
    <Container>
      <BaseListHeader
        columns={columns}
      />
      <Body>
        {
          data.map((item, index) => (
            <BaseListRow
              key={index}
              item={item}
              columns={columns}
            />
          ))
        }
      </Body>
      <BaseListFooter
        currentPage={currentPage}
        previous={previous}
        next={next}
      />
    </Container>
  );
};

BaseList.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  currentPage: PropTypes.number,
  previous: PropTypes.func,
  next: PropTypes.func,
};

export default BaseList;