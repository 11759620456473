import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as CaretLeftIcon } from '../../assets/caret-left.svg';
import { ReactComponent as CaretRightIcon } from '../../assets/caret-right.svg';
import { ReactComponent as SaveIcon } from '../../assets/toolbar/save.svg';
import { ReactComponent as CanvasIcon } from '../../assets/toolbar/canvas.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setPreviewedItem, switchSelectedImageIndex } from '../../store/imageGenerator';
import BaseButton from '../Common/BaseButton';
import { addLayer } from '../../store/builder';
import { getImageDimensions, prepareLayerName, recalculateImageDimensions } from '../../helpers';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  position: relative;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 680px;
  height: 680px;
  max-height: calc(100vh - 242px);
  min-height: 100px;
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  height: 680px;
  max-height: calc(100vh - 242px);
  min-height: 100px;
  border-radius: 6px;
`;

const Actions = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
  gap: 16px;
`;

const Action = styled(BaseButton)`
   background-color: #FFFFFF;
   border: 1px solid rgba(0, 0, 0, 0.2);
   color: #1A1C23;
   
   svg {
    stroke: #1A1C23;
   }
`;

const Arrow = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  
  &:hover {
    opacity: 0.7;
  }
`;

const LeftArrow = styled(Arrow)`
  left: 10px;
`;

const RightArrow = styled(Arrow)`
  right: 10px;
`;

const GeneratorPreviewLarge = ({ image, prompt }) => {
  const project = useSelector(state => state.builder.project);
  const dispatch = useDispatch();

  const download = () => {
    const link = document.createElement('a');
    link.download = 'image.png';
    link.href = image;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const addToCanvas = async () => {
    const imageDimensions = await getImageDimensions(image);
    const { width, height } =
      recalculateImageDimensions(
        imageDimensions.w,
        imageDimensions.h,
        project.width / 4,
        project.height / 4,
      );

    await dispatch(addLayer({
      id: project.lastLayerId + 1,
      type: 'image',
      content: image,
      left: project.width / 2 - 128,
      top: project.height / 2 - 128,
      visible: true,
      name: prepareLayerName(prompt),
      width,
      height,
    }));

    dispatch(setPreviewedItem(null));
  };

  return (
    <Container>
      <LeftArrow onClick={() => dispatch(switchSelectedImageIndex(-1))}>
        <CaretLeftIcon />
      </LeftArrow>
      <ImageContainer>
        <Image
          src={image}
          alt="..."
        />
        <Actions>
          <Action
            onClick={download}
          >
            <SaveIcon />
            Download
          </Action>
          <Action
            onClick={addToCanvas}
          >
            <CanvasIcon />
            Add to Canvas
          </Action>
        </Actions>
      </ImageContainer>
      <RightArrow onClick={() => dispatch(switchSelectedImageIndex(1))}>
        <CaretRightIcon />
      </RightArrow>
    </Container>
  );
};

GeneratorPreviewLarge.propTypes = {
  image: PropTypes.string,
  prompt: PropTypes.any,
};

export default GeneratorPreviewLarge;
