import React from 'react';
import styled from 'styled-components';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import PropTypes from 'prop-types';
import { isObject } from 'lodash';

const Container = styled(FormControl)`
  &.MuiFormControl-root {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
`;

const Label = styled(FormLabel)`
  &.MuiFormLabel-root {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #000;
    
    &.Mui-focused {
      color: #000;
    }
  }
`;

const Group = styled(RadioGroup)`

`;

const ValueLabel = styled(FormControlLabel)`
  .MuiTypography-root {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #000000;
  }
`;

const BaseRadioButtonGroup = ({ label, options, value, onChange }) => {
  return (
    <Container>
      <Label id="radio-buttons-group-label">
        { label }
      </Label>
      <Group
        row
        value={value}
        onChange={({ target: { value } }) => onChange(value)}
        aria-labelledby="radio-buttons-group-label"
      >
        {
          options.map((option, index) => (
            <ValueLabel
              key={index}
              value={isObject(option) ? option.value : option}
              control={<Radio size="small" color="success" />}
              label={isObject(option) ? option.label : option}
            />
          ))
        }
      </Group>
    </Container>
  );
};

BaseRadioButtonGroup.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default BaseRadioButtonGroup;
