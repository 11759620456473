import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PreviewIcon } from '../../assets/preview.svg';
import { ReactComponent as RenameIcon } from '../../assets/rename.svg';
import { ReactComponent as CopyIcon } from '../../assets/copy.svg';
import { ReactComponent as DownloadIcon } from '../../assets/download.svg';
import { ReactComponent as TrashIcon } from '../../assets/trash.svg';
import { deleteProject, duplicateProject, setSelectedProject } from '../../store/project';
import BaseMenuTrigger from '../Common/BaseMenuTrigger';
import ProjectPreview from './ProjectPreview';
import ConfirmationPopup from '../Common/ConfirmationPopup';

const ProjectMenu = ({ id, preview, width, height, rename, name, menuOpened }) => {
  const [previewOpened, setPreviewOpened] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const selectedProject = useSelector(({ project }) => project.selectedProject);
  const dispatch = useDispatch();

  const downloadProject = () => {
    const link = document.createElement('a');
    link.download = selectedProject.name + '.png';
    link.href = process.env.REACT_APP_API_URL + 'images/' + preview + '?extension=1';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const unselectProject = () => {
    dispatch(setSelectedProject(null));
    menuOpened(false);
  };

  const handleMenuOpen = () => {
    menuOpened(true);
    if(selectedProject === id) return;
    dispatch(setSelectedProject(id));
  };

  const handleRename = () => {
    if(!rename) return;
    rename();
  };

  const items = [
    {
      icon: <PreviewIcon />,
      label: 'Preview',
      action: () => {setPreviewOpened(true);},
    },
    {
      icon: <RenameIcon />,
      label: 'Rename',
      action: handleRename,
    },
    {
      icon: <CopyIcon />,
      label: 'Make a copy',
      action: () => dispatch(duplicateProject()),
    },{
      icon: <DownloadIcon />,
      label: 'Download',
      action: downloadProject,
    },
    { 
      separator: true,
    },
    {
      icon: <TrashIcon />,
      label: 'Delete',
      action: () => setConfirmDelete(true),
    },
  ];

  const handleDelete = () => {
    dispatch(setSelectedProject(id));
    dispatch(deleteProject());
  };

  return (
    <Fragment>
      <BaseMenuTrigger
        onClick={handleMenuOpen}
        close={selectedProject !== id}
        items={items}
        onClose={() => unselectProject()}
      />
      { previewOpened &&
        <ProjectPreview
          image={preview}
          name={name}
          width={width}
          height={height}
          open={previewOpened}
          handleClose={() => setPreviewOpened(false)}
        />
      }
      {confirmDelete &&
        <ConfirmationPopup 
          open={confirmDelete} 
          cancel={() => setConfirmDelete(false)} 
          confirm={handleDelete}
          title={`Are you sure you would like to delete ${name}?`}
          confirmText='Confirm delete'
        />
      }
    </Fragment>
  );
};

ProjectMenu.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  preview: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  rename: PropTypes.func,
  menuOpened: PropTypes.func,
};

export default ProjectMenu;
