import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { ReactComponent as GenerateIcon } from '../../assets/toolbar/generate.svg';
import { ReactComponent as CloseIcon } from '../../assets/times.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setPreviewedItem } from '../../store/imageGenerator';
import GeneratorPreviewLarge from './GeneratorPreviewLarge';
import GeneratorPreviewThumbnail from './GeneratorPreviewThumbnail';

const Container = styled(Dialog)`
  .MuiPaper-root {
    width: 712px;
    height: calc(100vh - 72px);
    max-height: 850px;
  }
`;

const Title = styled(DialogTitle)`
  display: flex;
  gap: 10px;
  align-items: center;

  .icon {
    stroke: #77B26B;
  }
  
  .title {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #3C4049;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .close {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #3C4049;
    cursor: pointer;
  }
  
  .close-icon {
    cursor: pointer;
  }
`;

const Content = styled(DialogContent)`

`;

const Items = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
`;

const GeneratorPreview = () => {
  const item = useSelector(state => state.imageGenerator.previewedItem);
  const selectedImageIndex = useSelector(state => state.imageGenerator.selectedImageIndex);
  const dispatch = useDispatch();

  const close = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }

    dispatch(setPreviewedItem(null));
  };

  return (
    <Container
      maxWidth="1032px"
      open={item !== null}
      onClose={close}
    >
      {
        item !== null &&
        <Fragment>
          <Title>
            <GenerateIcon className="icon" />
            <span className="title">
          { item.prompt }
        </span>
            <span
              className="close"
              onClick={close}
            >
          Close
        </span>
            <CloseIcon className="close-icon" onClick={close} />
          </Title>
          <Content>
            <GeneratorPreviewLarge
              image={item.generatedImages[selectedImageIndex].content}
              prompt={item.prompt}
            />
            <Items>
              {
                item.generatedImages.map((image, index) => (
                  <GeneratorPreviewThumbnail
                    key={index}
                    index={index}
                    image={image.content}
                  />
                ))
              }
            </Items>
          </Content>
        </Fragment>
      }
    </Container>
  );
};

export default GeneratorPreview;
