import React from 'react';
import styled from 'styled-components';
import RemoveIcon from '@mui/icons-material/Remove';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { IconButton } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/times.svg';
import PropTypes from 'prop-types';

const HeaderButtons = styled.div`
  display: flex;
  width: 50px;
  justify-content: space-between;
  -webkit-app-region: no-drag;
`;

const HeaderButton = styled(IconButton)`
  &.MuiButtonBase-root{
    background-color: #dcdde1;
    width: 10px;
    height: 10px;
    -webkit-app-region: no-drag;
    svg {
      display: none;
    }
    &:hover {
      padding: 0;
      svg {
        display: block;
        height: 7.5px;
        width: 7.5px;
      }
    }
  }
`;

const CloseButton = styled(HeaderButton)`
  &.MuiButtonBase-root{
    background-color: #ff605c;
    &:hover {
      padding: 2.5px;
      background-color: #ff605c;
      svg {
        display: block;
        height: 5px;
        width: 5px;
      }
    }
  }
`;
const MinimizeButton = styled(HeaderButton)`
  &.MuiButtonBase-root{
    background-color: #ffbd44;
    &:hover {
      background-color: #ffbd44;
    }
  }
`;
const FullScreenButton = styled(HeaderButton)`
  &.MuiButtonBase-root{
    background-color: #00ca4e;
    &:hover {
      background-color: #00ca4e;
    }
  }
`;

const MacButtons = ({ close, minimize, toogleFullScreen }) => {
    return (
        <HeaderButtons>
            <CloseButton onClick={close} size="small" aria-label="close">
                <CloseIcon />
            </CloseButton>
            <MinimizeButton onClick={minimize} size="small">
                <RemoveIcon />
            </MinimizeButton>
            <FullScreenButton onClick={toogleFullScreen} size="small">
                <FullscreenExitIcon />
            </FullScreenButton>
        </HeaderButtons>
    );
};

MacButtons.propTypes = {
    close: PropTypes.func,
    minimize: PropTypes.func,
    toogleFullScreen: PropTypes.func,
};

export default MacButtons;