import React, { Fragment } from 'react';
import styled from 'styled-components';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTemplates, fetchTemplatesNextPage, setSearch, setSelectedCategory } from '../../store/template';
import { BackButton, SimpleButton } from '../Common/BaseButton';
import PropTypes from 'prop-types';
import { setProjectTemplate } from '../../store/project';
import { useInView } from 'react-intersection-observer';
import Loader from '../Common/Loader';
import BaseTextBox from '../Common/BaseTextBox';
import SearchIcon from '@mui/icons-material/Search';

const Header = styled.div`
  display: flex;    
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
`;

const Title = styled(DialogTitle)`
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.4375rem;
  color: #3C4049;
`;

const Content = styled(DialogContent)`
  display: flex;
  
  &.MuiDialogContent-root {
    padding: 0;
  }
`;

const Sidebar = styled.div`
  width: 240px;
  height: 650px;
  padding: 0 24px;
  overflow-y: auto;
  
  > h5 {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #7C8394;
  }
`;

const Categories = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CategoryName = styled.div`
  flex: 1;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #3C4049;
`;

const Category = styled.div`
  display: flex;
  cursor: pointer;
  width: 192px;
  height: 26px;
  padding: 4px 8px;

  &:hover, &.selected {
    background-color: #F4FCE1;
    
    > ${CategoryName} {
      color: #66995C;
    }
  }
`;

const TemplateCount = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #989FAD;
`;

const TemplatesContainer = styled.div`
  width: 720px;
  height: 650px;
  padding: 24px;
  background-color: #F5F5F5;
  overflow-y: auto;
`;

const TemplatesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px 8px;
`;

const TemplateImage = styled.img`
  width: 197.33px;
  border-radius: 4px;
  object-fit: cover;
`;

const EmptyTemplate = styled.div`
  width: 197.33px;
  height: calc(197.33px * ${({ c }) => c ? c : 0.75});
  background: #F5F5F5;
  border-radius: 4px;
`;

const TemplateName = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #3C4049;
`;

const TemplateCategory = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #989FAD;
`;

const Template = styled.div`
  border: 2px solid #FFFFFF;
  background: #FFFFFF;
  border-radius: 6px;
  cursor: pointer;
  display:flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 8px;
  
  &:hover, &.selected {
    border: 2px solid #77B36B;
    
    > ${TemplateName} {
      color: #77B36B;
    }
  }
`;

const Actions = styled(DialogActions)`
  &.MuiDialogActions-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const LoaderWrap = styled.div`
  grid-column-end: 3;
`;

let timeout;

const NewProjectTemplates = ({ goBack, save }) => {
  const categories = useSelector(({ template }) => template.categories);
  const selectedCategory = useSelector(({ template }) => template.selectedCategory);
  const selectedTemplate = useSelector(({ project }) => project.project.template);
  const selectedSize = useSelector(({ project }) => project.project.size);
  const templates = useSelector(({ template }) => template.templates);
  const pagination = useSelector(({ template }) => template.pagination);
  const search = useSelector(({ template }) => template.search);
  const dispatch = useDispatch();

  const coef = {
    2: 1,
    3: 1,
    4: 0.5625,
    5: 1,
    6: 5/6,
  };

  const { ref } = useInView({
    onChange: (inView) => {
      if (inView && !pagination.loading) {
        dispatch(fetchTemplatesNextPage());
      }
    },
  });

  const selectCategory = (category) => {
    dispatch(setSelectedCategory(category));
    dispatch(fetchTemplates());
  };

  const updateTemplates = (immediate = false) => {
    clearTimeout(timeout);
    if (immediate) return dispatch(fetchTemplates());
    timeout = setTimeout(() => {
      dispatch(fetchTemplates());
    }, 500);
  };

  const handleSearch = (value) => {
    dispatch(setSearch(value));
    updateTemplates();
  };

  const back = () => {
    dispatch(setSelectedCategory(null));
    goBack();
  };

  return (
    <Fragment>
      <Header>
        <Title>
          Choose template
        </Title>
        <BaseTextBox
          placeholder="Search template"
          width={268}
          icon={<SearchIcon />}
          bottom={0}
          value={search}
          onChange={handleSearch}
          onEnterClick={() => updateTemplates(true)}
        />
      </Header>
      <Content>
        <Sidebar>
          <h5>Categories</h5>
          <Categories>
            {
              categories.map((category, index) => (
                <Category
                  className={category.id === selectedCategory ? 'selected' : ''}
                  key={index}
                  onClick={() => selectCategory(category.id)}
                >
                  <CategoryName>{ category.name }</CategoryName>
                  <TemplateCount>{ category.templatesCount }</TemplateCount>
                </Category>
              ))
            }
          </Categories>
        </Sidebar>
        <TemplatesContainer>
          <TemplatesGrid>
            {!templates.length && pagination.loading && 
              <LoaderWrap>
                <Loader />
              </LoaderWrap>
            }
            {((!pagination.loading || !!templates.length) && selectedCategory === null)  &&
              <Template
                className={!selectedTemplate ? 'selected' : ''}
                onClick={() => dispatch(setProjectTemplate((null)))}
              >
                <EmptyTemplate c={coef[selectedSize]} />
                <TemplateName>Blank</TemplateName>
                <TemplateCategory>Create project from scratch</TemplateCategory>
              </Template>
            }
            {
              templates.map((template, index) => (
                <Template
                  className={template.id === selectedTemplate ? 'selected' : ''}
                  key={index}
                  onClick={() => dispatch(setProjectTemplate((template.id)))}
                >
                  { template.preview && (
                    <TemplateImage
                      src={process.env.REACT_APP_API_URL + 'images/' + template.preview}
                      alt={ template.name }
                    />
                  )}
                  { !template.preview && <EmptyTemplate c={coef[selectedSize]} /> }
                  <TemplateName>
                    { template.name }
                  </TemplateName>
                  <TemplateCategory>
                    { template.categoryName || 'Create project from scratch' }
                  </TemplateCategory>
                </Template>
              ))
            }
            {!!templates.length && !pagination.isLastPage && 
              <LoaderWrap>
                <Loader ref={ref} />
              </LoaderWrap>
            }
          </TemplatesGrid>
        </TemplatesContainer>
      </Content>
      <Actions>
        <BackButton
          onClick={back}
        />
        <SimpleButton
          rightIcon={false}
          onClick={save}
        >
          Create project
        </SimpleButton>
      </Actions>
    </Fragment>
  );
};

NewProjectTemplates.propTypes = {
  goBack: PropTypes.func,
  save: PropTypes.func,
};

export default NewProjectTemplates;
