import React from 'react';
import styled from 'styled-components'; 
import BaseSlider from '../Common/BaseSlider';
import { useDispatch, useSelector } from 'react-redux';
import { setEraserSize } from '../../store/builder';

const Container = styled.div`
  width: min-content;
  display: flex;
  position: fixed;
  z-index: 99;
  right: 16px;
  bottom: 16px;
  align-items: center;
  justify-content: center;    
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SliderWrap = styled.div`
  width: 250px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background: #fff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  &:first-child {
    border-left: none
  }
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-align: center;
  color: #7C8394;
`;

const Slider = styled(BaseSlider)`
  width: 100%
`;

const EraserConfigs = () => {
    const dispatch = useDispatch();
    const eraserSize = useSelector(state => state.builder.eraserSize);

    return (
        <Container>
            <SliderWrap>
            <Label>Eraser size</Label>
            <Slider
                name="image"
                onChange={(value) => dispatch(setEraserSize(value))}
                value={eraserSize}
                min={5}
                max={50}
            />
            <Label>{eraserSize}</Label>
            </SliderWrap>
        </Container>
    );
};

export default EraserConfigs;