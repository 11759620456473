import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@mui/material';
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { useDispatch, useSelector } from 'react-redux';
import { addLayer } from "../../store/builder";

const Container = styled.div`
  height: calc(100vh - 370px);
  overflow-y: auto;
  padding: 16px;
`;

const Title = styled.h4`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #3C4049;
  margin: 0 0 16px;
`;

const Content = styled.div`
  display: flex;
`;

const Result = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #3C4049;
  flex: 1;
`;

const Plus = styled.div`
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F4FCE1;
  cursor: pointer;
  
  > svg {
    width: 15px;
    height: 15px;
  }
  
  &:hover {
    border: 1px solid #77B36B;
  }
`;

const TextGeneratorResults = () => {
  const result = useSelector(state => state.textGenerator.result);
  const project = useSelector(state => state.builder.project);
  const dispatch = useDispatch();

  const addText = () => {
    const textLayer = {
      id: project.lastLayerId + 1,
      type: 'text',
      font: {
        family: 'Arial',
        size: 35,
      },
      bold: false,
      italic: false,
      lineThrough: false,
      underline: false,
      gradient: {
        applied: false,
        colorA: '#FF0000',
        colorB: '#4A90E2',
        startPoint: 25,
        endPoint: 75,
        angle: 180,
      },
      color: '#FF0000',
      background: '#FFFFFF00',
      stroke: {
        applied: false,
        color: '#FF0000',
        width: 0,
      },
      shadow: {
        applied: false,
        horizontalOffset: 6,
        verticalOffset: 7,
        blur: 3,
        color: '#C0C0C0',
      },
      textAlign: 'left',
      content: result.replace(/<br>/g, '\n'),
      name: result,
      left: 50,
      top: project.height / 2 - 10,
      height: 100,
      width: 500,
      visible: true,
    };
    dispatch(addLayer(textLayer));
  };

  return (
    <Container>
      { result.length > 0 && <Title>Results</Title> }
      <Content>
        <Result dangerouslySetInnerHTML={{ __html: result }}>
        </Result>
        { result.length > 0 && <Tooltip
          arrow
          placement="top"
          title="Add generated text to the canvas"
        >
          <Plus onClick={addText}>
            <PlusIcon />
          </Plus>
        </Tooltip> }
      </Content>
    </Container>
  );
};

export default TextGeneratorResults;
