import { Dialog, DialogContent } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as TrashIcon } from '../../assets/trash-transparent.svg';
import { ReactComponent as SaveIcon } from '../../assets/save.svg';
import BaseButton, { RedButton, WhiteButton } from './BaseButton';

const Icon = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 40px;
    height: 40px;
    width: ${props => props.big ? 60 : 40}px;
    height: ${props => props.big ? 60 : 40}px;
    stroke: #f37171;
  }
`;

const ButtonsWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 0.5em;
`;

const Content = styled(DialogContent)`
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    h3 {
        max-width: 320px;
        margin-bottom: 0;
        color: ${props => props.type === 'warning' ? '#77b36b' : 'inherit'};
    }
    p {
        max-width: 300px;
    }
`;

const ConfirmationPopup = ({ 
    open,
    type = 'error',
    icon = 'delete', 
    title = 'Are you sure?', 
    description = 'This cannot be undone.',  
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    confirm,
    cancel,
}) => {
    return (
        <Dialog open={open}>
            <Content type={type}>
                {icon === 'delete' && (
                    <Icon>
                        <TrashIcon/>
                    </Icon>
                )}
                {icon === 'save' && (
                    <Icon big>
                        <SaveIcon/>
                    </Icon>
                )}
                <h3>{title}</h3>
                <p>{description}</p>
                <ButtonsWrap>
                    <WhiteButton rightIcon={false} width={130} onClick={cancel}>
                        {cancelText}
                    </WhiteButton>
                    {type === 'error' &&
                        <RedButton width={130} onClick={confirm}>
                            {confirmText}
                        </RedButton>
                    }
                    {type === 'warning' &&
                        <BaseButton width={130} onClick={confirm}>
                            {confirmText}
                        </BaseButton>
                    }
                </ButtonsWrap>
            </Content>
        </Dialog>
    );
};

ConfirmationPopup.propTypes = {
    open: PropTypes.bool,
    type: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    confirm: PropTypes.func,
    cancel: PropTypes.func,
};
  
export default ConfirmationPopup;