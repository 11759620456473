import React from 'react';
import styled from 'styled-components';
import BasePanel from '../Common/BasePanel';
import TextGenerator from './TextGenerator';
import TextStylePanel from './TextStylePanel';
import { useSelector } from 'react-redux';

const Container = styled(BasePanel)`
> div:nth-child(2) {
    display: flex;
    align-items: stretch;
  }
`;

const TextPanel = () => {
  const generateTextOpened = useSelector(({ builder }) => builder.generateTextOpened);

  return (
    <Container
      panel="text"
      width={generateTextOpened ? 580 : 290}
      collapsable
    >
      <TextStylePanel />
      { generateTextOpened && <TextGenerator /> }
    </Container>
  );
};

export default TextPanel;
