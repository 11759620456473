import ModelService from '../base/ModelService';
import api from '../index';

class Project extends ModelService {
  constructor() {
    super('projects');
  }

  async save(
    {
      id,
      content,
      lastLayerId,
      size,
      width,
      height,
      background,
    },
    stageContent,
  ) {
    await api.project.patch(
      id,
      {
        content,
        lastLayerId,
        size: size.id,
        width,
        height,
        background,
        preview: stageContent,
      },
    );
  }
}

export default new Project();
