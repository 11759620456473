import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import BaseUploader from '../Common/BaseUploader';
import { useDispatch, useSelector } from 'react-redux';
import { confirmReferenceImage, removeReferenceImage, setReferenceImage } from '../../store/imageGenerator';
import { ReactComponent as TrashIcon } from '../../assets/trash.svg';
import { CancelButton, SimpleButton } from '../Common/BaseButton';
import PropTypes from 'prop-types';

const Container = styled.div`
  padding: 16px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Image = styled.img`
  width: 428px;
  height: auto;
  border-radius: 4px;
`;

const ImageDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ImageInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ImageName = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #3C4049;
`;

const ImageSize = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #7C8394;
`;

const Delete = styled.div`
  cursor: pointer;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-top: 1px solid #DCDDE1;
`;

const ImageImporterUpload = ({ handleClose }) => {
  const referenceImage = useSelector(state => state.imageGenerator.referenceImage);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  const upload = () => {
    const file = document.querySelector('#upload-file').files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      if (file.size > 2900000)
        setError({ title: 'Failed to upload your file.', description: 'Maximum upload file size: 2.9MB.' });
      else
        dispatch(setReferenceImage({
          name: file.name,
          size: Math.floor(file.size / 1024) + 'kb',
          content: reader.result,
        }));
    };
  };

  const cancel = () => {
    dispatch(removeReferenceImage());
    handleClose();
  };

  const confirm = () => {
    dispatch(confirmReferenceImage());
    handleClose();
  };

  return (
    <Fragment>
      <Container>
        {
          !referenceImage.content &&
          <BaseUploader
            id="upload-file"
            width={420}
            height={280}
            showNote={false}
            upload={upload}
            error={error}
          />
        }
        {
          referenceImage.content &&
          <ImageContainer>
            <Image
              src={referenceImage.content}
              alt="..."
            />
            <ImageDetails>
              <ImageInfo>
                <ImageName>
                  { referenceImage.name }
                </ImageName>
                <ImageSize>
                  { referenceImage.size }
                </ImageSize>
              </ImageInfo>
              <Delete onClick={() => dispatch(removeReferenceImage())}>
                <TrashIcon />
              </Delete>
            </ImageDetails>
          </ImageContainer>
        }
      </Container>
      <Actions>
        <CancelButton
          width={206}
          onClick={cancel}
        />
        <SimpleButton
          width={206}
          disabled={referenceImage.content === null}
          onClick={confirm}
        >
          Import
        </SimpleButton>
      </Actions>
    </Fragment>
  );
};

ImageImporterUpload.propTypes = {
  handleClose: PropTypes.func,
};

export default ImageImporterUpload;
