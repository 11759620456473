import React from 'react';
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LogoImg } from '../../assets/logo.svg';

const Container = styled.div`
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100vw;
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 16px;
  background: #FFFFFF;
  border-bottom: 1px solid #DCDDE1;
  height: 40px;
  -webkit-user-select: none;
  -webkit-app-region: drag;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  
  svg {
    height: 24px;
  }
`;

const Menu = styled.div`
  display: flex;
  gap: 20px;
`;

const MenuItem = styled.div`
  cursor: pointer;
  
  &.active, &:hover {
    text-decoration: underline;
  }
`;

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Container>
      <LogoContainer>
        <LogoImg />
      </LogoContainer>
      <Menu>
        <MenuItem
          onClick={ () => navigate('/admin/assets') }
          className={ location.pathname.startsWith('/admin/assets') ? 'active' : '' }
        >
          Assets
        </MenuItem>
        <MenuItem
          onClick={ () => navigate('/admin/users') }
          className={ location.pathname.startsWith('/admin/users')  ? 'active' : '' }
        >
          Users
        </MenuItem>
        <MenuItem
          onClick={ () => navigate('/admin/templates') }
          className={ location.pathname.startsWith('/admin/templates') ? 'active' : '' }
        >
          Templates
        </MenuItem>
      </Menu>
    </Container>
  );
};

export default Header;