import React from 'react';
import styled from 'styled-components';
import NewProjectBasicsSizesOption from './NewProjectBasicsSizesOption';
import { useSelector } from 'react-redux';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const NewProjectBasicsSizes = () => {
  const sizes = useSelector(state => state.builder.sizes);

  return (
    <Container>
      {
        sizes.map((size, index) => (
          <NewProjectBasicsSizesOption
            key={index}
            {...size}
          />
        ))
      }
    </Container>
  );
};

export default NewProjectBasicsSizes;
