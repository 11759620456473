import { store } from '../../store';
import { decreaseLoading, increaseLoading } from '../../store/loader';
import ModelService from '../base/ModelService';

class Login extends ModelService {
  constructor() {
    super('login');
  }

  async login(body = {}) {
    store.dispatch(increaseLoading());

    try {
      const { data } = await this.axios.post(
        this.getUrl(),
        body,
        {
          headers: this.getHeaders(),
        },
      );
      return { data };
    } catch (err) {
      return { error: err.response.data.message };
    } finally {
      store.dispatch(decreaseLoading());
    }
  }
}

export default new Login();
