import React from 'react';
import styled from 'styled-components';
import { ReactComponent as AvatarImg } from '../../assets/user-avatar.svg';
import { ReactComponent as ProfileIcon } from '../../assets/profile.svg';
import { ReactComponent as PasswordIcon } from '../../assets/password.svg';
import { ReactComponent as LogoutIcon } from '../../assets/logout.svg';
import { useDispatch, useSelector } from 'react-redux';
import { logout, setChangePasswordModal, setChangeProfileModal } from '../../store/user';
import PropTypes from 'prop-types';

const Container = styled.div`
  
`;

const UserInfo = styled.div`
  margin: 16px 16px 0;
  width: 228px;
  border-bottom: 1px solid #DCDDE1;
`;

const UserAvatar = styled.div`
  text-align: center;
  height: 48px;
  margin-bottom: 10px;
  
  svg, img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
`;

const UserName = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #000000;
  text-align: center;
  margin-bottom: 4px;
`;

const UserEmail = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #7C8394;
  text-align: center;
  margin-bottom: 6px;
`;

const UserPlan = styled.div`
  border-radius: 4px;
  align-items: center;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin: 0 auto 16px;
  width: fit-content;
  padding: 4px 6px;
  color: #5a88cb;
  background-color: #d9efff;
`;

const CreditsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  margin: 0 16px;
  border-bottom: 1px solid #DCDDE1;
`;

const UserCredits = styled.div`
  width: 109px;
  height: 44px;
  background: #F5F5F5;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  div:first-child {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #000000;
  }
  
  div:last-child {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #7C8394;
  }
`;

const Items = styled.div`
  border-bottom: 1px solid #DCDDE1;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
`;

const Item = styled.div`
  height: 32px;
  margin: 0 16px;
  display: flex;
  align-items: center;
  gap: 17px;
  margin-top: 12px;
  cursor: pointer;
  
  &:hover {
    background-color: #F5F5F5;
  }
  
  div {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #000000;
  }
  
  svg {
    
  }
`;

const MenuContent = ({ close }) => {
  const {
    name,
    email,
    avatar,
    imageCredits,
    usedImageCredits,
    wordCredits,
    usedWordCredits,
    plan,
  } = useSelector(state => state.user);
  const dispatch = useDispatch();
  
  const changeProfile = () => {
    dispatch(setChangeProfileModal(true));
    close();
  };

  const changePassword = () => {
    dispatch(setChangePasswordModal(true));
    close();
  };

  return (
    <Container>
      <UserInfo>
        <UserAvatar>
          { avatar &&
            <img src={process.env.REACT_APP_API_URL + 'images/' + avatar } alt="" style={{ objectFit: 'cover' }} width={50} height={50} />
          }
          {
            !avatar && <AvatarImg />
          }
        </UserAvatar>
        <UserName>
          { name }
        </UserName>
        <UserEmail>
          { email }
        </UserEmail>
        <UserPlan>
          { plan } plan
        </UserPlan>
      </UserInfo>
      <CreditsContainer>
        <UserCredits>
          <div>{usedImageCredits}/{ imageCredits }</div>
          <div>images/month</div>
        </UserCredits>
        <UserCredits>
          <div>{usedWordCredits}/{ wordCredits }</div>
          <div>words/month</div>
        </UserCredits>
      </CreditsContainer>
      <Items>
        <Item onClick={changeProfile}>
          <ProfileIcon />
          <div>Profile</div>
        </Item>
        <Item onClick={changePassword}>
          <PasswordIcon />
          <div>Password</div>
        </Item>
      </Items>
      <Item onClick={() => dispatch(logout())}>
        <LogoutIcon />
        <div>Log out</div>
      </Item>
    </Container>
  );
};

MenuContent.propTypes = {
    close: PropTypes.func,
};

export default MenuContent;
