import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layer, Rect, Stage } from 'react-konva';
import { addLayer, emptySelectedLayer, setExportDialog, setExportRequested, setStageContent, setSaveRequested, saveProject, emptyLasso, setTool, setSelectedLayer, setPanel, emptySelectedText, setSelectedText, updateProjectDimensions, emptyBackgroundPreview, setDuplicateRequested, setSaveRedirect } from '../../store/builder';
import CanvasImage from './CanvasImage';
import CanvasText from './CanvasText';
import EraserConfigs from './EraserConfigs';
import Cursor from './Cursor';
import ImageConfigs from './ImageConfigs';
import { getImageDimensions, prepareLayerName, recalculateImageDimensions } from '../../helpers';
import { ResizableBox } from 'react-resizable';
import RemoveBackground from './RemoveBackground';
import EditName from './EditName';
import CanvasGroup from './CanvasGroup';
import { useNavigate } from 'react-router-dom';

let timeout;
const Canvas = () => {
  const draggedImage = useSelector(state => state.builder.draggedImage);
  const selectedLayer = useSelector(state => state.builder.selectedLayer);
  const selectedText = useSelector(state => state.builder.selectedText);
  const text = useSelector(state => state.builder.text);
  const lasso = useSelector(state => state.builder.lasso);
  const project = useSelector(state => state.builder.project);
  const tool = useSelector(state => state.builder.tool);
  const panel = useSelector(state => state.builder.panel);
  const zoom = useSelector(state => state.builder.zoom);
  const exportRequested = useSelector(state => state.builder.exportRequested);
  const saveRequested = useSelector(state => state.builder.saveRequested);
  const saveRedirect = useSelector(state => state.builder.saveRedirect);
  const loading = useSelector(state => state.loader.loading);
  const eraserSize = useSelector(state => state.builder.eraserSize);
  const imageBackgroundPreview = useSelector(state => state.builder.imageBackgroundPreview);
  const duplicateRequested = useSelector(state => state.builder.duplicateRequested);
  const mergeDownLayer = useSelector(state => state.builder.mergeDownLayer);
  const stageRef = useRef();
  const wrapRef = useRef();
  const dispatch = useDispatch();
  const [isDrawing, setIsDrawing] = useState(false);
  const [lines, setLines] = useState([]);
  const [showCursor, setShowCursor] = useState(true);
  const [canvasHeight, setCanvasHeight] = useState(project.height * zoom / 100);
  const [canvasWidth, setCanvasWidth] = useState(project.width * zoom / 100);
  const [mergeLayers, setMergeLayers] = useState();
  const navigate = useNavigate();

  const handleMouseDown = (e) => {
    if(tool !== 'eraser' || !selectedLayer.id) return deselectAll(e);
    if(!['Image'].includes(e.target.className)
    || Math.round(selectedLayer.meta.left) !== Math.round(e.target.absolutePosition().x * 100 / zoom)
    || Math.round(selectedLayer.meta.top) !== Math.round(e.target.absolutePosition().y * 100 / zoom)
    ) return;
    const pos = e.target.getStage().getPointerPosition();
    pos.x = (pos.x * 100 / zoom) - selectedLayer.meta.left;
    pos.y = (pos.y * 100 / zoom) - selectedLayer.meta.top;
    setIsDrawing(true);
    setLines([...lines, { 
      tool: 'eraser', 
      points: [pos.x, pos.y], 
      selectedLayerId: selectedLayer.id, 
      strokeWidth: eraserSize,
    }]);
  };

  const handleMouseMove = (e) => {
    if(tool !== 'eraser' || !selectedLayer.id) return;
    if (!isDrawing) return;
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    point.x = (point.x * 100 / zoom) - selectedLayer.meta.left;
    point.y = (point.y * 100 / zoom) - selectedLayer.meta.top;
    const lastLine = lines[lines.length - 1];
    lastLine.points = lastLine.points.concat([point.x, point.y]);
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = (e) => {
    if(tool !== 'eraser' || !selectedLayer.id) return;
    if (!isDrawing) return;
    setIsDrawing(false);
    const image = e.target.parent.children[0];
    let lastLine = lines[lines.length - 1];
    if(selectedLayer.meta.rotation) {
      const xArray = lastLine.points.filter((v, i) => !(i % 2));
      const yArray = lastLine.points.filter((v, i) => i % 2);
      const points = [];
      const pointsToSet = [];
      for (const [i, x] of xArray.entries()) {
        points.push({ x: (x + selectedLayer.meta.left) / 100 * zoom, y: (yArray[i] + selectedLayer.meta.top) / 100 * zoom });
      }
      for (const point of points) {
        if (image.intersects(point)) {
          pointsToSet.push(
            (point.x * 100 / zoom) - selectedLayer.meta.left, 
            (point.y * 100 / zoom) - selectedLayer.meta.top,
          );
        } 
      }
      lastLine.points = pointsToSet;
      lines.splice(lines.length - 1, 1, lastLine);
      setLines(lines.concat());
      return;
    } 
    const positionLimits = {
      x: {
        min: image.position().x + eraserSize / 2,
        max: image.position().x + image.width() - eraserSize / 2,
      },
      y: {
        min: image.position().y + eraserSize / 2,
        max: image.position().y + image.height() - eraserSize / 2,
      },
    };
    const xArray = lastLine.points.filter((v, i) => !(i % 2));
    const yArray = lastLine.points.filter((v, i) => i % 2);
    for (const [i, x] of xArray.entries()) {
      if(x > positionLimits.x.max) {
        xArray.splice(i, 1, positionLimits.x.max);
      }
      if(x < positionLimits.x.min) {
        xArray.splice(i, 1, positionLimits.x.min);
      }
    }
    for (const [i, y] of yArray.entries()) {
      if(y > positionLimits.y.max) {
        yArray.splice(i, 1, positionLimits.y.max);
      }
      if(y < positionLimits.y.min) {
        yArray.splice(i, 1, positionLimits.y.min);
      }
    }
    const points = [];
    for (const [i, x] of xArray.entries()) {
      points.push(x, yArray[i]);
    }
    lastLine.points = points;
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const deselectAll = (e) => {
    // deselect when clicked on empty area
    if (!selectedLayer.id) return;

    const clickedOnSelected =
      ['Transformer', 'Image', 'Text'].includes(e.target.className)
      || e.target.parent?.className === 'Transformer';
    if (clickedOnSelected) return;

    if (selectedLayer.meta.type === 'image') {
      if (panel === 'filter') {
        dispatch(setPanel(null));
      }

      dispatch(setTool('select'));
    }

    if (panel === 'text') {
      dispatch(setPanel(null));
      dispatch(emptySelectedText());
    }

    dispatch(emptySelectedLayer());
  };

  useEffect(() => {
    if (imageBackgroundPreview) {
      dispatch(emptyBackgroundPreview());
    }

    if (selectedLayer === null || selectedLayer.id === null) {
      return;
    }

    const layer = selectedLayer.meta;

    if (
      (layer.type === 'image' && ['select', 'eraser', 'lasso', 'background'].indexOf(tool) === -1) ||
      (layer.type === 'text' && 'select' !== tool)
    ) {
      dispatch(emptySelectedLayer());
    }
  }, [tool]);

  useEffect(() => {
    dispatch(setTool('select'));
  }, [selectedLayer.id]);

  useEffect(() => {
    if (!exportRequested) return;
    (async () => {
      const { fileFormat, width, height } = exportRequested;
      const scaleX = width / canvasWidth;
      const scaleY = height / canvasHeight;

      stageRef.current.width(width);
      stageRef.current.height(height);
      stageRef.current.scaleX(scaleX);
      stageRef.current.scaleY(scaleY);
      
      const uri = stageRef.current.toDataURL({ 
        mimeType: fileFormat === 'JPG' ? 'image/jpeg' : 'image/png',
      });
      const link = document.createElement('a');
      link.download = project.name + (fileFormat === 'JPG' ? '.jpg' : '.png');
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      stageRef.current.scaleX(zoom / 100);
      stageRef.current.scaleY(zoom / 100);
      stageRef.current.width(canvasWidth * zoom / 100);
      stageRef.current.height(canvasHeight * zoom / 100);

      dispatch(setExportRequested(null));
      dispatch(setExportDialog(false));
    })();
  }, [exportRequested]);

  useEffect(() => {
    if (saveRequested && !loading) {
      (async () => {
        await dispatch(emptySelectedLayer());
        await dispatch(setStageContent(stageRef.current.toDataURL()));
        await dispatch(saveProject());
        await dispatch(setSaveRequested(false));
        if (saveRedirect) {
          dispatch(setSaveRedirect(null));
          navigate('/');
        }
      })();
    }
  }, [saveRequested, loading]);

  useEffect(() => {
    if (!duplicateRequested) return;

    const layerToDuplicate = project.content.find(l => l.id === duplicateRequested);
    const layerId = project.lastLayerId + 1;
    dispatch(addLayer({
      ...layerToDuplicate,
      id: layerId,
      name: prepareLayerName(layerToDuplicate.name, true),
      left: layerToDuplicate.left + 20,
      top: layerToDuplicate.top + 20,
    }));

    dispatch(setDuplicateRequested(null));
    dispatch(setSelectedLayer({ id: layerId })); 
  }, [duplicateRequested]);

  const getStyle = () => {
    const style = { transform: 'translateZ(-10px)' };

    if(panel === 'text' && selectedText === null)  {
      style.cursor = 'text';
    }

    if(tool === 'eraser' && selectedLayer.id && showCursor) {
      style.cursor = 'none';
    }

    return style;
  };

  const handleMouseEnter = () => {
    if(tool === 'eraser')
      setShowCursor(true);
  };
  
  const handleMouseLeave = () => {
    if(tool === 'eraser')
      setShowCursor(false);
  };

  const removeLinesByLayer = (layerId) => {
    setLines(lines.filter(l => l.selectedLayerId !== layerId));
  };

  const addLine = (layerId, points) => {
    setLines([...lines, { 
      tool: 'lasso', 
      points, 
      selectedLayerId: layerId, 
      strokeWidth: eraserSize,
    }]);
    dispatch(setTool(null));
    setTimeout(() => dispatch(setTool('lasso')));
  };

  useEffect(() => {
    const handleKeyDown = async (event) => {
      event.preventDefault();
      const code = event.which || event.keyCode;
      let charCode = String.fromCharCode(code).toLowerCase();

      if ((event.ctrlKey || event.metaKey) && charCode === 'v' && lasso.clipboard) {
        const { x, y } = {
          x: stageRef.current.width() * 100 / zoom / 2,
          y: stageRef.current.height() * 100 / zoom / 2,
        };
        const layerId = project.lastLayerId + 1;
        const imageDimensions = await getImageDimensions(lasso.clipboard);
        dispatch(addLayer({
          id: layerId,
          type: 'image',
          content: lasso.clipboard,
          name: prepareLayerName(lasso.name, true),
          left: x,
          top: y,
          visible: true,
          width: imageDimensions.w,
          height: imageDimensions.h,
        }));
        dispatch(emptyLasso());
        dispatch(setTool('select'));
        dispatch(setSelectedLayer({ id: layerId })); 
      }
    };

    if(tool === 'lasso')
      window.addEventListener('keydown', handleKeyDown);
    else
      window.removeEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [tool, lasso.clipboard]);

  useEffect(() => {
    setCanvasHeight(project.height);
  }, [project.height, zoom]);

  useEffect(() => {
    setCanvasWidth(project.width);
  }, [project.width, zoom]);

  useEffect(() => {
    if(!mergeDownLayer && mergeLayers?.length) return setMergeLayers();
    if(!mergeDownLayer || mergeLayers?.length) return;
    const layerIndex = project.content.findIndex(l => l.id === mergeDownLayer);
    const firstLayer = project.content[layerIndex];
    const secondLayer = project.content[layerIndex - 1];
    if (firstLayer && secondLayer) setMergeLayers([{ ...secondLayer }, { ...firstLayer }]);
  }, [mergeDownLayer]);

  const handleResize = (event, { size }) => {
    if (panel !== 'canvas') return;
    const width = Math.round(size.width * 100 / zoom);
    const height = Math.round(size.height * 100 / zoom);
    setCanvasWidth(width);
    setCanvasHeight(height);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      dispatch(updateProjectDimensions({
        width,
        height,
      })); 
    }, 500);
  };

  return (
    <>
      <ResizableBox
        width={canvasWidth * zoom / 100}
        height={canvasHeight * zoom / 100}
        onResize={handleResize}
        resizeHandles={panel !== 'canvas' ? [] : ['sw', 'se', 'nw', 'ne', 'w', 'e', 'n', 's']}
        className={panel !== 'canvas' ? 'noBorder' : ''}
        lockAspectRatio={project.size.id !== 1}
      >
        <div
          onDrop={async (e) => {
            e.preventDefault();
            stageRef.current.setPointersPositions(e);
            const { x, y } = stageRef.current.getPointerPosition();
            const imageDimensions = await getImageDimensions(draggedImage.content);
            const { width, height } =
              recalculateImageDimensions(
                imageDimensions.w,
                imageDimensions.h,
                project.width / 4,
                project.height / 4,
              );
              const left = x * (100 / zoom) - (width / 2);
              const top = y * (100 / zoom) - (height / 2);
            dispatch(addLayer({
              id: project.lastLayerId + 1,
              type: 'image',
              content: draggedImage.content,
              name: prepareLayerName(draggedImage.name),
              left,
              top,
              visible: true,
              width,
              height,
            }));
          }}
          onDragOver={(e) => e.preventDefault()}
          onClick={async (e) => {
            e.preventDefault();
            if (panel === 'text' && selectedText === null && selectedLayer.id === null) {
              stageRef.current.setPointersPositions(e);
              const { x, y } = stageRef.current.getPointerPosition();

              const textLayer = {
                id: project.lastLayerId + 1,
                type: 'text',
                ...text,
                content: 'Sample Text',
                name: 'Sample Text',
                left: Math.round(x * 100 / zoom),
                top: Math.round(y * 100 / zoom),
                visible: true,
              };
              dispatch(addLayer(textLayer));

              if(tool !== 'select') dispatch(setTool('select'));
              dispatch(setSelectedLayer({ id: textLayer.id }));
              dispatch(setSelectedText(textLayer));
            }
          }}
          style={getStyle()}
          ref={wrapRef}
        >
          <Stage
            width={canvasWidth * zoom / 100}
            height={canvasHeight * zoom / 100}
            scaleX={zoom / 100}
            scaleY={zoom / 100}
            onTouchStart={deselectAll}
            ref={stageRef}
            onMouseDown={handleMouseDown}
            onMousemove={handleMouseMove}
            onMouseup={handleMouseUp}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Layer>
              <Rect
                fill={project.background}
                width={canvasWidth}
                height={canvasHeight}
              />
            </Layer>
            { project.content.map((layer, index) => {
              if (layer.type === 'image') {
                return (
                  <Layer key={index}>
                    <CanvasImage
                      layer={layer}
                      lines={lines.filter(l => l.selectedLayerId === layer.id)}
                      removeLines={removeLinesByLayer}
                      addLine={(points) => addLine(layer.id, points)}
                      stageRef={stageRef}
                    />
                  </Layer>
                );
              } else if (layer.type === 'text') {
                return (
                  <Layer key={index}>
                    <CanvasText layer={layer} />
                  </Layer>
                );
              }
            })}
            {!!mergeLayers?.length && <CanvasGroup layers={mergeLayers} />}
          </Stage>
        </div>
      </ResizableBox>
      {tool === 'eraser' && <EraserConfigs />}
      {tool === 'eraser' && <Cursor show={showCursor} size={eraserSize * zoom / 100} />}
      {tool === 'select' && selectedLayer.id && <ImageConfigs />}
      {tool === 'background' && <RemoveBackground />}
      <EditName />
  </>
  );
};

export default Canvas;
