import React, { Fragment, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  clearFilter,
  clearPagination, fetchAssetCategories,
  fetchAssets,
  setAssetFilterCategory,
  setAssetFilterType,
  setAssetSearch,
} from "../../store/admin";
import PageHeader from "./PageHeader";
import { useDispatch, useSelector } from "react-redux";
import BaseSelect from "../Common/BaseSelect";
import styled from "styled-components";

const Label = styled.div`
  width: 40px;
  text-align: right;
`;

const AssetSection = () => {
  const { search, category, type } = useSelector(({ admin }) => admin.asset.filter);
  const categories = useSelector(({ admin }) => admin.asset.categories);
  const types = useSelector(({ admin }) => admin.asset.types);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAssets());
  }, [search, category, type]);

  useEffect(() => {
    dispatch(fetchAssetCategories());
    dispatch(clearFilter());
    dispatch(clearPagination());
  }, []);

  return (
    <Fragment>
      <PageHeader
        title="Assets"
        listPage={location.pathname === '/admin/assets'}
        onAddClicked={() => navigate('/admin/assets/new')}
        onSearch={(val) => dispatch(setAssetSearch(val))}
      >
        {location.pathname === '/admin/assets' &&
          <Fragment>
            <Label>
              Category:
            </Label>
            <BaseSelect
              width={250}
              textKey="fullName"
              value={category}
              options={categories}
              onChange={(val) => dispatch(setAssetFilterCategory(val))}
            />
            <Label>
              Type:
            </Label>
            <BaseSelect
              width={250}
              value={type}
              options={types}
              onChange={(val) => dispatch(setAssetFilterType(val))}
            />
          </Fragment>
        }
      </PageHeader>
      <Outlet />
    </Fragment>
  );
};

export default AssetSection;