import React from 'react';
import styled from 'styled-components'; 
import BaseSlider from '../Common/BaseSlider';
import { ReactComponent as TrashIcon } from '../../assets/trash.svg';
import { ReactComponent as FlipHorizontal } from '../../assets/flip-horizontal.svg';
import { ReactComponent as FlipVertical } from '../../assets/flip-vertical.svg';
import { useDispatch, useSelector } from 'react-redux';
import { deleteLayer, setFlipHorizontalRequested, setFlipVerticalRequested, setOpacity } from '../../store/builder';
import { IconButton } from '@mui/material';

const Container = styled.div`
  width: fit-content;
  background: #fff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  position: fixed;
  z-index: 99;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  bottom: 16px;
  border-radius: 10px;
  align-items: center;
`;

const SliderWrap = styled.div`
  width: 250px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-left: 1px solid #F5F5F5;
  &:first-child {
    border-left: none
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-left: 1px solid #F5F5F5;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-align: center;
  color: #7C8394;
`;

const Slider = styled(BaseSlider)`
  width: 100%
`;

const ImageConfigs = () => {
  const selectedLayer = useSelector(state => state.builder.selectedLayer);
  const dispatch = useDispatch();

  if (selectedLayer.meta?.type !== 'image') return;

  return (
    <Container>
        <SliderWrap>
          <Label>Opacity</Label>
          <Slider
            name="image"
            onChange={(value) => dispatch(setOpacity(value))}
            value={selectedLayer.meta.opacity ?? 100}
            min={0}
            max={100}
          />
          <Label>{selectedLayer.meta.opacity}</Label>
        </SliderWrap>
      <ButtonWrap>
        <IconButton size="small">
          <FlipHorizontal onClick={() => dispatch(setFlipHorizontalRequested(true))} />
        </IconButton>
        <IconButton size="small" onClick={() => dispatch(setFlipVerticalRequested(true))}>
          <FlipVertical />
        </IconButton>
        <IconButton size="small" onClick={() => dispatch(deleteLayer(selectedLayer.id))}>
          <TrashIcon />
        </IconButton>
      </ButtonWrap>
    </Container>
  );
};

export default ImageConfigs;
