import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api';

const initialState = {
  search: '',
  loading: false,
  page: 0,
  perPage: 18,
  results: [],
};

export const fetchResults = createAsyncThunk(
  'pixabay/fetchResults',
  async (arg, { getState }) => {
    const { pixabay } = getState();

    return await api.pixabay.fetch({
      search: pixabay.search,
      page: pixabay.page + 1,
      perPage: pixabay.perPage,
    }, '', !pixabay.page);
  },
);

export const pixabay = createSlice({
  name: 'pixabay',
  initialState,
  reducers: {
    setSearch(state, { payload }) {
      state.search = payload;
      state.results = [];
      state.page = 0;
    },
    setPerPage(state, { payload }) {
      state.perPage = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchResults.pending, (state) => {
      state.loading = true;
    }),
    
    builder.addCase(fetchResults.fulfilled, (state, { payload }) => {
      state.results = state.results.concat(payload);
      state.page++;
      state.loading = false;
    });
  },
});

export const {
  setSearch,
  setPerPage,
} = pixabay.actions;

export default pixabay.reducer;
