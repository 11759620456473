import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api';

const initialState = {
  history: [],
  pagination: {
    page: 1,
    perPage: 2,
    isLastPage: false,
    loading: false,
  },
  previewedItem: null,
  prompt: '',
  referenceImage: {
    confirmed: false,
    name: null,
    size: null,
    content: null,
    url: null,
  },
  referenceImageSource: 'upload',
  variations: 0,
  results: [],
  selectedImageIndex: 0,
  selectedTab: 'new',
  waitOpened: false,
};

export const fetchGeneratorHistory = createAsyncThunk(
  'generator/fetchGeneratorHistory',
  async (arg, { getState }) => {
    const { builder, imageGenerator } = getState();

    return await api.imageGenerator.fetch({
      project: builder.project.id,
      limit: imageGenerator.pagination.perPage,
    });
  },
);

export const fetchGeneratorHistoryNextPage = createAsyncThunk(
  'generator/fetchGeneratorHistoryNextPage',
  async (arg, { getState }) => {
    const { builder, imageGenerator } = getState();
    return await api.imageGenerator.fetch({
      project: builder.project.id,
      limit: imageGenerator.pagination.perPage,
      offset: imageGenerator.pagination.perPage * imageGenerator.pagination.page,
    }, '', false);
  },
);

export const generateImages = createAsyncThunk(
  'generator/generateImages',
  async (arg, { getState }) => {
    const { builder, imageGenerator } = getState();

    return await api.imageGenerator.generate(
      builder.project.id,
      imageGenerator.prompt,
      imageGenerator.variations,
      imageGenerator.referenceImage.content ? imageGenerator.referenceImage.content : imageGenerator.referenceImage.url,
    );
  },
);

export const imageGenerator = createSlice({
  name: 'imageGenerator',
  initialState,
  reducers: {
    setPrompt(state, { payload }) {
      state.prompt = payload;
    },
    setPreviewedItem(state, { payload }) {
      state.previewedItem = payload;
      state.selectedImageIndex = 0;
    },
    setReferenceImage(state, { payload }) {
      state.referenceImage.name = payload.name;
      state.referenceImage.size = payload.size;
      state.referenceImage.content = payload.content;
    },
    setReferenceImagUrl(state, { payload }) {
      state.referenceImage.url = payload;
    },
    confirmReferenceImage(state) {
      state.referenceImage.confirmed = true;
    },
    removeReferenceImage(state) {
      state.referenceImage = {
        confirmed: false,
        name: null,
        size: null,
        content: null,
        url: null,
      };
    },
    setReferenceImageSource(state, { payload }) {
      state.referenceImageSource = payload;
    },
    setSelectedImageIndex(state, { payload }) {
      state.selectedImageIndex = payload;
    },
    switchSelectedImageIndex(state, { payload }) {
      if (payload === 1) {
        if (state.previewedItem.generatedImages[state.selectedImageIndex + 1]) {
          state.selectedImageIndex = state.selectedImageIndex + 1;
        } else {
          state.selectedImageIndex = 0;
        }
      } else if (payload === -1) {
        if (state.previewedItem.generatedImages[state.selectedImageIndex - 1]) {
          state.selectedImageIndex = state.selectedImageIndex - 1;
        } else {
          state.selectedImageIndex = state.previewedItem.generatedImages.length - 1;
        }
      }
    },
    setSelectedTab(state, { payload }) {
      state.selectedTab = payload;
    },
    setVariations(state, { payload }) {
      state.variations = payload;
    },
    toggleWaitOpened(state) {
      state.waitOpened = !state.waitOpened;
    },
    clearImageGenerator(state) {
      state.selectedTab = 'new';
      state.history = [];
      state.results = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateImages.pending, (state) => {
      state.waitOpened = true;
    });

    builder.addCase(generateImages.rejected, (state) => {
      state.waitOpened = false;
    });

    builder.addCase(generateImages.fulfilled, (state, { payload }) => {
      state.previewedItem = payload;
      state.selectedImageIndex = 0;
      state.selectedTab = 'history';
      state.waitOpened = false;
    });

    builder.addCase(fetchGeneratorHistory.fulfilled, (state, { payload }) => {
      state.history = payload;
      state.pagination.page = 1;
      state.pagination.isLastPage = false;
    });

    builder.addCase(fetchGeneratorHistoryNextPage.pending, (state) => {
      state.pagination.loading = true;
    });

    builder.addCase(fetchGeneratorHistoryNextPage.fulfilled, (state, { payload }) => {
      state.history = [...state.history, ...payload];
      state.pagination.page += 1;
      if (payload.length < state.pagination.perPage) state.pagination.isLastPage = true;
      state.pagination.loading = false;
    });

  },
});

export const {
  confirmReferenceImage,
  removeReferenceImage,
  setPreviewedItem,
  setPrompt,
  setReferenceImage,
  setReferenceImageSource,
  setReferenceImagUrl,
  setSelectedImageIndex,
  setSelectedTab,
  setVariations,
  switchSelectedImageIndex,
  toggleWaitOpened,
  clearImageGenerator,
} = imageGenerator.actions;

export default imageGenerator.reducer;
