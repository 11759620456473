import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api';

const initialState = {
  asset: {
    page: 1,
    filter: {
      search: '',
      category: null,
      type: null,
    },
    assets: [],
    categories: [],
    types: [
      {
        id: 1,
        name: '2D',
      },
      {
        id: 2,
        name: '3D',
      },
    ],
    edited: {
      id: null,
      name: '',
      content: null,
      category: null,
      type: null,
    },
  },
  template: {
    page: 1,
    filter: {
      search: '',
      category: null,
      size: null,
    },
    templates: [],
    categories: [],
    sizes: [],
    edited: {
      id: null,
      name: '',
      preview: null,
      category: null,
      size: null,
    },
  },
  user: {
    page: 1,
    search: '',
    users: [],
    edited: {
      id: null,
      name: '',
      email: '',
      lastLoggedInAt: '',
      avatar: null,
      plan: '',
      subscriptionEnd: '',
      notes: '',
      installedVersion: null,
      deletedProjects: [],
    },
  },
};

export const fetchUsers = createAsyncThunk(
  'admin/fetchUsers',
  async (arg, { getState }) => {
    const { admin } = getState();

    return api.admin.user.fetch({
      search: admin.user.search,
      page: admin.user.page,
    });
  },
);

export const fetchAssets = createAsyncThunk(
  'admin/fetchAssets',
  async (arg, { getState }) => {
    const { admin } = getState();

    return api.asset.fetch({
      page: admin.asset.page,
      ...admin.asset.filter,
    });
  },
);

export const fetchAssetCategories = createAsyncThunk(
  'admin/fetchAssetCategories',
  async () => {
    return api.assetCategory.fetch({
      plain: 1,
    });
  },
);

export const fetchAsset = createAsyncThunk(
  'admin/fetchAsset',
  async (arg) => {
    return await api.admin.asset.get(arg);
  },
);

export const updateAsset = createAsyncThunk(
  'admin/updateAsset',
  async (arg, { getState }) => {
    const { admin } = getState();

    if (admin.asset.edited.id === null) {
      return api.admin.asset.post(
        admin.asset.edited,
      );
    } else {
      return api.admin.asset.patch(
        admin.asset.edited.id,
        admin.asset.edited,
      );
    }
  },
);

export const fetchTemplates = createAsyncThunk(
  'admin/fetchTemplates',
  async (arg, { getState }) => {
    const { admin } = getState();

    return api.admin.template.fetch({
      page: admin.template.page,
      ...admin.template.filter,
    });
  },
);

export const fetchTemplate = createAsyncThunk(
  'admin/fetchTemplate',
  async (arg) => {
    return await api.admin.template.get(arg);
  },
);

export const fetchTemplateCategories = createAsyncThunk(
  'admin/fetchTemplateCategories',
  async () => {
    return api.templateCategory.fetch();
  },
);

export const fetchTemplateSizes = createAsyncThunk(
  'admin/fetchTemplateSizes',
  async () => {
    return api.projectSize.fetch();
  },
);

export const updateTemplate = createAsyncThunk(
  'admin/updateTemplate',
  async (arg, { getState }) => {
    const { admin } = getState();

    return api.admin.template.patch(
      admin.template.edited.id,
      admin.template.edited,
    );
  },
);

export const fetchUser = createAsyncThunk(
  'admin/fetchUser',
  async (arg) => {
    return await api.admin.user.get(arg);
  },
);

export const fetchDeletedProjects = createAsyncThunk(
  'admin/fetchDeletedProjects',
  async ({ id }) => {
    return await api.admin.project.fetch({
      user: id,
    });
  },
);

export const updateUser = createAsyncThunk(
  'admin/updateUser',
  async ({ id, ...rest }) => {
    return api.admin.user.patch(id, rest);
  },
);

export const updateProject = createAsyncThunk(
  'admin/updateProject',
  async ({ id, ...rest }) => {
    return api.admin.project.patch(id, rest);
  },
);

export const deleteProject = createAsyncThunk(
  'admin/deleteProject',
  async (id) => {
    return api.admin.project.delete(id);
  },
);

export const admin = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    clearFilter(state) {
      state.asset.filter = {
        search: '',
        category: null,
        type: null,
      };
    },
    clearPagination(state) {
      state.asset.page = 1;
      state.user.page = 1;
    },
    setEditedAssetName(state, { payload }) {
      state.asset.edited.name = payload;
    },
    setEditedAssetCategory(state, { payload }) {
      state.asset.edited.category = payload;
    },
    setEditedAssetType(state, { payload }) {
      state.asset.edited.type = payload;
    },
    setEditedAssetContent(state, { payload }) {
      state.asset.edited.content = payload;
    },
    clearEditedAsset(state) {
      state.asset.edited = {
        id: null,
        name: '',
        content: null,
        category: null,
        type: null,
      };
    },
    setUserSearch(state, { payload }) {
      state.user.search = payload;
      state.user.page = 1;
    },
    decreaseAssetPage(state) {
      if (state.asset.page > 1) {
        state.asset.page--;
      }
    },
    increaseAssetPage(state) {
      state.asset.page++;
    },
    setAssetSearch(state, { payload }) {
      state.asset.filter.search = payload;
      state.asset.page = 1;
    },
    setAssetFilterCategory(state, { payload }) {
      state.asset.filter.category = payload;
      state.asset.page = 1;
    },
    setAssetFilterType(state, { payload }) {
      state.asset.filter.type = payload;
      state.asset.page = 1;
    },
    decreaseTemplatePage(state) {
      if (state.template.page > 1) {
        state.template.page--;
      }
    },
    increaseTemplatePage(state) {
      state.template.page++;
    },
    setTemplateSearch(state, { payload }) {
      state.template.filter.search = payload;
      state.template.page = 1;
    },
    setTemplateFilterCategory(state, { payload }) {
      state.template.filter.category = payload;
      state.template.page = 1;
    },
    setTemplateFilterSize(state, { payload }) {
      state.template.filter.size = payload;
      state.template.page = 1;
    },
    setEditedTemplateName(state, { payload }) {
      state.template.edited.name = payload;
    },
    setEditedTemplateCategory(state, { payload }) {
      state.template.edited.category = payload;
    },
    setEditedTemplateSize(state, { payload }) {
      state.template.edited.size = payload;
    },
    clearEditedTemplate(state) {
      state.template.edited = {
        id: null,
        name: '',
        preview: null,
        category: null,
        size: null,
      };
    },
    decreaseUserPage(state) {
      if (state.user.page > 1) {
        state.user.page--;
      }
    },
    increaseUserPage(state) {
      state.user.page++;
    },
    clearEditedUser(state) {
      state.user.edited = {
        id: null,
        name: '',
        email: '',
        lastLoggedInAt: '',
        avatar: null,
        plan: '',
        subscriptionEnd: '',
        notes: '',
        installedVersion: null,
      };
    },
    setEditedUserName(state, { payload }) {
      state.user.edited.name = payload;
    },
    setEditedUserPlan(state, { payload }) {
      state.user.edited.plan = payload;
    },
    setEditedUserEmail(state, { payload }) {
      state.user.edited.email = payload;
    },
    setEditedUserNotes(state, { payload }) {
      state.user.edited.notes = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAssets.fulfilled, (state, { payload }) => {
      if (payload.length === 0 && state.asset.page > 1) {
        state.asset.page--;
        return;
      }

      state.asset.assets = payload;
    });

    builder.addCase(fetchAssetCategories.fulfilled, (state, { payload }) => {
      state.asset.categories = payload;
    });

    builder.addCase(fetchAsset.fulfilled, (state, { payload }) => {
      state.asset.edited.id = payload.id;
      state.asset.edited.name = payload.name;
      state.asset.edited.content = payload.content;
      state.asset.edited.category = payload.category.id;
      state.asset.edited.type = payload.type.id;
    });

    builder.addCase(updateAsset.fulfilled, (state) => {
      state.asset.edited = {
        id: null,
        name: null,
        content: null,
        category: null,
        type: null,
      };
    });

    builder.addCase(fetchTemplates.fulfilled, (state, { payload }) => {
      if (payload.length === 0 && state.template.page > 1) {
        state.template.page--;
        return;
      }

      state.template.templates = payload;
    });

    builder.addCase(fetchTemplate.fulfilled, (state, { payload }) => {
      state.template.edited.id = payload.id;
      state.template.edited.name = payload.name;
      state.template.edited.preview = payload.preview;
      state.template.edited.category = payload.category.id;
      state.template.edited.size = payload.size.id;
    });

    builder.addCase(updateTemplate.fulfilled, (state) => {
      state.asset.edited = {
        id: null,
        name: null,
        preview: null,
        category: null,
        size: null,
      };
    });

    builder.addCase(fetchTemplateCategories.fulfilled, (state, { payload }) => {
      state.template.categories = payload;
    });

    builder.addCase(fetchTemplateSizes.fulfilled, (state, { payload }) => {
      state.template.sizes = payload;
    });

    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      state.user.users = payload;
    });

    builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
      const {
        id,
        name,
        email,
        lastLoggedInAt,
        avatar,
        plan,
        subscriptionEnd,
        notes,
        installedVersion,
      } = payload;

      state.user.edited.id = id;
      state.user.edited.name = name;
      state.user.edited.email = email;
      state.user.edited.lastLoggedInAt = lastLoggedInAt;
      state.user.edited.avatar = avatar;
      state.user.edited.plan = plan;
      state.user.edited.subscriptionEnd = subscriptionEnd;
      state.user.edited.notes = notes;
      state.user.edited.installedVersion = installedVersion;
    });

    builder.addCase(fetchDeletedProjects.fulfilled, (state, { payload }) => {
      state.user.edited.deletedProjects = payload;
    });

    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      const users = state.user.users;

      for (let i = 0; i < users.length; i++) {
        if (users[i].id === payload.id) {
          state.user.users[i] = payload;
          break;
        }
      }
    });
  },
});

export const {
  clearFilter,
  clearPagination,
  clearEditedAsset,
  setEditedAssetName,
  setEditedAssetCategory,
  setEditedAssetType,
  setEditedAssetContent,
  decreaseAssetPage,
  increaseAssetPage,
  setAssetSearch,
  setAssetFilterCategory,
  setAssetFilterType,
  decreaseTemplatePage,
  increaseTemplatePage,
  setTemplateSearch,
  setTemplateFilterCategory,
  setTemplateFilterSize,
  clearEditedTemplate,
  setEditedTemplateCategory,
  setEditedTemplateName,
  setEditedTemplateSize,
  setUserSearch,
  increaseUserPage,
  decreaseUserPage,
  clearEditedUser,
  setEditedUserName,
  setEditedUserPlan,
  setEditedUserEmail,
  setEditedUserNotes,
} = admin.actions;

export default admin.reducer;