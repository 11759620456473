import React from 'react';
import styled from 'styled-components';
import BaseSwitch from '../Common/BaseSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { setEditLayerId, setFontFamilyPreview, toggleGenerateTextOpened } from '../../store/builder';
import TextStylePanelStroke from './TextStylePanelStroke';
import TextStylePanelShadow from './TextStylePanelShadow';
import TextStylePanelFont from './TextStylePanelFont';
import { setFontFamily } from '../../store/builder';
import TextStylePanelGradient from './TextStylePanelGradient';
import { listFonts } from '../../assets/fonts';
import BaseAutocomplete from '../Common/BaseAutocomplete';
import { clearTextGeneratorState } from "../../store/textGenerator";

const Container = styled.div`
  width: 280px;
  height: calc(100vh - 90px);
`;

const Section = styled.div`
  width: 280px;
  border-bottom: 1px solid #DCDDE1;
  padding: 19px 16px;
  &:last-child {
    border-bottom-width: 0;
  }
`;

const Element = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #3C4049;
`;

let timeout;

const TextStylePanel = () => {
  const text = useSelector(state => state.builder.text);
  const editLayerId = useSelector(({ builder }) => builder.editLayerId);
  const plan = useSelector(state => state.user.plan);
  const generateTextOpened = useSelector(({ builder }) => builder.generateTextOpened);
  const dispatch = useDispatch();

  const handleFontHover = (e) => {
    const value = e?.target?.firstChild.nodeValue;
    if (!value) return;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      dispatch(setFontFamilyPreview(value));
    }, 300);
  };

  const handleClick = () => {
    if (!text.id || editLayerId === text.id) return;
    dispatch(setEditLayerId(text.id));
  };

  const handleClose = () => {
    if (!editLayerId) return;
    dispatch(setFontFamilyPreview(null));
    dispatch(setEditLayerId(false));
  };

  const toggleTextGenerator = () => {
    dispatch(toggleGenerateTextOpened());
    dispatch(clearTextGeneratorState());
  };

  return (
    <Container>
      <Section>
        <Element>
          <Label>Font</Label>
          <BaseAutocomplete
            options={listFonts(plan === 'Business')}
            value={text.font.family}
            width={248}
            onChange={(value) => dispatch(setFontFamily(value))}
            onHover={handleFontHover}
            onClose={handleClose}
            onOpen={handleClick}
            disableClearable
          />
        </Element>
      </Section>
      <Section>
        <TextStylePanelFont />
      </Section>
      <Section>
        <TextStylePanelGradient/>
      </Section>
      <Section>
        <TextStylePanelStroke />
      </Section>
      <Section>
        <TextStylePanelShadow />
      </Section>
      <Section>
        <BaseSwitch
          label="Generate text"
          checked={generateTextOpened}
          toggle={toggleTextGenerator}
        />
      </Section>
    </Container>
  );
};

export default TextStylePanel;
