import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api';

export const resetPassword = createSlice({
  name: 'resetPassword',
  initialState: {
    name: null,
    code: null,
    step: 'email',
    emailError: null,
    codeError: null,
    passwordError: null,
  },
  reducers: {
    setEmail(state, { payload }) {
      state.email = payload;
    },
    setEmailError(state, { payload }) {
      state.emailError = payload;
    },
    setCode(state, { payload }) {
      state.code = payload;
    },
    setCodeError(state, { payload }) {
      state.codeError = payload;
    },
    setPasswordError(state, { payload }) {
      state.passwordError = payload;
    },
    setStep(state, { payload }) {
      state.step = payload;
    },
    resetState(state) {
      state.name = null;
      state.code = null;
      state.step = 'email';
      state.emailError = null;
      state.codeError = null;
      state.passwordError = null;
    },
  },
});

export const forgetPassword = ({ email }) => async (dispatch) => {
  dispatch(setEmailError(null));
  const data = await api.password.fetch({
    email,
  }, 'forget');

  if (!data)
    return dispatch(setEmailError('Incorrect email'));

  dispatch(setEmail(email));
  dispatch(setStep('code'));
};

export const validateCode = createAsyncThunk(
  'resetPassword/validateCode',
  async (code, { dispatch, getState }) => {
  dispatch(setCodeError(null));
  const { resetPassword: { email } } = getState();
  
  const data = await api.password.fetch({
    email,
    code,
  }, 'code');

  if (!data)
    return dispatch(setCodeError('Incorrect code'));

  dispatch(setCode(code));
  dispatch(setStep('password'));
});

export const updatePasword = createAsyncThunk(
  'resetPassword/updatePasword',
  async (password, { dispatch, getState }) => {
  dispatch(setPasswordError(null));
  const { resetPassword: { email, code } } = getState();
  
  const data = await api.password.patch(null, {
    email,
    code,
    password,
  });

  if (!data)
    return dispatch(setPasswordError('Incorrect code'));

  dispatch(setEmail(null));
  dispatch(setCode(null));
  dispatch(setStep('success'));
});

export const { 
  setEmailError, 
  setEmail,
  setStep,
  setCode,
  setCodeError,
  setPasswordError,
  resetState,
} = resetPassword.actions;

export default resetPassword.reducer;
