import { Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import BaseButton, { WhiteButton } from './BaseButton';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as Logo } from "../../assets/logo-square.svg";

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    width: 320px;
    text-align: center;
    padding-bottom: 10px;
    .MuiDialogActions-root {
      justify-content: center;
    }
  }
`;

const ContentContainer = styled.div`
  padding: 15px 25px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: #1A1C23;
  margin-bottom: 25px;
`;

const Description = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  color: #3C4049;
`;

const UpdateAvailableModal = ({ update }) => {
  return ( 
  <StyledDialog
    open
    onClose={() => {}}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogContent>
      <ContentContainer>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <Title>
          There is a new version of Articus available.
        </Title>
        <Description>
          Would you like to install now or the next time you open the app?
        </Description>
      </ContentContainer>
    </DialogContent>
    <DialogActions>
      <WhiteButton
        width={125}
        rightIcon={false}
        onClick={() => update(false)}
      >
        Install Later
      </WhiteButton>
      <BaseButton
        width={125}
        onClick={() => update(true)}
      >
        Install Now
      </BaseButton>
    </DialogActions>
  </StyledDialog>
  );
};

UpdateAvailableModal.propTypes = {
  update: PropTypes.func,
};

export default UpdateAvailableModal;
