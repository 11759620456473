import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as MinusIcon } from '../../assets/toolbar/zoom-minus.svg';
import { ReactComponent as PlusIcon } from '../../assets/toolbar/zoom-plus.svg';
import { HoverButton } from '../Common/BaseButton';
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment, setZoom } from '../../store/builder';
import BaseTextBox from '../Common/BaseTextBox';

const Text = styled.div``;

const StyledTextBox = styled(BaseTextBox)`
  input {
    padding: 2px 2px 2px 4px!important;
  }
`;

const StyledToolbarZoom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 136px;
  border-left: 1px solid #DCDDE1;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  padding: 0 4px;
  
  ${ HoverButton } svg {
    width: 18px;
    height: 18px;
  }
`;

const ToolbarZoom = () => {
  const zoom = useSelector(state => state.builder.zoom);
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(zoom);
  
  const handleClick = (e) => {
    // double click
    if(e.detail === 2) {
      setEdit(true);
    }
  };

  const handleUpdate = () => {
    const zoomToSet = Math.min(Math.max(1, +value), 800);
    dispatch(setZoom(zoomToSet));
    setEdit(false);
  };

  useEffect(() => {
    if (value !== zoom)
      setValue(zoom);
  }, [zoom]);

  return (
    <StyledToolbarZoom>
      <HoverButton
        onClick={() => dispatch(decrement())}
        width={40}
        height={40}
      >
        <MinusIcon/>
      </HoverButton>
      {edit ?
        <StyledTextBox 
          value={value} 
          type="number"
          onChange={(v) => setValue(v)}
          width={45}
          height={20} 
          bottom={0} 
          onEnterClick={handleUpdate}
          onBlur={handleUpdate}
          autoFocus
        /> : 
        <Text onClick={handleClick}>
          {zoom}%
        </Text>
      }
      <HoverButton
        onClick={() => dispatch(increment())}
        width={40}
        height={40}
      >
        <PlusIcon/>
      </HoverButton>
    </StyledToolbarZoom>
  );
};

export default ToolbarZoom;
