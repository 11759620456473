import React, { Fragment, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../store/user';
import Header from './Header/Header';

const LoggedInLayout = () => {
  const token = useSelector(state => state.user.token);
  const id = useSelector(state => state.user.id);
  const dispatch = useDispatch();

  useEffect(() => {
    if(token && !id) {
      dispatch(fetchUser());
    }
  }, [token, id]);

  if (!token && !id) 
    return <Navigate to={'/login'} replace />;

  if(!id)
    return<></>;

  return (
    <Fragment>
      <Header />
      <Outlet />
    </Fragment>
  );
};

export default LoggedInLayout;
