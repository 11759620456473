import { CircularProgress } from '@mui/material';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

const LoaderWrap = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
  justify-content: center;
  color: #77b36b;
`;

const Loader = forwardRef(function Loader(props, ref) {
  return (
    <LoaderWrap>
      <CircularProgress ref={ref} color='inherit' />
    </LoaderWrap>
  );
});

export default Loader;
