import React, { useRef } from 'react';
import { Html } from 'react-konva-utils';
import PropTypes from 'prop-types';
import BaseMenu from '../Common/BaseMenu';

const ContextMenu = ({ open, position, items, onClose }) => {
    const anchorRef = useRef();

    return (
        <Html 
          groupProps={{ x: position.x, y: position.y }}
        >
          <span ref={anchorRef}></span>
          {open && 
            <BaseMenu
              items={items}
              open={true}
              anchorEl={anchorRef.current}
              onClose={onClose}
            />
          }
        </Html>
    );
};

ContextMenu.propTypes = {
    open: PropTypes.bool,
    position: PropTypes.object,
    items: PropTypes.array,
    onClose: PropTypes.func,
};

export default ContextMenu;