import { Dialog, DialogContent } from '@mui/material';
import React, { useRef } from 'react';
import styled from 'styled-components';
import BaseTextBox from '../../components/Common/BaseTextBox';
import BaseButton, { CancelButton } from '../../components/Common/BaseButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword, setChangePasswordModal } from '../../store/user';
 
const EditPasswordDialog = styled(Dialog)`
  .MuiPaper-root {
  }
`;

const FormError = styled.p`
  color: #f37171;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 0;
`;

const Content = styled(DialogContent)`
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
`;

const validationSchema = yup.object({
    oldPassword: yup.string().required('Required field'),
    newPassword: yup.string().required('Required field'),
    confirmPassword: 
      yup.string()
      .required('Required field')
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

const EditPassword = () => {
  const open = useSelector(({ user }) => user.changePasswordModal);
  const error = useSelector(({ user }) => user.changePasswordError);
  const dispatch = useDispatch();
  const newPassRef = useRef();
  const confirmPassRef = useRef();
  const btnRef = useRef();
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: values => {
      dispatch(changePassword(values));
      formik.handleReset();
    },
    validationSchema,
  });
  const handleClose = () => {
    formik.handleReset();
    dispatch(setChangePasswordModal(false));
  };
  return ( 
      <EditPasswordDialog
        open={open}
        onClose={handleClose}
      >
          <Content>
            <h2>Change password</h2>
            {error?.length &&
              <FormError>{error}</FormError>
            }
            <p>{formik.touched.oldPassword}</p>
            <BaseTextBox 
              width={300} 
              label="Old password"
              type="password" 
              name="oldPassword"
              value={formik.values.oldPassword}
              errorText={formik.touched.oldPassword && formik.errors.oldPassword}
              onChangeFormik={formik.handleChange}
              onFormikBlur={formik.handleBlur}
              onEnterClick={() => newPassRef.current.focus()}
              onTabClick={() => newPassRef.current.focus()}
            />
            <BaseTextBox 
              width={300} 
              label="New password"
              type="password"
              name="newPassword"
              value={formik.values.newPassword}
              errorText={formik.touched.newPassword && formik.errors.newPassword}
              onChangeFormik={formik.handleChange}
              onFormikBlur={formik.handleBlur}
              ref={newPassRef}
              onEnterClick={() => confirmPassRef.current.focus()}
              onTabClick={() => confirmPassRef.current.focus()}
              />
            <BaseTextBox 
              width={300} 
              label="Confirm new password"
              type="password" 
              name="confirmPassword"
              value={formik.values.confirmPassword}
              errorText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              onChangeFormik={formik.handleChange}
              onFormikBlur={formik.handleBlur}
              ref={confirmPassRef}
              onEnterClick={formik.submitForm}
              onTabClick={() => btnRef.current.focus()}
            />
            <ButtonWrap>
              <CancelButton ref={btnRef} onClick={handleClose}>
                Cancel
              </CancelButton>
              <BaseButton disabled={!formik.isValid} onClick={formik.submitForm}>
                Update password
              </BaseButton>
            </ButtonWrap>
          </Content>
      </EditPasswordDialog>
  );
};

export default EditPassword;