import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import BaseTextBox from '../Common/BaseTextBox';
import { CancelButton, SimpleButton } from '../Common/BaseButton';
import { useDispatch, useSelector } from 'react-redux';
import {  confirmReferenceImage, removeReferenceImage, setReferenceImagUrl } from '../../store/imageGenerator';
import PropTypes from 'prop-types';

const Container = styled.div`
  padding: 16px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-top: 1px solid #DCDDE1;
`;

const ImageImporterUrl = ({ handleClose }) => {
  const referenceImage = useSelector(state => state.imageGenerator.referenceImage);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  const cancel = () => {
    dispatch(removeReferenceImage());
    handleClose();
  };

  const confirm = async () => {
    const { size } = await (await fetch(referenceImage.url)).blob();
    if (size > 2900000)
      return setError('Failed to upload your file. Maximum upload file size: 2.9MB.');

    dispatch(confirmReferenceImage());
    handleClose();
  };

  const handleChange = async (url) => {
    if (error) setError(null);
    dispatch(setReferenceImagUrl(url));
  };

  return (
    <Fragment>
      <Container>
        <BaseTextBox
          value={referenceImage.url}
          width={428}
          placeholder="http://www.domain.com/image.png"
          onChange={handleChange}
          error={error}
        />
      </Container>
      <Actions>
        <CancelButton
          width={206}
          onClick={cancel}
        />
        <SimpleButton
          width={206}
          disabled={referenceImage.url === null || !!error}
          onClick={confirm}
        >
          Import
        </SimpleButton>
      </Actions>
    </Fragment>
  );
};

ImageImporterUrl.propTypes = {
  handleClose: PropTypes.func,
};

export default ImageImporterUrl;
