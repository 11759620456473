import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setDraggedImage } from '../../store/builder';

const Image = styled.img`
  width: 114px;
  height: 114px;
  border-radius: 4px;
  cursor: pointer;
 
  &:hover {
    background-color: linear-gradient(360deg, rgba(0, 0, 0, 0.5) 12.22%, rgba(0, 0, 0, 0) 100%);
  }
`;

const GeneratorHistoryBoxImage = ({ image, prompt, ...rest }) => {
  const dispatch = useDispatch();

  return (
    <Image
      draggable
      src={image}
      alt="..."
      {...rest}
      onDragStart={() => {
        dispatch(setDraggedImage({ content: image, name: prompt }));
      }}
    />
  );
};

GeneratorHistoryBoxImage.propTypes = {
  image: PropTypes.string,
  prompt: PropTypes.string,
};

export default GeneratorHistoryBoxImage;
