import React, { useRef } from 'react';
import BaseTextBox from '../Common/BaseTextBox';
import { SimpleButton } from '../Common/BaseButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { validateCode } from '../../store/resetPassword';
import FormError from '../Common/FormError';
    
const validationSchema = yup.object({
  code: yup.string().required('Required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(6, 'Must be at least 7 digits'),
});

const CodeForm = () => {
  const error = useSelector(({ resetPassword }) => resetPassword.codeError);
    const dispatch = useDispatch();
    const codeRef = useRef();
    const submitRef = useRef();
    const formik = useFormik({
      initialValues: {
        code: '',
      },
      onSubmit: values => {
        dispatch(validateCode(values.code));
        formik.handleReset();
      },
      validationSchema,
    });
    return (
      <>
        <h2>Enter the code that you have received.</h2>
        {error?.length &&
            <FormError text={error}/>
        }
        <BaseTextBox
          ref={codeRef}
          autoFocus
          label="Code"
          placeholder="Enter code"
          background="transparent"
          inputBackground="#fff"
          name="code"
          value={formik.values.code}
          errorText={formik.touched.code && formik.errors.code}
          onChangeFormik={formik.handleChange}
          onFormikBlur={formik.handleBlur}
          onEnterClick={formik.submitForm}
          onTabClick={() => {
              if(formik.isValid && formik.dirty) 
                submitRef.current.focus();
              else {
                codeRef.current.blur();
                codeRef.current.focus();
              }
          }}
        />
        <SimpleButton 
          ref={submitRef}
          width={400} 
          disabled={formik.touched.code && !formik.isValid} 
          onClick={formik.submitForm}
          onTabClick={() => codeRef.current.focus()}
        >
            Confirm code
        </SimpleButton>
      </>
    );
};

export default CodeForm;