import React, { useState } from 'react';
import styled from 'styled-components';
import BaseTextBox from '../Common/BaseTextBox';
import BaseButton from '../Common/BaseButton';
import { useDispatch, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { fetchResults, setSearch } from '../../store/pixabay';
import Loader from '../Common/Loader';
import { CancelButton } from '../Common/BaseButton';
import { SimpleButton } from '../Common/BaseButton';
import PropTypes from 'prop-types';
import { confirmReferenceImage, removeReferenceImage, setReferenceImage } from '../../store/imageGenerator';

const StyledImageImporterPixabay = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-bottom: 92px;
`;

const Search = styled.div`
  display: flex;
  gap: 18px;
  justify-content: center;
`;

const Results = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  margin-top: 8px;
`;

const Image = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 6px;
  cursor: pointer;

  &.selected {
    outline: 2px solid #88CC7A;
  }
  
  &:hover {
    opacity: 0.8;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-top: 1px solid #DCDDE1;
  position: absolute;
  bottom: 0;
  background: #fff;
  width: 100%;
`;

const ImageImporterPixabay = ({ handleClose }) => {
  const pixabay = useSelector(state => state.pixabay);
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(null);

  const cancel = () => {
    dispatch(removeReferenceImage());
    handleClose();
  };

  const confirm = () => {
    if (selectedIndex === null) return;
    
    const base64content = pixabay.results[selectedIndex];
    const size = (base64content.split(';base64,')[1].length * 3/4);
    const content = 'data:' + 'image/' + base64content.split('data:')[1];
    const image = {
      name: pixabay.search,
      size: Math.floor(size / 1024) + 'kb',
      content,
    };

    dispatch(setReferenceImage(image));
    dispatch(confirmReferenceImage(image));
    handleClose();
  };

  const { ref } = useInView({
    onChange: (inView) => {
      if (inView && !pixabay.loading) {
        dispatch(fetchResults());
      }
    },
  });

  return (
    <>
      <StyledImageImporterPixabay>
      <Search>
        <BaseTextBox
          value={pixabay.search}
          placeholder="Search images"
          width={185}
          onChange={(value) => dispatch(setSearch(value))}
          onEnterClick={() => dispatch(fetchResults())}
        />
        <BaseButton
          width={60}
          onClick={() => dispatch(fetchResults())}
        >
          Search
        </BaseButton>
      </Search>
        
      <Results>
          {
            pixabay.results.map((item, index) => (
              <Image
              key={index}
                src={item}
                alt='...'
                onClick={() => setSelectedIndex(index)}
                className={index === selectedIndex ? 'selected' : ''}
              />
            ))
          }
        </Results>
        {
          pixabay.page > 0 &&
          <Loader ref={ref} />
        }
      </StyledImageImporterPixabay>
        <Actions>
          <CancelButton
            width={206}
            onClick={cancel}
          />
          <SimpleButton
            width={206}
            disabled={selectedIndex === null}
            onClick={confirm}
          >
            Import
          </SimpleButton>
        </Actions>
      </>
  );
};

ImageImporterPixabay.propTypes = {
  handleClose: PropTypes.func,
};

export default ImageImporterPixabay;
