import React from 'react';
import styled from 'styled-components';
import BaseButton from './BaseButton';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background: #F5F5F5;
  border-radius: 4px;
`;

const FileInput = styled(BaseButton)`
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #3C4049;
  
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    cursor: pointer;
  }
`;

const Note = styled.p`
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 140%;
  text-align: center;
  color: #6C7080;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f2dbdb;
  color: #f37171;
  padding: 10px;
  border-radius: 10px;
  margin-top: 8px;
  p {
    font-size: 0.875rem;
    line-height: 1.125re;m;
    margin: 0;
    text-align: center;
  }
`;

const BaseUploader = ({
  id,
  width = 248,
  height = 100,
  showNote = true,
  upload,
  error,
}) => {
  return (
    <Container
      width={width}
      height={height}
    >
      <FileInput>
        <label htmlFor={id}>
          Browse image
        </label>
        <input
          type="file"
          id={id}
          hidden
          onChange={upload}
          accept="image/png, image/gif, image/jpeg"
        />
      </FileInput>
      {
        error &&
          <ErrorContainer>
            <p>
              <b>{error.title}</b>
            </p>
            <p>
              {error.description}
            </p>
          </ErrorContainer>
      }
      {
        showNote && <Note>
          Upload a PNG, GIF, or JPG
        </Note>
      }
    </Container>
  );
};

BaseUploader.propTypes = {
  id: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  showNote: PropTypes.bool,
  upload: PropTypes.func,
  error: PropTypes.object,
};

export default BaseUploader;
