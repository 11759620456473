import React, { useEffect } from 'react';
import BaseList from "./BaseList";
import { useDispatch, useSelector } from "react-redux";
import {
  decreaseTemplatePage,
  fetchTemplates,
  increaseTemplatePage,
} from "../../store/admin";
import { ReactComponent as PencilIcon } from "../../assets/pencil.svg";
import { useNavigate } from "react-router-dom";

const TemplateList = () => {
  const { page, templates } = useSelector(( { admin }) => admin.template);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchTemplates());
  }, []);

  const goToEdit = ({ id }) => {
    navigate('/admin/templates/' + id);
  };

  const previous = () => {
    dispatch(decreaseTemplatePage());
    dispatch(fetchTemplates());
  };

  const next = () => {
    dispatch(increaseTemplatePage());
    dispatch(fetchTemplates());
  };

  const columns = [
    {
      label: '',
      key: 'preview',
      isImage: true,
      action: goToEdit,
    },
    {
      label: 'Name',
      key: 'name',
      isMain: true,
      action: goToEdit,
    },
    {
      label: '',
      icon: <PencilIcon />,
      action: goToEdit,
    },
  ];

  return (
    <BaseList
      columns={columns}
      data={templates}
      currentPage={page}
      previous={previous}
      next={next}
    />
  );
};

export default TemplateList;