import React, { useEffect } from 'react';
import styled from 'styled-components';
import ProjectsListHeader from './ProjectsListHeader';
import ProjectsListRow from './ProjectsListRow';
import { useDispatch, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { fetchProjects, fetchProjectsNextPage } from '../../store/project';
import Loader from '../Common/Loader';

const Container = styled.div`
  margin-bottom: 40px;

  section {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: #3C4049;
`;

function ProjectsList() {
  const projects = useSelector(state => state.project.projects);
  const pagination = useSelector(state => state.project.pagination);
  const dispatch = useDispatch();

  const { ref } = useInView({
    onChange: (inView) => {
      if (inView && !pagination.loading) {
        dispatch(fetchProjectsNextPage());
      }
    },
  });

  useEffect(() => {
    dispatch(fetchProjects());
  }, []);

  return (
    <Container>
      <Title>
        All projects
      </Title>
      <section>
        <ProjectsListHeader />
        {
          projects.map((project, index) => (
            <ProjectsListRow
              key={index}
              {... project}
            />
          ))
        }
        {!!projects?.length && !pagination.isLastPage && 
            <Loader ref={ref} />
        }

      </section>
    </Container>
  );
}

export default ProjectsList;
