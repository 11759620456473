import React from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import loader from '../../assets/loader.json';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  z-index: 9999999;
  opacity: 0.5;
`;

const LoadingBackdrop = () => {
  return (
    <Container>
      <Lottie animationData={loader} />
    </Container>
  );
};

export default LoadingBackdrop;
