import React, { useEffect } from 'react';
import styled from 'styled-components';
import BaseUploader from '../Common/BaseUploader';
import { useDispatch, useSelector } from 'react-redux';
import ImagePanelImporterImage from './ImagePanelImporterImage';
import { fetchUploadedImages, fetchUploadedImagesNextPage, uploadImage } from '../../store/imageImporter';
import Loader from '../Common/Loader';
import { useInView } from 'react-intersection-observer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  margin-top: 40px;
`;

const Images = styled.div`

`;

const Title = styled.h3`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #3C4049;
`;

const Subtitle = styled.h4`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #7C8394;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ImagePanelImporter = () => {
  const images = useSelector(state => state.imageImporter.images);
  const pagination = useSelector(state => state.imageImporter.pagination);
  const dispatch = useDispatch();

  const { ref } = useInView({
    onChange: (inView) => {
      if (inView && !pagination.loading) {
        dispatch(fetchUploadedImagesNextPage());
      }
    },
  });

  useEffect(() => {
    dispatch(fetchUploadedImages());
  }, []);

  const upload = () => {
    dispatch(
      uploadImage({
        image: document.querySelector('#image-input').files[0],
      }),
    );
  };

  return (
    <Container>
      <BaseUploader
        id="image-input"
        upload={upload}
      />
      {images.length > 0 && (
        <Images>
          <Title>Imported images</Title>
          <Subtitle>You can drag images in to canvas</Subtitle>
          <List>
            {
              images.map((image, index) => (
                <ImagePanelImporterImage
                  key={index}
                  image={image}
                />
              ))
            }
            {!!images.length && !pagination.isLastPage && 
                <Loader ref={ref} />
            }
          </List>
        </Images>
      )}
    </Container>
  );
};

export default ImagePanelImporter;
