import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { BackButton, SimpleButton } from "../Common/BaseButton";
import {
  clearEditedTemplate,
  fetchTemplate,
  fetchTemplateCategories,
  fetchTemplateSizes,
  setEditedTemplateCategory,
  setEditedTemplateName,
  setEditedTemplateSize,
  updateTemplate,
} from "../../store/admin";
import { useDispatch, useSelector } from "react-redux";
import BaseTextBox from "../Common/BaseTextBox";
import BaseSelect from "../Common/BaseSelect";

const Container = styled.div`
  margin-bottom: 40px;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

const Image = styled.img`
  height: 300px;
`;

const Field = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  justify-content: flex-end;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const TemplateForm = () => {
  const { name, preview, category, size } = useSelector(({ admin }) => admin.template.edited);
  const categories = useSelector(({ admin }) => admin.template.categories);
  const sizes = useSelector(({ admin }) => admin.template.sizes);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { templateId } = useParams();

  useEffect(() => {
    if (isNaN(templateId)) {
      dispatch(clearEditedTemplate());
    } else {
      dispatch(fetchTemplate(templateId));
    }

    dispatch(fetchTemplateCategories());
    dispatch(fetchTemplateSizes());
  }, []);

  const back = () => {
    navigate('/admin/templates');
  };

  const save = async () => {
    await dispatch(updateTemplate());
    navigate('/admin/templates');
  };

  return (
    <Container>
      <ImageContainer>
        <Image
          src={process.env.REACT_APP_API_URL + 'images/' + preview}
          alt=""
        />
      </ImageContainer>
      <div>
        <Field>
          <Label>
            Label:
          </Label>
          <BaseTextBox
            width={600}
            bottom={0}
            value={name}
            onChange={(val) => dispatch(setEditedTemplateName(val))}
          />
        </Field>
        <Field>
          <Label>
            Category:
          </Label>
          <BaseSelect
            width={600}
            value={category}
            options={categories}
            onChange={(val) => dispatch(setEditedTemplateCategory(val))}
          />
        </Field>
        <Field>
          <Label>
            Type:
          </Label>
          <BaseSelect
            width={600}
            value={size}
            textKey="label"
            options={sizes}
            onChange={(val) => dispatch(setEditedTemplateSize(val))}
          />
        </Field>
        <Actions>
          <BackButton
            onClick={back}
          />
          <SimpleButton
            onClick={save}
          >
            Save
          </SimpleButton>
        </Actions>
      </div>
    </Container>
  );
};

export default TemplateForm;