import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ProjectMenu from './ProjectMenu';
import BaseTextBox from '../Common/BaseTextBox';
import { setSelectedProject, updateProjectName } from '../../store/project';

const Image = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 4px;
  object-fit: cover;
  flex: 0 0 60px;
  cursor: pointer;
`;

const EmptyImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 4px;
  cursor: pointer;
  flex: 0 0 60px;
  background: #F5F5F5;
`;

const ProjectName = styled.div`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: #3C4049;
  flex: 0 0 667px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Cell = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #7C8394;
  flex: 0 0 120px;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 10px;
  gap: 16px;
  height: 68px;
  background: #FFFFFF;
  border-radius: 6px;
  
  &.selected {
    border: 1px solid #77B36B;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    
    > ${ProjectName} {
      color: #77B36B;
    }
  }
`;

const ProjectsListRow = ({ id, preview, name, createdAt, updatedAt, fileSize, width, height }) => {
  const [editName, setEditName] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [newName, setNewName] = useState(name);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToBuilder = async () => {
    navigate('/builder/' + id);
  };

  const handleRename = async () => {
    if(editName && newName!== name) 
      await dispatch(updateProjectName({ name: newName }));
    setEditName(false);
    dispatch(setSelectedProject(null));
    setIsSelected(false);
  };

  const openRename = () => {
    setTimeout(() => {
      setEditName(true);
      dispatch(setSelectedProject(id)); 
      setIsSelected(true);
    });
  };

  return (
    <Container className={isSelected ? 'selected' : ''}>
      { preview && (
        <Image
          onClick={goToBuilder}
          src={process.env.REACT_APP_API_URL + 'images/' + preview}
          alt={name}
        />
      )}
      { !preview && (
        <EmptyImage  onClick={goToBuilder} />
      )}
      {editName ? 
        <BaseTextBox 
          value={newName} 
          onChange={(value) => setNewName(value)}
          width={300} 
          bottom={0} 
          onEnterClick={handleRename}
          onBlur={handleRename}
          autoFocus
        /> :
        <ProjectName onClick={goToBuilder}>
          {name}
        </ProjectName>
      }
      <Cell onClick={goToBuilder}>{moment(createdAt).format('MM-DD-YYYY')}</Cell>
      <Cell onClick={goToBuilder}>{moment(updatedAt).format('MM-DD-YYYY')}</Cell>
      <Cell onClick={goToBuilder}>{fileSize} Kb</Cell>
      <ProjectMenu
        id={id}
        name={name}
        preview={preview}
        width={width}
        height={height}
        rename={openRename}
        menuOpened={setIsSelected}
      />
    </Container>
  );
};

ProjectsListRow.propTypes = {
  id: PropTypes.number,
  preview: PropTypes.string,
  name: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  fileSize: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ProjectsListRow;
