import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as DotsIcon } from '../../assets/vertical-dots.svg';
import PropTypes from 'prop-types';
import BaseMenu from './BaseMenu';

const Container = styled.div`
  position: relative;
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 22px;
  height: 22px;
  border-radius: 4px;
&:hover {
  background-color: #F5F5F5;
}
`;

const BaseMenuTrigger = ({ items, onClick, close, onClose, ...rest }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const click = (e) => {
    if (onClick) onClick();
    setAnchorEl(e.target);
  };

  useEffect(() => {
    if (close) {
      setAnchorEl(null);
    }
  }, [close]);

  const handleClose = () => {
    setAnchorEl(null);
    if (onClose) onClose();
  };

  return (
    <Container { ...rest }>
      <IconWrap onClick={click}>
        <DotsIcon />
      </IconWrap>
      <BaseMenu 
        items={items}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      />
    </Container>
  );
};

BaseMenuTrigger.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  close: PropTypes.bool,
  items: PropTypes.array,
};

export default BaseMenuTrigger;
