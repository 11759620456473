import React from 'react';
import { Box, Typography } from '@mui/material';
import BaseButton from '../components/Common/BaseButton';

const ErrorPage = () =>  {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#fff',
      }}
    >
      <Typography variant="h6" style={{ color: '#3C4049' }}>
        Oops! Something went wrong.
      </Typography>
      <BaseButton onClick={() => window.location.reload()}>Refresh</BaseButton>
    </Box>
  );
};

export default ErrorPage;