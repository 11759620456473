import React, { useEffect } from 'react';
import RecentProjects from '../components/Dashboard/RecentProjects';
import ProjectsList from '../components/Dashboard/ProjectsList';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSizes } from '../store/builder';
import NewProject from '../components/Project/NewProject';
import { useNavigate } from 'react-router-dom';
import { updateProjectBuilder } from '../store/builder';
import { setCreatedProject } from '../store/project';

const StyledDashboard = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 46px;
  flex-direction: column;
`;

const Dashboard = () => {
  const createdProject = useSelector(state => state.project.createdProject);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (process.env.REACT_APP_AREA === 'admin') {
    navigate('/admin/users');
  }

  useEffect(() => {
    dispatch(fetchSizes());
  }, []);

  useEffect(()=>{
    if (!createdProject) return;
    (async () => {
      await dispatch(updateProjectBuilder(createdProject));
      await dispatch(setCreatedProject(null));
      navigate('/builder/' + createdProject.id);
    })();
  }, [createdProject]);

  return (
    <StyledDashboard>
      <RecentProjects />
      <ProjectsList />
      <NewProject />
    </StyledDashboard>
  );
};

export default Dashboard;
