import React,{ useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import BaseSelect from '../Common/BaseSelect';
import { fetchSizes, updateProjectBackgroundColor, updateProjectHeight, updateProjectWidth } from '../../store/builder';
import BasePanel from '../Common/BasePanel';
import { updateProjectSize } from '../../store/builder';
import BaseTextBox from '../Common/BaseTextBox';
import BaseColorPicker from '../Common/BaseColorPicker';

const Section = styled.div`
  padding: 16px;
  border-bottom: 1px solid #DCDDE1;
`;

const Dimensions = styled.div`
  display: flex;
  gap: 12px;
`;

const BackgroundColor = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #3C4049;
  flex: 1;
`;

const CanvasPanel = () => {
  const project = useSelector(state => state.builder.project);
  const sizes = useSelector(state => state.builder.sizes);
  const dispatch = useDispatch();
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  useEffect(() => {
    if(typeof width === 'number') dispatch(updateProjectWidth(Math.max(100, width)));
  }, [width]);

  useEffect(() => {
    if(typeof height === 'number') dispatch(updateProjectHeight(Math.max(100, height)));
  }, [height]);

  useEffect(() => {
    dispatch(fetchSizes());
  }, []);

  useEffect(() => {
    if(typeof width !== 'number' || width > 100 && project.width !== width) setWidth(project.width);
  }, [project.width]);

  useEffect(() => {
    if(typeof height !== 'number' || height > 100 && project.height !== height) setHeight(project.height);
  }, [project.height]);

  const handleWidth = (v) => {
    if (v < 0) return;
    setWidth(v);
    if (project.size.id === 1) return;
    const size = sizes.find(s => s.id === project.size.id);
    const newHeight = size.height * Math.max(v, 100) / size.width;
    setHeight(newHeight);
  };

  const handleHeight = (v) => {
    if (v < 0) return;
    setHeight(v);
    if (project.size.id === 1) return;
    const size = sizes.find(s => s.id === project.size.id);
    const newWidth = size.width * Math.max(v, 100) / size.height;
    setWidth(newWidth);
  };

  return (
    <BasePanel panel="canvas" collapsable>
      <Section>
        <BaseSelect
          label="Canvas size"
          textKey="label"
          value={project.size.id}
          options={sizes}
          onChange={(size) => dispatch(updateProjectSize(size))}
        />
      </Section>
      <Section>
        <Dimensions>
          <BaseTextBox
            label="Width"
            value={width}
            width={118}
            inputBackground="#F5F5F5"
            onChange={handleWidth}
            type='number'
            onBlur={() => setWidth(Math.max(100, width))}
          />
          <BaseTextBox
            label="Height"
            value={height}
            width={118}
            inputBackground="#F5F5F5"
            onChange={handleHeight}
            type='number'
            onBlur={() => setHeight(Math.max(100, height))}
          />
        </Dimensions>
        <BackgroundColor>
          <Label>Background color</Label>
          <BaseColorPicker
            color={project.background || '#00000000'}
            setColor={(color) => dispatch(updateProjectBackgroundColor(color))}
          />
        </BackgroundColor>
      </Section>
    </BasePanel>
  );
};

export default CanvasPanel;
