import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as InvisibleIcon } from '../../assets/invisible.svg';
import { ReactComponent as DotsIcon } from '../../assets/vertical-dots.svg';
import { ReactComponent as VisibleIcon } from '../../assets/visible.svg';
import { ReactComponent as TextIcon } from '../../assets/text.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setEditLayerId, setPanel, setSelectedLayer, toggleLayer, updateLayer } from '../../store/builder';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import LayersPanelLayerMenu from './LayersPanelLayerMenu';
import BaseTextBox from '../Common/BaseTextBox';

const Drag = styled.div`
  height: 40px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  
  &.active {
    cursor: grabbing;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #F5F5F5;
  border: 1px solid #DCDDE1;
  border-radius: 4px;
  
  &.selected {
    border: 1px solid #77B36B;
    ${Drag} {
      background-color: #77B36B;
    }
  }
`;

const Content = styled.div`
  flex: 1;
  background: #FFFFFF;
  border-left: 1px solid #DCDDE1;
  border-radius: 4px;
  padding: 4px 4px 4px 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
`;

const Preview = styled.div`
  border: 1px solid #DCDDE1;
  border-radius: 2px;
  width: 40px;
  height: 32px;
  margin-right: 8px;
  
  img {
    width: 38px;
    height: 30px;
    object-fit: cover;
    border-radius: 2px;
  }
`;

const Text = styled.div`    
  max-width: 110px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #3C4049;
  flex: 1;
  z-index: 1;
`;

const Visibility = styled.div`
  cursor: pointer;
  z-index: 1;
`;

const Background = styled.div`
 position: absolute;
 height: 100%;
 width: 100%;
 z-index: 0;
`;

const TextIconContainer = styled.div`
  display: flex;
  width: 40px;
  height: 32px;
  justify-content: center;
  align-items: center;
`;

const LayersPanelLayer = ({ id, name, type, content, visible }) => {
  const selectedLayer = useSelector(state => state.builder.selectedLayer);
  const editLayerId = useSelector(state => state.builder.editLayerId);
  const panel = useSelector(state => state.builder.panel);
  const dispatch = useDispatch();
  const [newName, setNewName] = useState(name);
  const [edit, setEdit] = useState(false);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleRename = () => {
    if (newName && !!newName.replaceAll(' ', ''))
      dispatch(updateLayer({ id, name: newName }));
    else
     setNewName(name);

    setEdit(false);
    dispatch(setEditLayerId(null));
  };

  useEffect(() => {
    setNewName(name);
  }, [name]);

  let openRename;

  if (type === 'image')
    openRename = () => {
      setEdit(true);
      setTimeout(() => dispatch(setEditLayerId(id)));
    };

  const handleClick = () => {
    if (!openRename) return;
    setEdit(true);
    dispatch(setEditLayerId(id));
  };

  const handleVisible = () => {
    if (visible && panel === 'filter') dispatch(setPanel(null));
    dispatch(toggleLayer(id));
  };

  return (
    <Container
      ref={setNodeRef}
      style={style}
      {...attributes}
      className={selectedLayer.id === id ? 'selected' : ''}
      onPointerDown={() => dispatch(setSelectedLayer({ id }))}
    >
      <Drag {...listeners}>
        <DotsIcon />
        <DotsIcon />
      </Drag>
      <Content>
        <Background {...listeners} />
        <Preview>
          { type === 'image' && <img src={ content } alt={ name } /> }
          { type === 'text' && <TextIconContainer><TextIcon /></TextIconContainer> }
        </Preview>
        {edit && editLayerId === id ? 
          <BaseTextBox 
            value={newName} 
            onChange={(value) => setNewName(value)}
            width={100}
            height={20} 
            bottom={0} 
            onEnterClick={handleRename}
            onBlur={handleRename}
            autoFocus
          /> : 
          <Text {...listeners} onDoubleClick={handleClick}>
            { name }
          </Text>
        }
        <Visibility onClick={handleVisible}>
          { visible ? <VisibleIcon /> : <InvisibleIcon /> }
        </Visibility>
        <LayersPanelLayerMenu
          id={id}
          rename={openRename}
          visible={visible}
        />
      </Content>
    </Container>
  );
};

LayersPanelLayer.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.string,
  content: PropTypes.string,
  visible: PropTypes.bool,
};

export default LayersPanelLayer;
