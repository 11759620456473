import React from 'react';
import styled from 'styled-components';
import ToolbarDashboard from './ToolbarDashboard';
import ToolbarIcons from './ToolbarIcons';
import ToolbarZoom from './ToolbarZoom';
import ToolbarExport from './ToolbarExport';
import ToolbarSave from './ToolbarSave';
import ToolbarEdit from './ToolbarEdit';

const Container = styled.div`
  width: 100vw;
  height: 50px;
  background: #F5F5F5;
  border-bottom: 1px solid #DCDDE1;
  display: flex;
  position: fixed;
  z-index: 99;
  top: 40px;
  
  .separator {
    flex: 1;
  }
`;

const Toolbar = () => {
  return (
    <Container>
      <ToolbarDashboard />
      <ToolbarIcons />
      <div className="separator" />
      <ToolbarEdit />
      <ToolbarZoom />
      <ToolbarExport />
      <ToolbarSave />
    </Container>
  );
};

export default Toolbar;
