export const safeFonts = [
    'Arial',
    'Verdana',
    'Tahoma',
    'Trebuchet MS',
    'Times New Roman',
    'Georgia',
    'Garamond',
    'Courier New',
    'Brush Script MT',
];

export function listFonts() {
    let { fonts } = document;
    const it = fonts.entries();
  
    let arr = [...safeFonts];
    let done = false;
  
    while (!done) {
      const font = it.next();
      if (!font.done) {
        arr.push(font.value[0].family);
      } else {
        done = font.done;
      }
    }
  
    return [...new Set(arr)].sort((a, b) => a > b ? 1 : -1);
  }