import React from 'react';
import styled from "styled-components";
import { ReactComponent as ArrowLeftIcon } from "../../assets/caret-left.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/caret-right.svg";
import PropTypes from "prop-types";

const Container = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;
  position: fixed;
  width: calc(100vw - 100px);
  background-color: #F5F5F5;
  bottom: 0;
  height: 80px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const Button = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #222;
  border-radius: 4px;
  
  svg {
    path {
      stroke: #222;
    }
  }

  &.disabled {
    border: 1px solid #ccc;
    cursor: not-allowed;

    svg {
      path {
        stroke: #ccc;
      }
    }
  }
`;

const BaseListFooter = ({ currentPage, previous, next }) => {
  return (
    <Container>
      <Button
        className={currentPage === 1 ? 'disabled' : ''}
        onClick={currentPage === 1 ? () => {} : previous}
      >
        <ArrowLeftIcon />
      </Button>
      <Button
        onClick={next}
      >
        <ArrowRightIcon />
      </Button>
    </Container>
  );
};

BaseListFooter.propTypes = {
  currentPage: PropTypes.number,
  previous: PropTypes.func,
  next: PropTypes.func,
};

export default BaseListFooter;