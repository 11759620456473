import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api';

const initialState = {
  step: 1,
  projects: [],
  orderBy: {
    field: 'updatedAt',
    direction: 'desc',
  },
  pagination: {
    page: 1,
    perPage: 9,
    isLastPage: false,
    loading: false,
  },
  recentProjects: [],
  newProjectOpen: false,
  selectedProject: null,
  createdProject: null,
  project: {
    name: '',
    size: null,
    template: null,
    width: 1200,
    height: 800,
  },
};

export const fetchProjects = createAsyncThunk(
  'project/fetchProjects',
  async (args, { getState }) => {
    const { project } = getState();

    return await api.project.fetch({
      limit: project.pagination.perPage,
      orderBy: project.orderBy.field,
      direction: project.orderBy.direction,
    });
  },
);

export const fetchProjectsNextPage = createAsyncThunk(
  'project/fetchProjectsNextPage',
  async (args, { getState }) => {
    const { project } = getState();

    return await api.project.fetch({
      limit: project.pagination.perPage,
      offset: project.pagination.perPage * project.pagination.page,
      orderBy: project.orderBy.field,
      direction: project.orderBy.direction,
    }, '', false);
  },
);

export const fetchRecentProjects = createAsyncThunk(
  'project/fetchRecentProjects',
  async () => {
    return await api.project.fetch({
      limit: 4,
      orderBy: 'lastOpenedAt',
    });
  },
);

export const createProject = createAsyncThunk(
  'project/createProject',
  async (arg, { dispatch, getState }) => {
    const { project } = getState();

    const projectToCreate = { ...project.project };

    if (projectToCreate.size !== 1) {
      projectToCreate.width = undefined;
      projectToCreate.height = undefined;
    }

    const data = await api.project.post(projectToCreate);
    await dispatch(setCreatedProject(data));
    return dispatch(fetchProjects());
  },
);

export const duplicateProject = createAsyncThunk(
  'project/duplicateProject',
  async (arg, { dispatch, getState }) => {
    const { project } = getState();

    await api.project.post(
      {},
      project.selectedProject + '/copy',
    );

    //await dispatch(setCreatedProject(data));
    dispatch(fetchRecentProjects());
    return dispatch(fetchProjects());
  },
);

export const deleteProject = createAsyncThunk(
  'project/deleteProject',
  async (arg, { dispatch, getState }) => {
    const { project } = getState();

    await api.project.delete(project.selectedProject);
    await dispatch(fetchRecentProjects());
    return dispatch(fetchProjects());
  },
);

export const updateProjectName = createAsyncThunk(
  'project/updateProjectName',
  async ({ name }, { dispatch, getState }) => {
    const { project } = getState();

    const data = await api.project.patch(project.selectedProject, { name });

    return dispatch(setUpdatedName(data));
  },
);

export const project = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjectName(state, { payload }) {
      state.project.name = payload;
    },
    setProjectSize(state, { payload }) {
      state.project.size = payload;
    },
    setProjectWidth(state, { payload }) {
      state.project.width = !payload || isNaN(payload) ? '' : parseInt(payload);
    },
    setProjectHeight(state, { payload }) {
      state.project.height = !payload || isNaN(payload) ? '' : parseInt(payload);
    },
    setProjectTemplate(state, { payload }) {
      state.project.template = payload;
    },
    setSelectedProject(state, { payload }) {
      state.selectedProject = payload;
    },
    setStep(state, { payload }) {
      state.step = payload;
    },
    toggleNewProjectOpen(state) {
      state.newProjectOpen = !state.newProjectOpen;

      if (!state.newProjectOpen) {
        state.project = {
          name: '',
          size: null,
          template: null,
          width: 1200,
          height: 800,
        };

        state.step = 1;
      }
    },
    setCreatedProject(state, { payload }) {
      state.createdProject = payload;
    },
    setUpdatedName(state, { payload: { id, name } }) {
      const updateProjectName = p => p.id === id ? { ...p, name } : p;
      state.projects = state.projects.map(updateProjectName);
      state.recentProjects = state.recentProjects.map(updateProjectName);
    },
    setOrderBy(state, { payload }) {
      const field = state.orderBy.field;

      if (payload === field) {
        state.orderBy.direction = state.orderBy.direction === 'desc' ? 'asc' : 'desc';
      } else {
        state.orderBy.field = payload;
        state.orderBy.direction = 'asc';
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjects.pending, (state) => {
      state.projects = [];
      state.pagination.page = 1;
      state.pagination.isLastPage = false;
    });

    builder.addCase(fetchProjects.fulfilled, (state, { payload }) => {
      state.projects = payload;
    });

    builder.addCase(fetchRecentProjects.pending, (state) => {
      state.recentProjects = [];
    });

    builder.addCase(fetchRecentProjects.fulfilled, (state, { payload }) => {
      state.recentProjects = payload;
    });

    builder.addCase(createProject.fulfilled, (state) => {
      project.caseReducers.toggleNewProjectOpen(state);
    });

    builder.addCase(duplicateProject.fulfilled, (state) => {
      project.caseReducers.setSelectedProject(state, { payload: null });
    });

    builder.addCase(deleteProject.fulfilled, (state) => {
      project.caseReducers.setSelectedProject(state, { payload: null });
    });

    builder.addCase(updateProjectName.fulfilled, (state) => {
      project.caseReducers.setSelectedProject(state, { payload: null });
    });

    builder.addCase(fetchProjectsNextPage.pending, (state) => {
      state.pagination.loading = true;
    });

    builder.addCase(fetchProjectsNextPage.fulfilled, (state, { payload }) => {
      state.projects = [...state.projects, ...payload];
      state.pagination.page += 1;
      if (payload.length < state.pagination.perPage) state.pagination.isLastPage = true;
      state.pagination.loading = false;
    });
  },
});

export const {
  setProjectName,
  setOrderBy,
  setProjectSize,
  setProjectWidth,
  setProjectHeight,
  setProjectTemplate,
  setSelectedProject,
  setStep,
  toggleNewProjectOpen,
  setCreatedProject,
  setUpdatedName,
} = project.actions;

export default project.reducer;
