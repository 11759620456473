import React from 'react';
import styled from 'styled-components';
import BasePanel from '../Common/BasePanel';
import { useSelector } from 'react-redux';
import { ReactComponent as BlurIcon } from '../../assets/filter/blur.svg';
import { ReactComponent as BrightenIcon } from '../../assets/filter/brighten.svg';
import { ReactComponent as ContrastIcon } from '../../assets/filter/contrast.svg';
import { ReactComponent as EmbossIcon } from '../../assets/filter/emboss.svg';
import { ReactComponent as EnhanceIcon } from '../../assets/filter/enhance.svg';
import { ReactComponent as GrayscaleIcon } from '../../assets/filter/grayscale.svg';
import { ReactComponent as InvertIcon } from '../../assets/filter/invert.svg';
import { ReactComponent as NoiseIcon } from '../../assets/filter/noise.svg';
import { ReactComponent as SaturationIcon } from '../../assets/filter/saturation.svg';
import FilterPanelItem from './FilterPanelItem';
import FilterConfigs from './FilterConfigs';

const Container = styled(BasePanel)`
  > div:nth-child(2) {
    padding: 16px;
  }
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #3C4049;
  margin: 0 0 4px;
`;

const Subtitle = styled.h4`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #7C8394;
  margin: 0;
`;

const Filters = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;
  margin-top: 8px;
`;

const FilterPanel = () => {
  const filters = useSelector(state => state.filter.filters);

  const icons = {
    Blur: <BlurIcon />,
    Brighten: <BrightenIcon />,
    Contrast: <ContrastIcon />,
    Emboss: <EmbossIcon />,
    Enhance: <EnhanceIcon />,
    Grayscale: <GrayscaleIcon />,
    Invert: <InvertIcon />,
    Noise: <NoiseIcon />,
    Saturation: <SaturationIcon />,
  };

  return (
    <>
      <Container panel="filter" collapsable>
        <Title>Filters</Title>
        <Subtitle>You can apply effect to the selected layer</Subtitle>
        <Filters>
          {
            filters.map((filter, index) => (
              <FilterPanelItem
                key={index}
                name={filter.name}
                parameters={filter.parameters}
                icon={icons[filter.name]}
              />
            ))
          }
        </Filters>
      </Container>
      <FilterConfigs />
    </>
  );
};

export default FilterPanel;
