import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api';

const initialText = {
  content: '',
  font: {
    family: 'Arial',
    size: 48,
  },
  bold: false,
  italic: false,
  lineThrough: false,
  underline: false,
  gradient: {
    applied: false,
    colorA: '#FF0000',
    colorB: '#4A90E2',
    startPoint: 25,
    endPoint: 75,
    angle: 180,
  },
  color: '#FF0000',
  background: '#FFFFFF00',
  stroke: {
    applied: false,
    color: '#FF0000',
    width: 0,
  },
  shadow: {
    applied: false,
    horizontalOffset: 6,
    verticalOffset: 7,
    blur: 3,
    color: '#C0C0C0',
  },
  textAlign: 'left',
};

const initialProject = {
  id: null,
  background: null,
  size: {
    id: null,
  },
  width: null,
  height: null,
  content: [],
  lastLayerId: 0,
};

const initialState = {
  stageContent: null,
  draggedImage: null,
  exportDialog: false,
  exportRequested: null,
  saveRequested: false,
  saveRedirect: false,
  flipVerticalRequested: false,
  flipHorizontalRequested: false,
  duplicateRequested: null,
  editNameModal: false,
  generateTextOpened: false,
  project: initialProject,
  selectedLayer: {
    id: null,
    meta: {
      filter: {
        name: null,
      },
      opacity: 100,
    },
  },
  selectedText: null,
  editLayerId: null,
  text: initialText,
  fontFamilyPreview: null,
  imageBackgroundPreview: null,
  showMenuFor: null,
  lasso: {
    points: [],
    clipboard: null,
  },
  tool: 'select',
  panel: null,
  sizes: [],
  zoom: 50,
  eraserSize: 15,
  events: [],
  eventsIndex: 0,
  mergeDownLayer: null,
};

export const fetchProject = createAsyncThunk(
  'builder/fetchProject',
  async ({ id }) => {
    return await api.project.get(id);
  },
);

export const saveProject = createAsyncThunk(
  'builder/saveProject',
  async (args, { dispatch, getState }) => {
    const { builder } = getState();
    await api.project.save(builder.project, builder.stageContent);
    return dispatch(clearHistory());
  },
);

export const fetchSizes = createAsyncThunk(
  'builder/fetchSizes',
  async (arg, { getState }) => {
    const { builder } = getState();

    if (builder.sizes.length > 0) {
      return;
    }

    return await api.projectSize.fetch();
  },
);

export const removeBackground = createAsyncThunk(
  'builder/removeBackground',
  async (args, { getState }) => {
    const { builder } = getState();
    const layerToUpdate = builder.project.content.find(l => l.id === builder.selectedLayer.id);
    if(builder.imageBackgroundPreview) return builder.imageBackgroundPreview;
    return await api.imageEditor.upload(layerToUpdate.content);
  },
);

export const previewImageBackground = createAsyncThunk(
  'builder/previewImageBackground',
  async (args, { getState }) => {
    const { builder } = getState();
    const layerToUpdate = builder.project.content.find(l => l.id === builder.selectedLayer.id);
    return await api.imageEditor.upload(layerToUpdate.content);
  },
);

const updateLayerById = (state, func) => {
  for (const layer of state.project.content) {
    if (layer.id === state.selectedText) {
      const valueBefore = JSON.parse(JSON.stringify(layer));
      func(layer);
      const valueAfter = JSON.parse(JSON.stringify(layer));

      state.events = [...state.events.slice(0, state.eventsIndex), ...state.events.slice(state.eventsIndex).reverse(), {
        type: 'updateLayerById',
        value: valueBefore,
        payload: valueAfter,
      }];
      state.eventsIndex = state.events.length;

      return;
    }
  }
};

const updateLayerFunc = (state, payload) => {
  const { id, x, y, left, top, width, height, rotation, content, name } = payload;

  let layerSnapshot;

  for (const layer of state.project.content) {
    if (layer.id === id) {
      layerSnapshot = { ...layer };

      if (typeof x === 'number') {
        layer.left = x;
      }
      
      if (typeof left === 'number') {
        layer.left = left;
      }

      if (typeof y === 'number') {
        layer.top = y;
      }

      if (typeof top === 'number') {
        layer.top = top;
      }

      if (width) {
        layer.width = width;
      }

      if (height) {
        layer.height = height;
      }

      if (typeof rotation === 'number') {
        layer.rotation = rotation;
      }

      if (content) {
        layer.content = content;
      }

      if (name) {
        layer.name = name;
      }

      break;
    }
  }
  return layerSnapshot;
};

const changeLayer = (state, payload) => {
  for (const layer of state.project.content) {
    if(layer.id === payload.id) {
      Object.keys(layer).map(key => {
        if (typeof payload[key] !== 'undefined' && typeof payload[key] === 'object') { 
          Object.keys(layer[key]).map(k => {
            if (typeof payload[key][k] !== 'undefined' && layer[key][k] !== payload[key][k]) {
              if (state.text && state.text.id === payload.id) state.text[key][k] = payload[key][k];
              layer[key][k] = payload[key][k];
            }
          });
        } else if (typeof payload[key] !== 'undefined' && layer[key] !== payload[key]) {
          if (state.text && state.text.id === payload.id) state.text[key] = payload[key];
          layer[key] = payload[key];
        }
      });
    }
  }
};

const updateLayerVisibility = (state, payload) => {
  for (const layer of state.project.content) {
    if (layer.id === payload) {
      layer.visible = !layer.visible;

      if (!layer.visible && state.selectedLayer && state.selectedLayer.id === layer.id) {
        state.selectedLayer = {
          id: null,
        };
      }
      break;
    }
  }
};

const updateLayerByKey = (state, { key, id, value }) => {
  if (state.selectedLayer.id === id) state.selectedLayer.meta[key] = value;

  for (const layer of state.project.content) {
    if (layer.id === id) {
      layer[key] = value;

      break;
    }
  }
};

export const builder = createSlice({
  name: 'builder',
  initialState,
  reducers: {
    setTextContent(state, { payload }) {
      state.text.content = payload;

      updateLayerById(
        state,
        (layer) => layer.content = payload,
      );
    },
    setStageContent(state, { payload }) {
      state.stageContent = payload;
    },
    addLayer(state, { payload }) {
      state.project.content.push(payload);
      state.project.lastLayerId++;
      state.draggedImage = null;

      if (payload.type === 'text') {
        state.selectedText = payload.id;
      }
      
      state.events = [...state.events.slice(0, state.eventsIndex), ...state.events.slice(state.eventsIndex).reverse(), {
        type: 'addLayer',
        payload,
      }];
      state.eventsIndex = state.events.length;
    },
    mergeLayers(state, { payload: { data, layers } }) {
      const layerIndex = state.project.content.findIndex(l => l.id === layers[1].id);
      state.project.content[layerIndex] = data;
      state.project.content.splice(layerIndex - 1, 1);
      
      state.events = [...state.events.slice(0, state.eventsIndex), ...state.events.slice(state.eventsIndex).reverse(), {
        type: 'mergeLayers',
        payload: data,
        value: layers,
      }];
      state.eventsIndex = state.events.length;
    },
    increment: (state) => {
      if (state.zoom >= 800) {
        return;
      }

      state.zoom = state.zoom + 25;
    },
    decrement: (state) => {
      if (state.zoom <= 25) {
        return;
      }

      state.zoom = state.zoom - 25;
    },
    setZoom: (state, { payload }) => {
      state.zoom = payload;
    },
    setDraggedImage(state, { payload }) {
      state.draggedImage = payload;
    },
    setExportRequested(state, { payload }) {
      state.tool = 'select';
      state.panel = null;
      state.exportRequested = payload;
    },
    setExportDialog(state, { payload }) {
      state.exportDialog = payload;
    },
    setSaveRequested(state, { payload }) {
      state.tool = 'select';
      state.panel = null;
      state.saveRequested = payload;
    },
    setSaveRedirect(state, { payload }) {
      state.saveRedirect = payload;
    },
    setDuplicateRequested(state, { payload }) {
      state.duplicateRequested = payload;
    },
    setEditNameModal(state, { payload }) {
      state.editNameModal = payload;
    },
    setSelectedLayer(state, { payload }) {
      let selectable = false;
      for (const layer of state.project.content) {
        if (layer.id === payload.id) {
          payload.meta = layer;

          if (layer.type === 'image' && ['select', 'filter', 'eraser', 'lasso',  'background'].indexOf(state.tool) !== -1) {
            selectable = true;
          } else if (layer.type === 'text' && ['select', 'text'].indexOf(state.tool) !== -1) {
            selectable = true;
          }

          break;
        }
      }

      if (selectable) {
        state.selectedLayer = payload;
      }
    },
    setShowMenuFor(state, { payload }) {
      state.showMenuFor = payload;
    },
    emptySelectedLayer(state) {
      state.selectedLayer = {
        id: null,
      };
    },
    setTool(state, { payload }) {
      state.tool = payload;
    },
    setPanel(state, { payload }) {
      if (state.panel === payload) {
        state.panel = null;

        if (payload === 'text') {
          state.selectedText = null;

          state.text = initialText;

          state.selectedLayer = {
            id: null,
          };
        }
        return;
      }

      state.panel = payload;
    },
    toggleGenerateTextOpened(state, { payload }) {
      if (payload !== undefined) {
        state.generateTextOpened = payload;
        return;
      }

      state.generateTextOpened = !state.generateTextOpened;
    },
    toggleLayer(state, { payload }) {
      updateLayerVisibility(state, payload);
      state.events = [...state.events.slice(0, state.eventsIndex), ...state.events.slice(state.eventsIndex).reverse(), {
        type: 'updateLayerVisibility',
        payload,
      }];
      state.eventsIndex = state.events.length;
    },
    deleteLayer(state, { payload }) {
      if (state.selectedLayer.id === payload) {
        state.selectedLayer = {
          id: null,
        };

        if (state.panel === 'text') {
          state.panel = null;
          state.selectedText = null;
          state.text = initialText;
        }
      }

      const content = [];

      let layerData;
      for (const [index, layer] of state.project.content.entries()) {
        if (layer.id !== payload) {
          content.push(layer);
        } else {
          layerData = { index, data: JSON.parse(JSON.stringify(layer)) };
        }
      }

      state.events = [...state.events.slice(0, state.eventsIndex), ...state.events.slice(state.eventsIndex).reverse(), {
        type: 'deleteLayer',
        payload,
        value: layerData,
      }];
      state.eventsIndex = state.events.length;

      state.project.content = content;
    },
    updateLayer(state, { payload }) {
      const layerSnapshot = updateLayerFunc(state, payload);
      state.events = [...state.events.slice(0, state.eventsIndex), ...state.events.slice(state.eventsIndex).reverse(), {
        type: 'updateLayer',
        payload,
        value: layerSnapshot,
      }];
      state.eventsIndex = state.events.length;
    },
    updateLayers(state, { payload }) {
      state.project.content = payload;
    },
    updateProjectBackgroundColor(state, { payload }) {
      state.project.background = payload;
    },
    updateProjectSize(state, { payload }) {
      state.project.size.id = payload;

      for (const size of state.sizes) {
        if (size.id === payload && size.width && size.height) {
          state.project.width = size.width;
          state.project.height = size.height;
          return;
        }
      }
    },
    updateProjectWidth(state, { payload }) {
      state.project.width = payload;
    },
    updateProjectHeight(state, { payload }) {
      state.project.height = payload;
    },
    updateProjectDimensions(state, { payload }) {
      state.project.width = payload.width;
      state.project.height = payload.height;
    },
    setSelectedText(state, { payload }) {
      state.selectedText = payload.id;

      state.text = payload;
    },
    emptySelectedText(state) {
      state.selectedText = null;

      state.text = initialText;
    },
    setFontFamily(state, { payload }) {
      state.text.font.family = payload;

      updateLayerById(
        state,
        (layer) => layer.font.family = payload,
      );
    },
    setFontFamilyPreview(state, { payload }) {
      state.fontFamilyPreview = payload;
    },
    setFontSize(state, { payload }) {
      state.text.font.size = payload ? parseInt(payload) : '';

      updateLayerById(
        state,
        (layer) => layer.font.size = payload ? parseInt(payload) : '',
      );
    },
    setBackground(state, { payload }) {
      state.text.background = payload;
      updateLayerById(
        state,
        (layer) => layer.background = payload,
      );
    },
    setColor(state, { payload }) {
      state.text.color = payload;

      updateLayerById(
        state,
        (layer) => layer.color = payload,
      );
    },
    toggleStroke(state) {
      state.text.stroke.applied = !state.text.stroke.applied;

      if (!state.text.stroke.applied) {
        state.text.stroke.width = 0;
      }

      updateLayerById(
        state,
        (layer) => {
          layer.stroke.applied = !layer.stroke.applied;

          if (!layer.stroke.applied) {
            layer.stroke.width = 0;
          }
        },
      );
    },
    setFilter(state, { payload }) {
      const { id, meta: { filter } } = JSON.parse(JSON.stringify(state.selectedLayer));
      updateLayerByKey(state, { id, key: 'filter', value: payload });
      state.events = [...state.events.slice(0, state.eventsIndex), ...state.events.slice(state.eventsIndex).reverse(), {
        type: 'updateLayerByKey',
        payload: { id, key: 'filter', value: payload },
        value: filter,
      }];
      state.eventsIndex = state.events.length;
    },
    setOpacity(state, { payload }) {
      const { id, meta: { opacity } } = JSON.parse(JSON.stringify(state.selectedLayer));
      updateLayerByKey(state, { id, key: 'opacity', value: payload });
      state.events = [...state.events.slice(0, state.eventsIndex), ...state.events.slice(state.eventsIndex).reverse(), {
        type: 'updateLayerByKey',
        payload: { id, key: 'opacity', value: payload },
        value: opacity,
      }];
      state.eventsIndex = state.events.length;
    },
    setStrokeColor(state, { payload }) {
      state.text.stroke.color = payload;

      updateLayerById(
        state,
        (layer) => layer.stroke.color = payload,
      );
    },
    setStrokeWidth(state, { payload }) {
      state.text.stroke.width = payload;

      updateLayerById(
        state,
        (layer) => layer.stroke.width = payload,
      );
    },
    toggleShadow(state) {
      state.text.shadow.applied = !state.text.shadow.applied;

      if (!state.text.shadow.applied) {
        state.text.shadow.horizontalOffset = 2;
        state.text.shadow.verticalOffset = 2;
        state.text.shadow.blur = 2;
      }

      updateLayerById(
        state,
        (layer) => {
          layer.shadow.applied = !layer.shadow.applied;

          if (!layer.shadow.applied) {
            layer.shadow.horizontalOffset = 2;
            layer.shadow.verticalOffset = 2;
            layer.shadow.blur = 2;
          }
        },
      );
    },
    setShadowHorizontalOffset(state, { payload }) {
      state.text.shadow.horizontalOffset = payload;

      updateLayerById(
        state,
        (layer) => layer.shadow.horizontalOffset = payload,
      );
    },
    setShadowVerticalOffset(state, { payload }) {
      state.text.shadow.verticalOffset = payload;

      updateLayerById(
        state,
        (layer) => layer.shadow.verticalOffset = payload,
      );
    },
    setShadowBlur(state, { payload }) {
      state.text.shadow.blur = payload;

      updateLayerById(
        state,
        (layer) => layer.shadow.blur = payload,
      );
    },
    setShadowColor(state, { payload }) {
      state.text.shadow.color = payload;

      updateLayerById(
        state,
        (layer) => layer.shadow.color = payload,
      );
    },
    setTextAlign(state, { payload }) {
      state.text.textAlign = payload;

      updateLayerById(
        state,
        (layer) => layer.textAlign = payload,
      );
    },
    toggleBold(state) {
      state.text.bold = !state.text.bold;

      updateLayerById(
        state,
        (layer) => layer.bold = !layer.bold,
      );
    },
    toggleItalic(state) {
      state.text.italic = !state.text.italic;

      updateLayerById(
        state,
        (layer) => layer.italic = !layer.italic,
      );
    },
    toggleLineThrough(state) {
      state.text.lineThrough = !state.text.lineThrough;

      updateLayerById(
        state,
        (layer) => layer.lineThrough = !layer.lineThrough,
      );
    },
    toggleUnderline(state) {
      state.text.underline = !state.text.underline;

      updateLayerById(
        state,
        (layer) => layer.underline = !layer.underline,
      );
    },
    updateProjectBuilder(state, { payload }) {
      state.project = payload;

      state.text = initialText;

      state.tool = 'select';
      state.panel = null;
    },
    toggleGradient(state) {
      state.text.gradient.applied = !state.text.gradient.applied;

      updateLayerById(
        state,
        (layer) => {
          layer.gradient.applied = !layer.gradient.applied;
        },
      );
    },
    setGradientColorA(state, { payload }) {
      state.text.gradient.colorA = payload;

      updateLayerById(
        state,
        (layer) => {
          layer.gradient.colorA = payload;
        },
      );
    },
    setGradientColorB(state, { payload }) {
      state.text.gradient.colorB = payload;

      updateLayerById(
        state,
        (layer) => {
          layer.gradient.colorB = payload;
        },
      );
    },
    setGradientAngle(state, { payload }) {
      state.text.gradient.angle = payload;

      updateLayerById(
        state,
        (layer) => {
          layer.gradient.angle = payload;
        },
      );
    },
    setStartPoint(state, { payload }) {
      state.text.gradient.startPoint = payload;

      updateLayerById(
        state,
        (layer) => {
          layer.gradient.startPoint = payload;
        },
      );
    },
    setEndPoint(state, { payload }) {
      state.text.gradient.endPoint = payload;
      
      updateLayerById(
        state,
        (layer) => {
          layer.gradient.endPoint = payload;
        },
        );
    },
    setEraserSize(state, { payload }) {
      state.eraserSize = payload;
    },
    setLasso(state, { payload }) {
      const { clipboard, name, points } = payload;

      if(clipboard)
        state.lasso.clipboard = clipboard;

      if(name)
        state.lasso.name = name;
      
      if(points)
        state.lasso.points = points;
    },
    emptyLasso(state) {
      state.lasso.points = [];
      state.lasso.clipboard = null;
    },
    setFlipVerticalRequested(state, { payload }) {
      state.flipVerticalRequested = payload;
    },
    setFlipHorizontalRequested(state, { payload }) {
      state.flipHorizontalRequested = payload;
    },
    emptyBackgroundPreview(state) {
      state.imageBackgroundPreview = null;
    },
    setEditLayerId(state, { payload }) {
      state.editLayerId = payload;
    },
    undo(state) {
      const lastEvent = state.events[state.eventsIndex - 1];
      if(lastEvent.type === 'updateLayer') {
        updateLayerFunc(state, { 
          ...lastEvent.value, 
          rotation: typeof lastEvent.value.rotation === 'number' ?
            lastEvent.value.rotation :
            typeof lastEvent.payload.rotation === 'number' ? 0 : undefined,
        });
      }
      if(lastEvent.type === 'updateLayerById') {
        changeLayer(state, JSON.parse(JSON.stringify(lastEvent.value)));
      }
      if(lastEvent.type === 'updateLayerVisibility') {
        updateLayerVisibility(state, lastEvent.payload);
      }
      if(lastEvent.type === 'updateLayerByKey') {
        updateLayerByKey(state, { id: lastEvent.payload.id, key: lastEvent.payload.key, value: lastEvent.value });
      }
      if(lastEvent.type === 'deleteLayer') {
        const content = JSON.parse(JSON.stringify(state.project.content));
        content.splice(lastEvent.value.index, 0, lastEvent.value.data);
        state.project.content = content;
      }
      if(lastEvent.type === 'addLayer') {
        const content = [];
        for (const layer of state.project.content) {
          if (layer.id !== lastEvent.payload.id) {
            content.push(layer);
          }
        }
        state.project.content = content;
      }
      if(lastEvent.type === 'mergeLayers') {
        const layerIndex = state.project.content.findIndex(l => l.id === lastEvent.payload.id);
        state.project.content[layerIndex] = lastEvent.value[0];
        state.project.content.splice(layerIndex + 1, 0, lastEvent.value[1]);
      }
      state.eventsIndex = Math.max(0, state.eventsIndex - 1);
    },
    redo(state) {
      const lastEvent = state.events[state.eventsIndex];
      if(lastEvent.type === 'updateLayer') {
        updateLayerFunc(state, { 
          ...lastEvent.payload, 
          rotation: typeof lastEvent.payload.rotation === 'number' ?
            lastEvent.payload.rotation :
            typeof lastEvent.value.rotation === 'number' ? 0 : undefined,
        });
      }
      if(lastEvent.type === 'updateLayerById') {
        changeLayer(state, JSON.parse(JSON.stringify(lastEvent.payload)));
      }
      if(lastEvent.type === 'updateLayerVisibility') {
        updateLayerVisibility(state, lastEvent.payload);
      }
      if(lastEvent.type === 'updateLayerByKey') {
        updateLayerByKey(state, JSON.parse(JSON.stringify(lastEvent.payload)));
      }
      if(lastEvent.type === 'deleteLayer') {
        const content = [];
        for (const layer of state.project.content) {
          if (layer.id !== lastEvent.payload) {
            content.push(layer);
          }
        }
        state.project.content = content;
      }
      if(lastEvent.type === 'addLayer') {
        state.project.content.push(lastEvent.payload);
      }
      if(lastEvent.type === 'mergeLayers') {
        const layerIndex = state.project.content.findIndex(l => l.id === lastEvent.value[1].id);
        state.project.content[layerIndex] = lastEvent.payload;
        state.project.content.splice(layerIndex - 1, 1);
      }
      state.eventsIndex = state.eventsIndex + 1;
    },
    clearHistory(state) {
      state.events = [];
    },
    setMergeDownLayer(state, { payload }) {
      state.mergeDownLayer = payload;
    },
    clearBuilder(state) {
      state.zoom = 50;
      state.eraserSize = 15;
      state.events = [];
      state.eventsIndex = 0;
      state.text = initialText;
      state.selectedText = null;
      state.selectedLayer = {
        id: null,
      };
      state.lasso.points = [];
      state.lasso.clipboard = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProject.pending, (state) => {
      state.tool = 'select';
      state.panel = null;
      state.project = initialProject;
    });

    builder.addCase(fetchProject.fulfilled, (state, { payload }) => {

      state.selectedLayer = {
        id: null,
      };
      
      state.project = payload;

      state.text = initialText;

      state.tool = 'select';
      state.panel = null;
      state.generateTextOpened = false;
    });

    builder.addCase(fetchSizes.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }

      state.sizes = payload;
    });

    builder.addCase(removeBackground.fulfilled, (state, { payload }) => {
      state.imageBackgroundPreview = null;
      state.tool = 'select';
      for (const layer of state.project.content) {
        if (layer.id === state.selectedLayer.id) {
          layer.content = payload;
          return;
        }
      }
    });

    builder.addCase(previewImageBackground.fulfilled, (state, { payload }) => {
      state.imageBackgroundPreview = payload;
    });
  },
});

export const {
  setStageContent,
  addLayer,
  increment,
  decrement,
  setZoom,
  deleteLayer,
  setDraggedImage,
  setExportDialog,
  setExportRequested,
  setSelectedLayer,
  emptySelectedLayer,
  setTool,
  setPanel,
  setShowMenuFor,
  toggleGenerateTextOpened,
  toggleLayer,
  updateLayer,
  updateLayers,
  updateProjectBackgroundColor,
  updateProjectSize,
  updateProjectWidth,
  updateProjectHeight,
  updateProjectDimensions,
  setSelectedText,
  emptySelectedText,
  setTextContent,
  setFontFamily,
  setFontFamilyPreview,
  setFontSize,
  setBackground,
  setColor,
  toggleStroke,
  setStrokeColor,
  setStrokeWidth,
  toggleShadow,
  setShadowHorizontalOffset,
  setShadowVerticalOffset,
  setShadowBlur,
  setShadowColor,
  setTextAlign,
  toggleBold,
  toggleItalic,
  toggleLineThrough,
  toggleUnderline,
  updateProjectBuilder,
  toggleGradient,
  setGradientColorA,
  setGradientColorB,
  setGradientAngle,
  setStartPoint,
  setEndPoint,
  setSaveRequested,
  setSaveRedirect,
  setFilter,
  setOpacity,
  setEraserSize,
  setLasso,
  emptyLasso,
  setFlipVerticalRequested,
  setFlipHorizontalRequested,
  emptyBackgroundPreview,
  setEditLayerId,
  undo,
  redo,
  clearHistory,
  setDuplicateRequested,
  setEditNameModal,
  setMergeDownLayer,
  mergeLayers,
  clearBuilder,
} = builder.actions;

export default builder.reducer;
