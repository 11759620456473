import React, { Fragment, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { BackButton, SimpleButton } from "../Common/BaseButton";
import {
  clearEditedAsset,
  fetchAsset,
  fetchAssetCategories,
  setEditedAssetCategory, setEditedAssetContent,
  setEditedAssetName, setEditedAssetType,
  updateAsset,
} from "../../store/admin";
import { useDispatch, useSelector } from "react-redux";
import BaseTextBox from "../Common/BaseTextBox";
import BaseSelect from "../Common/BaseSelect";

const Container = styled.div`
  margin-bottom: 40px;
  padding-top: 150px;
  display: flex;
  align-items: flex-start;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

const Image = styled.img`
  width: 150px;
  cursor: pointer;
`;

const EmptyImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  cursor: pointer;
  background: rgb(119, 179, 107);
`;

const Link = styled.div`
  text-decoration: underline;
  color: rgb(119, 179, 107);
  cursor: pointer;
  
  &:hover {
    text-decoration: none;
  }
`;

const Field = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  justify-content: flex-end;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const AssetForm = () => {
  const { name, content, category, type } = useSelector(({ admin }) => admin.asset.edited);
  const categories = useSelector(({ admin }) => admin.asset.categories);
  const types = useSelector(({ admin }) => admin.asset.types);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { assetId } = useParams();
  const uploadRef = useRef();

  useEffect(() => {
    if (isNaN(assetId)) {
      dispatch(clearEditedAsset());
    } else {
      dispatch(fetchAsset(assetId));
    }

    dispatch(fetchAssetCategories());
  }, []);

  const selectFile = () => {
    uploadRef.current.click();
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      dispatch(setEditedAssetContent(reader.result));
    };
  };

  const back = () => {
    navigate('/admin/assets');
  };

  const save = async () => {
    await dispatch(updateAsset());
    navigate('/admin/assets');
  };

  return (
    <Container>
      <ImageContainer>
        { content &&
          <Fragment>
            <Image
              src={content}
              alt=""
              onClick={selectFile}
            />
            <Link onClick={selectFile}>
              Replace Asset
            </Link>
          </Fragment>
        }
        { !content &&
          <Fragment>
            <EmptyImage onClick={selectFile} />
            <Link onClick={selectFile}>
              Upload Asset
            </Link>
          </Fragment>
        }
        <input
          type="file"
          ref={uploadRef}
          hidden
          onChange={handleUpload}
          accept="image/png, image/jpeg"
        />
      </ImageContainer>
      <div>
        <Field>
          <Label>
            Name:
          </Label>
          <BaseTextBox
            width={600}
            bottom={0}
            value={name}
            onChange={(val) => dispatch(setEditedAssetName(val))}
          />
        </Field>
        <Field>
          <Label>
            Category:
          </Label>
          <BaseSelect
            width={600}
            value={category}
            options={categories}
            textKey="fullName"
            onChange={(val) => dispatch(setEditedAssetCategory(val))}
          />
        </Field>
        <Field>
          <Label>
            Type:
          </Label>
          <BaseSelect
            width={600}
            value={type}
            options={types}
            onChange={(val) => dispatch(setEditedAssetType(val))}
          />
        </Field>
        <Actions>
          <BackButton
            onClick={back}
          />
          <SimpleButton
            onClick={save}
          >
            Save
          </SimpleButton>
        </Actions>
      </div>
    </Container>
  );
};

export default AssetForm;