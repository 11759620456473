import React from 'react';
import styled from 'styled-components';
import { ReactComponent as GenerateIcon } from '../../assets/toolbar/generate.svg';
import GeneratorHistoryBoxImage from './GeneratorHistoryBoxImage';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setPreviewedItem } from '../../store/imageGenerator';

const StyledGeneratorHistoryBox = styled.div`
  border: 1px solid #DCDDE1;
  border-radius: 6px;
  margin-top: 16px;
  padding: 8px;
`;

const GeneratorHistoryBoxHeader = styled.div`
  display: flex;
  gap: 12px;
  align-items: flex-start:
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin-bottom: 8px;
`;

const GeneratorHistoryBoxContent = styled.div`
   display: grid;
   grid-gap: 4px;
   grid-template-columns: 1fr 1fr;
`;

const GeneratorHistoryBox = ({ item, prompt, images }) => {
  const dispatch = useDispatch();

  return (
    <StyledGeneratorHistoryBox
      onClick={() => dispatch(setPreviewedItem(item))}
    >
      <GeneratorHistoryBoxHeader>
        <GenerateIcon width={20} stroke="#77B26B" />
        <div>{ prompt }</div>
      </GeneratorHistoryBoxHeader>
      <GeneratorHistoryBoxContent>
        {
          images.map((image, index) => (
            <GeneratorHistoryBoxImage
              key={index}
              image={image.content}
              prompt={prompt}
            />
          ))
        }
      </GeneratorHistoryBoxContent>
    </StyledGeneratorHistoryBox>
  );
};

GeneratorHistoryBox.propTypes = {
  item: PropTypes.object,
  prompt: PropTypes.string,
  images: PropTypes.array,
};

export default GeneratorHistoryBox;
