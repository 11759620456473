import React from 'react';
import { ReactComponent as TrashIcon } from '../../assets/trash.svg';
import { ReactComponent as RenameIcon } from '../../assets/rename.svg';
import { ReactComponent as CopyIcon } from '../../assets/copy.svg';
import { ReactComponent as HideIcon } from '../../assets/hide.svg';
import { ReactComponent as InvisibleIcon } from "../../assets/invisible.svg";
import { ReactComponent as MergeIcon } from '../../assets/merge.svg';
import { useDispatch, useSelector } from 'react-redux';
import BaseMenuTrigger from '../Common/BaseMenuTrigger';
import PropTypes from 'prop-types';
import { deleteLayer, setDuplicateRequested, setMergeDownLayer, setPanel, setShowMenuFor, toggleLayer } from '../../store/builder';

const LayersPanelLayerMenu = ({ id, rename, visible }) => {
  const showMenuFor = useSelector(state => state.builder.showMenuFor);
  const panel = useSelector(state => state.builder.panel);
  const dispatch = useDispatch();

  const closeFilterPanel = () => {
    if (panel === 'filter') dispatch(setPanel(null));
  };

  const items = [
    {
      icon: <CopyIcon />,
      label: 'Duplicate',
      action: () => dispatch(setDuplicateRequested(id)),
    },
    {
      icon: <MergeIcon />,
      label: 'Merge down',
      action: () => dispatch(setMergeDownLayer(id)),
    },
    {
      separator: true,
    },
    {
      icon: visible ? <HideIcon /> : <InvisibleIcon />,
      label: (visible ? 'Hide' : 'Show') + ' Layer',
      action: () => closeFilterPanel(dispatch(toggleLayer(id))),
    },
  ];

  if (rename) items.push({
    icon: <RenameIcon />,
    label: 'Rename',
    action: () => rename(),
  });

  items.push(
    { 
      separator: true,
    },
    {
      icon: <TrashIcon />,
      label: 'Delete',
      action: () => closeFilterPanel(dispatch(deleteLayer(id))),
    });

  return (
    <BaseMenuTrigger
      onClick={() => dispatch(setShowMenuFor(id))}
      close={showMenuFor !== id}
      items={items}
    />
  );
};

LayersPanelLayerMenu.propTypes = {
  id: PropTypes.number,
  rename: PropTypes.func,
  visible: PropTypes.bool,
};

export default LayersPanelLayerMenu;
