import React from 'react';
import GeneratorNew from './GeneratorNew';
import GeneratorHistory from './GeneratorHistory';
import BaseTabs from '../Common/BaseTabs';
import BasePanel from '../Common/BasePanel';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTab } from '../../store/imageGenerator';

const Generator = () => {
  const selectedTab = useSelector(state => state.imageGenerator.selectedTab);
  const dispatch = useDispatch();

  let tab = <GeneratorNew />;

  if (selectedTab === 'history') {
    tab = <GeneratorHistory />;
  }

  const tabs = [
    { label: 'New', value: 'new' },
    { label: 'History', value: 'history' },
  ];

  return (
    <BasePanel panel="generate" collapsable>
      <BaseTabs
        tabs={tabs}
        activeTab={selectedTab}
        switchTab={(tab) => dispatch(setSelectedTab(tab))}
      />
      { tab }
    </BasePanel>
  );
};

export default Generator;
