import React from 'react';
import styled from 'styled-components';
import { ToolToolbarIcon, PanelToolbarIcon } from '../Common/BaseToolbarIcon';
import { ReactComponent as CanvasIcon } from '../../assets/toolbar/canvas.svg';
import { ReactComponent as FiltersIcon } from '../../assets/toolbar/filters.svg';
import { ReactComponent as TextIcon } from '../../assets/toolbar/text.svg';
import { ReactComponent as GenerateIcon } from '../../assets/toolbar/generate.svg';
import { ReactComponent as ImportIcon } from '../../assets/toolbar/import.svg';
import { ReactComponent as LibraryIcon } from '../../assets/toolbar/library.svg';
import { ReactComponent as SelectIcon } from '../../assets/toolbar/select.svg';
import { ReactComponent as EraserIcon } from '../../assets/toolbar/eraser.svg';
import { ReactComponent as LassoIcon } from '../../assets/toolbar/lasso.svg';
import { ReactComponent as BackgroundIcon } from '../../assets/toolbar/remove-background.svg';

const StyledToolbarIcons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5px;
  border-right: 1px solid #DCDDE1;
`;

const ToolbarIcons = () => {
  return (
    <>
      <StyledToolbarIcons>
        <ToolToolbarIcon name="select">
          <SelectIcon />
        </ToolToolbarIcon>
        <ToolToolbarIcon name="eraser" imageOnly>
          <EraserIcon />
        </ToolToolbarIcon>
        <ToolToolbarIcon name="lasso" imageOnly>
          <LassoIcon />
        </ToolToolbarIcon>
        <ToolToolbarIcon name="background" imageOnly>
          <BackgroundIcon />
        </ToolToolbarIcon>
      </StyledToolbarIcons>
      <StyledToolbarIcons>
        <PanelToolbarIcon name="generate" unselectLayer>
          <GenerateIcon />
        </PanelToolbarIcon>
        <PanelToolbarIcon name="image" unselectLayer>
          <ImportIcon />
        </PanelToolbarIcon>
        <PanelToolbarIcon name="library" unselectLayer>
          <LibraryIcon />
        </PanelToolbarIcon>
        <PanelToolbarIcon name="filter" imageOnly>
          <FiltersIcon />
        </PanelToolbarIcon>
        <PanelToolbarIcon name="text" unselectImage>
          <TextIcon />
        </PanelToolbarIcon>
        <PanelToolbarIcon name="canvas" unselectLayer>
          <CanvasIcon />
        </PanelToolbarIcon>
      </StyledToolbarIcons>
    </>
  );
};

export default ToolbarIcons;
