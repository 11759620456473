import React, { useEffect } from 'react';
import { LoggedOutContent } from '../layouts/LoggedOutLayout';
import BaseLink from '../components/Common/BaseLink';
import styled from 'styled-components';
import EmailForm from '../components/ResetPassword/EmailForm';
import CodeForm from '../components/ResetPassword/CodeForm';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PasswordForm from '../components/ResetPassword/PasswordForm';
import { setStep, resetState } from '../store/resetPassword';

const StyledLoggedOutContent = styled(LoggedOutContent)`
  .back-link {
    width: 400px;
    border-top: 1px solid #DCDDE1;
    padding-top: 8px;
    margin-top: 16px;
  }
`;

const ResetPassword = () => {
  const step = useSelector(({ resetPassword }) => resetPassword.step);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (step === 'success') {
      dispatch(setStep('email'));
      navigate('/login');
    }
  },[step]);

  return(
    <StyledLoggedOutContent>
      <h1>Forgot password</h1>
      {{
        email: <EmailForm />,
        code: <CodeForm />,
        password: <PasswordForm />,
      }[step] }
      <div className="back-link">
        <BaseLink text="Back to Log in screen" to="/login" onClick={() => dispatch(resetState())}/>
      </div>
    </StyledLoggedOutContent>
);};

export default ResetPassword;
