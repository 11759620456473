import React, { Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import moment from "moment";

const Image = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 4px;
  object-fit: cover;
  flex: 0 0 60px;
  cursor: pointer;
`;

const EmptyImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 4px;
  cursor: pointer;
  flex: 0 0 60px;
  background: #F5F5F5;
`;

const Cell = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #7C8394;
  flex: 0 0 ${({ width }) => width}px;
  cursor: pointer;
`;

const Action = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  
  > svg {
    width: 20px;
    height: 20px;
  }
`;

const MainCell = styled.div`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: #3C4049;
  flex: 1;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 10px;
  gap: 16px;
  height: 68px;
  background: #FFFFFF;
  border-radius: 6px;
  
  &.selected {
    border: 1px solid #77B36B;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    
    > ${MainCell} {
      color: #77B36B;
    }
  }
`;

const BaseListRow = ({ item, columns }) => {
  return (
    <Container>
      {
        columns.map((column, index) => (
          <Fragment key={index}>
            {
              column.isImage &&
              <Fragment>
                {
                  item[column.key] &&
                    <Image
                      onClick={column.action ? () => column.action(item) : () => {} }
                      src={process.env.REACT_APP_API_URL + 'images/' + item[column.key] }
                      style={{ objectFit: 'cover' }}
                      alt=""
                    />
                }
                {
                  !item[column.key] &&
                  <EmptyImage
                    onClick={column.action ? () => column.action(item) : () => {} }
                  />
                }
              </Fragment>
            }
            {
              !column.isImage && column.isBase64 &&
              <Fragment>
                {
                  item[column.key] &&
                  <Image
                    onClick={column.action ? () => column.action(item) : () => {} }
                    src={item[column.key] }
                    style={{ objectFit: 'cover' }}
                    alt=""
                  />
                }
                {
                  !item[column.key] &&
                  <EmptyImage
                    onClick={column.action ? () => column.action(item) : () => {} }
                  />
                }
              </Fragment>
            }
            {
              column.isMain &&
              <MainCell
                onClick={column.action ? () => column.action(item) : () => {} }
              >
                { item[column.key] }
              </MainCell>
            }
            {
              !column.isMain && !column.isImage && !column.isBase64 && column.isBoolean &&
              <Cell
                onClick={ column.action ? () => column.action(item.id, item[column.key]) : () => {}}
                width={column.width}
              >
                { item[column.key] ? 'Yes' : 'No' }
              </Cell>
            }
            {
              !column.isMain && !column.isImage && !column.isBase64 && !column.isBoolean && column.isDate &&
              <Cell width={column.width}>
                { item[column.key] ? moment(item[column.key]).format('MM/DD/YYYY') : '---' }
              </Cell>
            }
            {
              !column.isMain && !column.isImage && !column.isBase64 && !column.isBoolean && !column.isDate && column.icon &&
              <Action onClick={() => column.action(item)}>
                { column.icon }
              </Action>
            }
            {
              !column.isMain && !column.isImage && !column.isBase64 && !column.icon && !column.isDate && !column.isBoolean &&
              <Cell width={column.width}>
                { item[column.key] }
              </Cell>
            }
          </Fragment>
        ))
      }
    </Container>
  );
};

BaseListRow.propTypes = {
  item: PropTypes.object,
  columns: PropTypes.array,
};

export default BaseListRow;