import './App.css';
import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import LoggedOutLayout from './layouts/LoggedOutLayout';
import ResetPassword from './pages/ResetPassword';
import Builder from './pages/Builder';
import LoggedInLayout from './layouts/LoggedInLayout';
import LoadingBackdrop from './components/Common/LoadingBackdrop';
import { useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from './pages/ErrorPage';
import Generator from './pages/Generator';
import UpdateAvailableModal from './components/Common/UpdateAvailableModal';
import UpdateDownloadedModal from './components/Common/UpdateDownloadedModal';
import UserAgreementModal from './components/Common/UserAgreementModal';
import AdminLayout from "./layouts/AdminLayout";
import UserList from "./components/Admin/UserList";
import UserForm from "./components/Admin/UserForm";
import UserSection from "./components/Admin/UserSection";
import TemplateSection from "./components/Admin/TemplateSection";
import AssetSection from "./components/Admin/AssetSection";
import TemplateList from "./components/Admin/TemplateList";
import AssetList from "./components/Admin/AssetList";
import TemplateForm from "./components/Admin/TemplateForm";
import AssetForm from "./components/Admin/AssetForm";

const INSTALLED_KEY = 'update-installed';

function App() {
  const loading = useSelector(state => state.loader.loading);
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [updateInstalled, setUpdateInstalled] = useState(false);

  useEffect(() => {
    if (!window.require) return;
    const ipcRenderer = window.require('electron')?.ipcRenderer;

    if (!ipcRenderer) return;
    ipcRenderer.on('update-downloaded', () => {
      setUpdateAvailable(true);
    });
  }, []);

  useEffect(() => {
    if (localStorage.getItem(INSTALLED_KEY))
      setUpdateInstalled(true);
  }, []);

  const handleUpdate = (update) => {
    localStorage.setItem(INSTALLED_KEY, 'true');
    if (update)
      window.require('electron')?.ipcRenderer.send('install-update');
    else
      setUpdateAvailable(false);
  };

  const handleInstalled = () => {
    localStorage.removeItem(INSTALLED_KEY);
    setUpdateInstalled(false);
  };

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <Routes>
        <Route path="/" element={<LoggedInLayout />}>
          <Route index element={<Dashboard />}/>
          <Route path="builder/:projectId" element={<Builder />}/>
        </Route>
        <Route path="/login" element={<LoggedOutLayout />}>
          <Route index element={<Login />}/>
          <Route path="reset" element={<ResetPassword />}/>
        </Route>
        <Route path="/admin" element={<AdminLayout />}>
          <Route path="users" element={<UserSection />}>
            <Route index element={<UserList />}/>
            <Route path=":userId"  element={<UserForm />}/>
          </Route>
          <Route path="templates" element={<TemplateSection />}>
            <Route index element={<TemplateList />}/>
            <Route path=":templateId"  element={<TemplateForm />}/>
          </Route>
          <Route path="assets" element={<AssetSection />}>
            <Route index element={<AssetList />}/>
            <Route path=":assetId"  element={<AssetForm />}/>
          </Route>
        </Route>
        <Route path="/gpt" element={<Generator />}/>
      </Routes>
      {loading !== 0 && <LoadingBackdrop />}
      {updateAvailable && <UpdateAvailableModal update={handleUpdate} />}
      {updateInstalled && <UpdateDownloadedModal close={handleInstalled} />}
      {!updateAvailable && !updateInstalled && <UserAgreementModal />}
    </ErrorBoundary>
  );
}

export default App;
