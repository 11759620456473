import axios from 'axios';
import ModelService from '../base/ModelService';

class ImageEditor extends ModelService {
  constructor() {
    super('remove-background');
    
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_IMAGE_EDITOR_URL,
    });
  }
}

export default new ImageEditor();
