import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CaretLeftIcon } from '../../assets/caret-left.svg';
import { ReactComponent as CaretRightIcon } from '../../assets/caret-right.svg';
import { toggleVisible } from '../../store/layers';
import LayersPanelLayer from './LayersPanelLayer';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { updateLayers } from '../../store/builder';

const Container = styled.div`
  position: fixed;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  right: 0;
  top: 90px;
  background: #FFFFFF;
  width: max-content;
  height: calc(100vh - 90px);
  -webkit-user-select: none;
  user-select: none; 
`;

const Collapser = styled.div`
  position: absolute;
  left: -24px;
  top: 14px;
  background: #FFFFFF;
  border-radius: 4px 0px 0px 4px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
`;

const StyledPanel = styled.div`
  background: #FFFFFF;
  width: 280px;
  height: calc(100vh - 90px);
  padding: 19px 16px;
  -webkit-user-select: none;
  user-select: none; 
  overflow-y: auto;
`;

const Title = styled.div`
  display: flex;
  
  h2 {
    flex: 1;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #7C8394;
    text-transform: uppercase;
    margin: 0 0 11px;
  }
`;

const Layers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const LayersPanel = () => {
  const visible = useSelector(state => state.layers.visible);
  const layers = useSelector(state => state.builder.project.content);
  const dispatch = useDispatch();

  let className = 'slide-out-right';

  if (visible) {
    className = 'slide-in-right';
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = ({ active, over }) => {
    if (over && active.id !== over?.id) {
      const activeIndex = layers.findIndex(({ id }) => id === active.id);
      const overIndex = layers.findIndex(({ id }) => id === over.id);

      dispatch(updateLayers(arrayMove(layers, activeIndex, overIndex)));
    }
  };

  return (
    <Container  className={className}>
      <Collapser onClick={() => dispatch(toggleVisible() )}>
        { visible ?  <CaretRightIcon /> : <CaretLeftIcon /> }
      </Collapser>
      <StyledPanel>
        <Title>
          <h2>Layers</h2>
        </Title>
        <Layers>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={layers}
              strategy={verticalListSortingStrategy}
            >
              {
                [...layers].reverse().map((layer, index) => (
                  <LayersPanelLayer {...layer} key={index}  />
                ))
              }
            </SortableContext>
          </DndContext>
        </Layers>
      </StyledPanel>
    </Container>
  );
};

export default LayersPanel;
