import React, { Fragment, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers, setUserSearch } from "../../store/admin";
import PageHeader from "./PageHeader";

const UserSection = () => {
  const search = useSelector(({ admin }) => admin.user.search);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchUsers());
  }, [search]);

  return (
    <Fragment>
      <PageHeader
        title="Users"
        listPage={location.pathname === '/admin/users'}
        onAddClicked={() => navigate('/admin/users/new')}
        onSearch={(val) => dispatch(setUserSearch(val))}
      />
      <Outlet />
    </Fragment>
  );
};

export default UserSection;