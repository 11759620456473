import React, { Fragment, useEffect, useState } from 'react';
import BaseColorPicker from '../Common/BaseColorPicker';
import styled from 'styled-components';
import BaseSlider from '../Common/BaseSlider';
import { useDispatch, useSelector } from 'react-redux';
import { setEditLayerId, setStrokeColor, setStrokeWidth, toggleStroke } from '../../store/builder';
import BaseTextBox from '../Common/BaseTextBox';
import BaseSwitch from '../Common/BaseSwitch';
import { TEXT_LIMITS } from '../../constants';

const Container = styled.div`
  padding-top: 20px;
`;

const Element = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #3C4049;
  flex: 1;
`;

const Slider = styled(BaseSlider)`
  width: 160px;
  margin-bottom: 16px;
`;

let timeout;

const TextStylePanelStroke = () => {
  const stroke = useSelector(({ builder }) => builder.text.stroke);
  const selectedText = useSelector(({ builder }) => builder.selectedText);
  const editLayerId = useSelector(({ builder }) => builder.editLayerId);
  const [width, setWidth] = useState(0);
  const dispatch = useDispatch();

  const handleWidthChange = (value) => {
    clearTimeout(timeout);
    setWidth(value);
    timeout = setTimeout(() => {
      const valueToSet = `${Math.min(Math.max(+value, TEXT_LIMITS.strokeWidth.min), TEXT_LIMITS.strokeWidth.max)}`;
      if (valueToSet !== value) setWidth(valueToSet);
      dispatch(setStrokeWidth(valueToSet));
    }, 500);
  };

  const handleClick = () => {
    if (!selectedText || editLayerId === selectedText) return;
    dispatch(setEditLayerId(selectedText));
  };

  const handleBlur = () => {
    dispatch(setEditLayerId(false));
  };

  useEffect(() => {
    if(width !== stroke.width) setWidth(stroke.width);
  }, [stroke.width]);

  return (
    <Fragment>
      <BaseSwitch
        label="Stroke"
        checked={stroke.applied}
        toggle={() => dispatch(toggleStroke())}
      />
      {
        stroke.applied &&
          <Container>
            <Element>
              <Slider
                value={stroke.width}
                min={TEXT_LIMITS.strokeWidth.min}
                max={TEXT_LIMITS.strokeWidth.max}
                onChange={(width) => dispatch(setStrokeWidth(width))}
              />
              <BaseTextBox
                type="number"
                min={TEXT_LIMITS.strokeWidth.min}
                max={TEXT_LIMITS.strokeWidth.max}
                width={80}
                onClick={handleClick}
                onBlur={handleBlur}
                onChange={handleWidthChange}
                value={width}
                background={'rgb(245, 245, 245)'}
              />
            </Element>
            <Element>
              <Label>Stroke color</Label>
              <BaseColorPicker
                color={stroke.color}
                setColor={(color) => dispatch(setStrokeColor(color))}
              />
            </Element>
          </Container>
      }
    </Fragment>
  );
};

export default TextStylePanelStroke;
