import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Switch } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 18px;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #3C4049;
`;

const StyledSwitch = muiStyled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    '&:before, &:after': {
      position: 'absolute',
      fontSize: '0.625rem',
      top: 6,
    },
    '&:before': {
      content: '"ON"',
      left: 8,
      color: '#FFFFFF',
    },
    '&:after': {
      content: '"OFF"',
      right: 6,
      color: '#7C8394',
    },
  },
}));

const BaseSwitch = ({ label, checked, toggle }) => {
  return (
    <Container>
      <Label>
        { label }
      </Label>
      <StyledSwitch
        checked={checked}
        onChange={toggle}
      />
    </Container>
  );
};

BaseSwitch.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  toggle: PropTypes.func,
};

export default BaseSwitch;
