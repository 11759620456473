import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import BaseTextArea from '../Common/BaseTextArea';
import BaseButton from '../Common/BaseButton';
import { ReactComponent as GenerateIcon } from '../../assets/toolbar/generate.svg';
import ImageImporter from './ImageImporter';
import BaseProcessingWait from '../Common/BaseProcessingWait';
import { useDispatch, useSelector } from 'react-redux';
import { generateImages, setPrompt, setVariations, toggleWaitOpened } from '../../store/imageGenerator';
import BaseSlider from '../Common/BaseSlider';
import LimitGenerationModal from './LimitGenerationModal';
import { IMAGE_KEY } from '../../constants';
import { fetchUser, setCreditsUpdated, setLimitGenerationModal, setUsedImageCredits } from '../../store/user';

const Container = styled.div`
  padding: 16px;
  
  button {
    width: 100% !important;
  }
`;

const Slider = styled(BaseSlider)`
  margin: 10px 0 20px;
`;

const GeneratorNew = () => {
  const user = useSelector(state => state.user);
  const waitOpened = useSelector(state => state.imageGenerator.waitOpened);
  const prompt = useSelector(state => state.imageGenerator.prompt);
  const referenceImage = useSelector(state => state.imageGenerator.referenceImage);
  const variations = useSelector(state => state.imageGenerator.variations);
  const [referenceImageOpened, setReferenceImageOpened] = useState(false);
  const dispatch = useDispatch();

  const handleGenerate = async () => {
    if (variations > (user.imageCredits - user.usedImageCredits))
      return dispatch(fetchUser(true));

    dispatch(generateImages());
    dispatch(setUsedImageCredits(user.usedImageCredits + variations));
  };

  useEffect(()=> {
    if(user.creditsUpdated) {
      if (variations > (user.imageCredits - user.usedImageCredits)) {
        dispatch(setLimitGenerationModal(IMAGE_KEY));
      } else {
        dispatch(generateImages());
        dispatch(setUsedImageCredits(user.usedImageCredits + variations));
      }
      dispatch(setCreditsUpdated(false));
    }
  }, [user.creditsUpdated]);

  useEffect(() => {
    if(!variations) dispatch(setVariations(user.generateImagesCount));
  }, []);

  return (
    <Fragment>
      <Container>
        { !referenceImage.confirmed && <BaseTextArea
          placeholder="Tell Articus what to create. (i.e. a giant tree in 8k with green leaves"
          onChange={(e) => dispatch(setPrompt(e.target.value))}
        /> }
        <Slider
          value={variations}
          onChange={(v) => dispatch(setVariations(v))}
          label="Variations"
          name="image"
          defaultValue={1}
          min={1}
          max={10}
        />
        <BaseButton
          disabled={!prompt && !referenceImage.confirmed}
          onClick={handleGenerate}
        >
          Generate <GenerateIcon stroke="#fff" />
        </BaseButton>
        <ImageImporter
          open={referenceImageOpened}
          handleClose={() => setReferenceImageOpened(false) }
        />
      </Container>
      <BaseProcessingWait
        title="Generating images"
        prompt={prompt}
        opened={waitOpened}
        toggleOpened={() => dispatch(toggleWaitOpened())}
      />
      <LimitGenerationModal type={IMAGE_KEY} />
    </Fragment>
  );
};

export default GeneratorNew;
