import React, { useEffect } from 'react';
import styled from 'styled-components';
import GeneratorHistoryBox from './GeneratorHistoryBox';
import { useDispatch, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { fetchGeneratorHistory, fetchGeneratorHistoryNextPage } from '../../store/imageGenerator';
import GeneratorPreview from './GeneratorPreview';
import Loader from '../Common/Loader';

const StyledGeneratorHistory = styled.div`
  height: calc(100vh - 117px);
  overflow-y: auto;
  padding: 16px;
`;

const GeneratedImagesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: #3C4049;
  
  div:last-child {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #7C8394;
  }
`;

const GeneratorNew = () => {
  const generatorHistory = useSelector(state => state.imageGenerator.history);
  const pagination = useSelector(state => state.imageGenerator.pagination);
  const dispatch = useDispatch();

  const { ref } = useInView({
    onChange: (inView) => {
      if (inView && !pagination.loading) {
        dispatch(fetchGeneratorHistoryNextPage());
      }
    },
  });

  useEffect(() => {
    dispatch(fetchGeneratorHistory());
  }, []);

  return (
    <StyledGeneratorHistory>
      <GeneratedImagesHeader>
        <div>Generated images</div>
        <div>
          { generatorHistory.length } result{ generatorHistory.length === 1 ? '' : 's'}
        </div>
      </GeneratedImagesHeader>
      {
        generatorHistory.map((item, index) => (
          <GeneratorHistoryBox
            key={index}
            item={item}
            prompt={item.prompt}
            images={item.generatedImages}
          />
        ))
      }
      {!!generatorHistory.length && !pagination.isLastPage && 
          <Loader ref={ref} />
      }
      <GeneratorPreview />
    </StyledGeneratorHistory>
  );
};

export default GeneratorNew;
