import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import BaseTextBox from "../Common/BaseTextBox";
import { SimpleButton } from "../Common/BaseButton";

const Section = styled.div`
  position: fixed;
  width: calc(100vw - 100px);
  background-color: #F5F5F5;
  margin-top: -20px;
  padding-top: 20px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: #3C4049;
`;

const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Search = styled.div`
  
`;

const PageHeader = ({ title, onAddClicked, listPage, onSearch, children }) => {
  return (
    <Section>
      <Container>
        <Title>
          { title }
        </Title>
        { listPage &&
          <SimpleButton
            onClick={onAddClicked}
          >
            + Add
          </SimpleButton>
        }
      </Container>
      <Filters>
        { children }
        { listPage &&
          <Search>
            <BaseTextBox
              placeholder="Search..."
              width={250}
              bottom={0}
              onChange={onSearch}
            />
          </Search>
        }
      </Filters>
    </Section>
  );
};

PageHeader.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  onAddClicked: PropTypes.func,
  listPage: PropTypes.bool,
  onSearch: PropTypes.func,
};

export default PageHeader;