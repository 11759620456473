import BaseService from './BaseService';
import config from '../Config';
import { store } from '../../store';
import { decreaseLoading, increaseLoading } from '../../store/loader';

class ReadOnlyService extends BaseService {
  constructor(resource) {
    super(resource);

    this.axios = config.axios;
  }

  async fetch(params = {}, suffix = '', withLoading = true) {
    if(withLoading) store.dispatch(increaseLoading());

    try {
      const { data } = await this.axios.get(
        this.getUrl(suffix),
        {
          headers: this.getHeaders(),
          params: params.length === 0 ? {} : { ...params },
        },
      );

      return data;
    } catch (err) {
      return this.handleErrors(err);
    } finally {
      if(withLoading) store.dispatch(decreaseLoading());
    }
  }

  async fetchWithoutAnimation(params = {}, suffix = '') {
    try {
      const { data } = await this.axios.get(
        this.getUrl(suffix),
        {
          headers: this.getHeaders(),
          params: params.length === 0 ? {} : { ...params },
        },
      );

      return data;
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async get(id) {
    if (!id) {
      return this.handleErrors('Id is not provided');
    }

    store.dispatch(increaseLoading());

    try {
      const { data } = await this.axios.get(
        this.getUrl(id),
        {
          headers: this.getHeaders(),
        },
      );
      return data;
    } catch (err) {
      return this.handleErrors(err);
    } finally {
      store.dispatch(decreaseLoading());
    }
  }
}

export default ReadOnlyService;
