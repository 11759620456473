import React from 'react';
import styled from 'styled-components'; 
import { useDispatch, useSelector } from 'react-redux';
import { emptyBackgroundPreview, previewImageBackground, removeBackground } from '../../store/builder';
import BaseButton, { CancelButton, WhiteButton } from '../Common/BaseButton';

const Container = styled.div`
  width: min-content;
  display: flex;
  position: fixed;
  z-index: 99;
  right: 16px;
  bottom: 16px;
  align-items: center;
  justify-content: center;    
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
  > div:first-child {
    border-right: 1px solid #DCDDE1;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
`;

const RemoveBackground = () => {
    const dispatch = useDispatch();
    const selectedLayer = useSelector(state => state.builder.selectedLayer);
    const imageBackgroundPreview = useSelector(state => state.builder.imageBackgroundPreview);

    return (
      <Container>
        <ButtonWrap>
          {imageBackgroundPreview ? 
            <CancelButton onClick={() => dispatch(emptyBackgroundPreview())} /> :
            <WhiteButton 
              disabled={!selectedLayer.id} 
              onClick={() => dispatch(previewImageBackground())}
              rightIcon={false}
            >
              Detect background
            </WhiteButton>}
        </ButtonWrap>
        <ButtonWrap>
          <BaseButton disabled={!selectedLayer.id} onClick={() => dispatch(removeBackground())}>
            Remove background
          </BaseButton>
        </ButtonWrap>
      </Container>
    );
};

export default RemoveBackground;