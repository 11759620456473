import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from '../../store/builder';

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 2px solid #D9D9D9;
  height: 78px;
`;

const Name = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-align: center;
  color: #7C8394;
`;

const Container = styled.div`
  width: 76px;
  height: 100px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5px;
  
  &:hover, &.selected {
    ${Icon} {
      border-color: #77B36B;
    }
    
    ${Name} {
      color: #1A1C23;
    }
  }
`;

const FilterPanelItem = ({ name, parameters, icon }) => {
  const selectedLayer = useSelector(state => state.builder.selectedLayer);
  const dispatch = useDispatch();

  const isSelected = selectedLayer?.meta?.filter?.name === name;

  const applyFilter = () => {
    if (isSelected)
      return  dispatch(setFilter({ name: null }));

    const filterToSet = { name, parameters };
    if(parameters?.length) 
      for(const parameter of parameters) {
        filterToSet[parameter.key] = selectedLayer?.meta?.filter?.[parameter.key] ?? (parameter.min + parameter.max) / 2;
      }
    dispatch(
      setFilter(filterToSet),
    );
  };

  return (
    <Container
      className={isSelected ? 'selected' : ''}
      onClick={applyFilter}
    >
      <Icon>
        {icon}
      </Icon>
      <Name>
        { name }
      </Name>
    </Container>
  );
};

FilterPanelItem.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.object,
  parameters: PropTypes.array,
};

export default FilterPanelItem;
