import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api';

export const fetchEULA = createAsyncThunk(
  'user/fetchEULA',
  async (version) => {
    return api.userAgreement.fetch({ version });
  },
);

export const acceptEULA = createAsyncThunk(
  'user/acceptEULA',
  async (arg, { getState }) => {
    const { user: { userAgreement: { id } } } = getState();

    return api.userAgreement.fetch({} , `${id}/accept`);
  },
);

export const user = createSlice({
  name: 'user',
  initialState: {
    id: null,
    name: null,
    email: null,
    avatar: null,
    plan: null,
    imageCredits: null,
    usedImageCredits: null,
    wordCredits: null,
    usedWordCredits: null,
    token: null,
    loginError: null,
    changePasswordError: null,
    changePasswordModal: null,
    changeProfileError: null,
    changeProfileModal: null,
    acceptedEULA: null,
    generateImagesCount: null,
    userAgreement: {
      content: null,
      id: null,
    },
    limitGenerationModal: null,
    creditsUpdated: null, 
  },
  reducers: {
    setUser(state, { payload }) {
      const {
        id,
        name,
        email,
        avatar,
        acceptedEULA,
        plan,
        subscriptionEnd,
        imageCredits,
        usedImageCredits,
        wordCredits,
        usedWordCredits,
        generateImagesCount,
      } = payload;

      state.id = id;
      state.name = name;
      state.email = email;
      state.avatar = avatar;
      state.acceptedEULA = acceptedEULA;
      state.imageCredits = imageCredits;
      state.usedImageCredits = usedImageCredits;
      state.wordCredits = wordCredits;
      state.usedWordCredits = usedWordCredits;
      state.generateImagesCount = generateImagesCount;
      if (Date.now() < new Date(subscriptionEnd).getTime())
        state.plan = plan;
    },
    setToken(state, { payload }) {
      state.token = payload.token;
    },
    setLoginError(state, { payload }) {
      state.loginError = payload.loginError;
    },
    setChangePasswordError(state, { payload }) {
      state.changePasswordError = payload;
    },
    setChangePasswordModal(state, { payload }) {
      state.changePasswordModal = payload;
    },
    setChangeProfileError(state, { payload }) {
      state.changeProfileError = payload;
    },
    setChangeProfileModal(state, { payload }) {
      state.changeProfileModal = payload;
    },
    setLimitGenerationModal(state, { payload }) {
      state.limitGenerationModal = payload;
    },
    setUsedImageCredits(state, { payload }) {
      state.usedImageCredits = payload;
    },
    setUsedWordCredits(state, { payload }) {
      state.usedWordCredits = payload;
    },
    setCreditsUpdated(state, { payload }) {
      state.creditsUpdated = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEULA.fulfilled, (state, { payload: { id, content } }) => {
      state.userAgreement = { id, content };
    });

    builder.addCase(acceptEULA.fulfilled, (state, { payload: { version } }) => {
      state.userAgreement = {
        content: null,
        id: null,
      };
      state.acceptedEULA = [...state.acceptedEULA, version];
    });
  },
});

export const authenticate = ({ email, password, rememberMe }) => async (dispatch) => {
  dispatch(setLoginError({
    loginError: null,
  }));

  const { data, error } = await api.login.login({
    email,
    password,
    _remember_me: rememberMe,
  });

  if (error) {
    dispatch(setLoginError({
      loginError: error === 'Invalid credentials.' ? 'Incorrect Email/Password Combination': error,
    }));
  }

  dispatch(setToken(data));
};

export const changePassword = ({ oldPassword, newPassword }) => async (dispatch) => {
  dispatch(setChangePasswordError(null));
  
  const data = await api.user.patch('password', {
    oldPassword, 
    newPassword,
  });

  if (!data)
    return dispatch(setChangePasswordError('Incorrect password'));

  dispatch(setChangePasswordModal());
};

export const changeProfile = ({ name, email, avatar }) => async (dispatch) => {
  dispatch(setChangeProfileError(null));
  
  const data = await api.user.patch('profile', {
    name, 
    email,
    avatar,
  });

  if (!data)
    return dispatch(setChangeProfileError('Unable to update profile'));

  dispatch(setUser(data.user));
  dispatch(setToken({ token: data.token } ));

  dispatch(setChangeProfileModal(null));
};

export const logout = () => async (dispatch) => {
  dispatch(setToken({
    token: null,
  }));

  dispatch(setUser({
    id: null,
    name: null,
    email: null,
    avatar: null,
  }));

  if (window.require) {
    const ipcRenderer = window.require('electron')?.ipcRenderer;
    ipcRenderer.removeAllListeners();
  }
};

export const fetchUser = (checkCredits = false) => async (dispatch) => {
  const data = await api.user.fetch();

  if(data) dispatch(setUser(data));
  if(checkCredits) dispatch(setCreditsUpdated(true));
};

export const { 
  setToken, 
  setUser, 
  setLoginError,
  setChangePasswordError,
  setChangePasswordModal, 
  setChangeProfileError,
  setChangeProfileModal, 
  setLimitGenerationModal,
  setUsedImageCredits,
  setUsedWordCredits,
  setCreditsUpdated,
} = user.actions;

export default user.reducer;
