import { Dialog, DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import BaseButton from './BaseButton';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as Logo } from "../../assets/logo-square.svg";

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    width: 600px;
    text-align: center;
    padding-bottom: 10px;
    .MuiDialogActions-root {
      justify-content: center;
    }
  }
`;

const ContentContainer = styled.div`
  padding: 15px 25px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  color: #1A1C23;
  margin-bottom: 25px;
`;

const UpdateDownloadedModal = ({ close }) => {
  return ( 
    <StyledDialog
      open
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <ContentContainer>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <Title>
            Articus is Updated!
          </Title>
        </ContentContainer>
      </DialogContent>
      <DialogActions>
        <BaseButton
          width={120}
          onClick={close}
        >
          {'Ok, let\'s go!'}
        </BaseButton>
      </DialogActions>
    </StyledDialog>
  );
};

UpdateDownloadedModal.propTypes = {
  close: PropTypes.func,
};

export default UpdateDownloadedModal;
