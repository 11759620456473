import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #77B36B;
`;

function BaseLink({ text, to, onClick }) {
  return (
    <StyledLink to={to} onClick={onClick && onClick}>
      {text}
    </StyledLink>
  );
}

BaseLink.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

export default BaseLink;
