import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { fetchProjects, setOrderBy } from "../../store/project";
import { ReactComponent as CaretDown } from "../../assets/caret-down.svg";

const Container = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #7C8394;
  cursor: pointer;
  flex: 0 0 ${({ width }) => width}px;
`;

const CaretContainer = styled.div`
  &.up {
    transform: rotate(180deg);
  }
  
  > svg {
    stroke: #7C8394;
  }
`;

const ProjectsListHeaderColumn = ({ children, column, width = 120 }) => {
  const orderBy = useSelector(state => state.project.orderBy);
  const dispatch = useDispatch();

  const changeOrderBy = () => {
    dispatch(setOrderBy(column));
    dispatch(fetchProjects());
  };

  return (
    <Container
      onClick={changeOrderBy}
      width={width}
    >
      {children}
      { orderBy.field === column &&
        <CaretContainer
          className={ orderBy.direction === 'desc' ? 'down' : 'up' }
        >
          <CaretDown />
        </CaretContainer>
      }
    </Container>
  );
};

ProjectsListHeaderColumn.propTypes = {
  children: PropTypes.any,
  column: PropTypes.string,
  width: PropTypes.number,
};

export default ProjectsListHeaderColumn;