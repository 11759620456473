import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BaseButton from './BaseButton';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { acceptEULA, fetchEULA } from '../../store/user';

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    padding-bottom: 10px;
    min-width: 500px;
    .MuiDialogTitle-root {
      text-align: center;
    }
    .MuiDialogActions-root {
      justify-content: center;
    }
  }
`;

const UserAgreementModal = () => {
  const userAgreement = useSelector(({ user }) => user.userAgreement);
  const userId = useSelector(({ user }) => user.id);
  const acceptedEULA = useSelector(({ user }) => user.acceptedEULA);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!userId || !window.require) return;
    const ipcRenderer = window.require('electron')?.ipcRenderer;

    ipcRenderer.send('getAppVersion');

    const checkEula = (evt, version) => {
      version = version.toString();

      if (acceptedEULA === null || !acceptedEULA.includes(version.toString())) {
        dispatch(fetchEULA(version));
      }
    };
    
    ipcRenderer.on('getAppVersion', checkEula);
  }, [userId]);

  useEffect(() => {
    if (userAgreement.content) setShow(true);
  }, [userAgreement]);

  const handleClose = () => {
    dispatch(acceptEULA());
    setShow(false);
  };
  
  if (!show) return;

  return ( 
    <StyledDialog
      open
      onClose={() => {}}
    >
      <DialogTitle>
        {'End User License Agreement'}
      </DialogTitle>
      <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: userAgreement.content }} />
      </DialogContent>
      <DialogActions>
        <BaseButton
          width={150}
          onClick={handleClose}
        >
          Accept
        </BaseButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default UserAgreementModal;
