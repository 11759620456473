import React, { useRef } from 'react';
import BaseTextBox from '../Common/BaseTextBox';
import { SimpleButton } from '../Common/BaseButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { forgetPassword } from '../../store/resetPassword';
import FormError from '../Common/FormError';

const validationSchema = yup.object({
    email: yup.string().email('Not valid email').required('Required'),
});

const EmailForm = () => {
    const error = useSelector(({ resetPassword }) => resetPassword.emailError);
    const dispatch = useDispatch();
    const emailRef = useRef();
    const submitRef = useRef();
    const formik = useFormik({
        initialValues: {
        email: '',
        },
        onSubmit: values => {
        dispatch(forgetPassword(values));
        formik.handleReset();
        },
        validationSchema,
    });
    return (
        <>
            <h2>Enter the email address that you registered with and we&apos;ll send you a code to reset your password.</h2>
            {error?.length &&
                <FormError text={error}/>
            }
            <BaseTextBox
                autoFocus
                ref={emailRef}
                label="Email"
                placeholder="Enter email address"
                background="transparent"
                inputBackground="#fff"
                name="email"
                value={formik.values.email}
                errorText={formik.touched.email && formik.errors.email}
                onChangeFormik={formik.handleChange}
                onFormikBlur={formik.handleBlur}
                onEnterClick={formik.submitForm}
                onTabClick={() => {
                    if(formik.isValid && formik.dirty) 
                        submitRef.current.focus();
                    else {
                        emailRef.current.blur();
                        emailRef.current.focus();
                    }
                }}
            />
            <SimpleButton 
                ref={submitRef}
                width={400} 
                disabled={formik.touched.email && !formik.isValid} 
                onClick={formik.submitForm}
                onTabClick={() => emailRef.current.focus()}
            >
                Send code
            </SimpleButton>
        </>
        );
};

export default EmailForm;