import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { emptySelectedLayer, setPanel, setTool, toggleGenerateTextOpened } from '../../store/builder';
import { Tooltip } from '@mui/material';
import { clearTextGeneratorState } from "../../store/textGenerator";
import { setSelectedTab } from "../../store/imageGenerator";

export const StyledToolbarIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 6px;
  
  svg {
    stroke: #1A1C23;
  }
  
  &:hover {
    background-color: #DCDDE1;
  }
  
  &.selected {
    background-color: #77B36B;
    
    svg {
      stroke: #FFFFFF;
    }
  }

  &.disabled {
    cursor: not-allowed;
    &:hover {
      background-color: transparent;
    }

    svg {
      stroke: rgb(220, 221, 225);
    }
  }
`;

export const PanelToolbarIcon = ({ children, name, imageOnly, unselectImage = false, unselectLayer = false }) => {
  const panel = useSelector(state => state.builder.panel);
  const selectedLayer = useSelector(state => state.builder.selectedLayer);
  const dispatch = useDispatch();

  const handleSelect = () => {
    if (unselectLayer) {
      dispatch(emptySelectedLayer());
    }

    if (unselectImage && selectedLayer.meta?.type === 'image') {
      dispatch(emptySelectedLayer());
    }

    if (name === 'text' && panel === name) {
      dispatch(clearTextGeneratorState());
      dispatch(toggleGenerateTextOpened(false));
    }

    if (name === 'generate' && panel === name) {
      dispatch(setSelectedTab('new'));
    }

    dispatch(setPanel(name));
  };

  return (
    <BaseToolbarIcon
      title={name.charAt(0).toUpperCase() + name.slice(1)}
      onSelect={handleSelect}
      selected={panel === name}
      disabled={imageOnly && selectedLayer.meta?.type !== 'image'}
    >
      { children }
    </BaseToolbarIcon>
  );
};

PanelToolbarIcon.propTypes = {
  children: PropTypes.any,
  name: PropTypes.string,
  imageOnly: PropTypes.bool,
  unselectLayer: PropTypes.bool,
  unselectImage: PropTypes.bool,
};

export const ToolToolbarIcon = ({ children, name, imageOnly }) => {
  const tool = useSelector(state => state.builder.tool);
  const selectedLayer = useSelector(state => state.builder.selectedLayer);
  const dispatch = useDispatch();

  return (
    <BaseToolbarIcon
      title={name.charAt(0).toUpperCase() + name.slice(1)}
      onSelect={() => dispatch(setTool(name))}
      selected={tool === name}
      disabled={imageOnly && selectedLayer.meta?.type !== 'image'}
    >
      { children }
    </BaseToolbarIcon>
  );
};

ToolToolbarIcon.propTypes = {
  children: PropTypes.any,
  name: PropTypes.string,
  imageOnly: PropTypes.bool,
};

const BaseToolbarIcon = ({ children, title, selected, onSelect, disabled }) => {
  return (
    <Tooltip arrow title={!disabled && title}>
      <StyledToolbarIcon 
        className={`${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`} 
        onClick={disabled ? undefined : onSelect}
        disabled={disabled}
      >
        { children }
      </StyledToolbarIcon>
    </Tooltip>
  );
};

BaseToolbarIcon.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
};

export default BaseToolbarIcon;
