import axios from 'axios';

class Config {
  constructor() {
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
  }
}

export default new Config();
