import React from 'react';
import styled from 'styled-components';
import { Dialog, DialogActions, LinearProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as GeneratorIcon } from '../../assets/generator.svg';
import { CancelButton } from './BaseButton';

const StyledProcessingWait = styled(Dialog)`
  .MuiPaper-root {
    width: 320px;
    height: 350px;
    padding: 26px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0 29px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.4375rem;
  text-align: center;
  color: #77B36B;
  margin-bottom: 24px;
`;

const Progress = styled.div`

`;

const Body = styled.p`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #3C4049;
  margin-top: 24px;
`;

const Actions = styled(DialogActions)`
  display: flex;
  justify-content: center !important;
`;

const BaseProcessingWait = ({ title, prompt, opened, toggleOpened }) => {
  return (
    <StyledProcessingWait
      open={opened}
    >
      <Header>
        <GeneratorIcon />
      </Header>
      <Title>
        { title }
      </Title>
      <Progress>
        <LinearProgress color="success" />
      </Progress>
      <Body>
        { prompt }
      </Body>
      <Actions>
        <CancelButton onClick={toggleOpened} />
      </Actions>
    </StyledProcessingWait>
  );
};

BaseProcessingWait.propTypes = {
  opened: PropTypes.bool,
  prompt: PropTypes.string,
  title: PropTypes.string,
  toggleOpened: PropTypes.func,
};

export default BaseProcessingWait;
