import ModelService from '../base/ModelService';

class ImageGenerator extends ModelService {
  constructor() {
    super('image-generator');
  }

  async generate(project, prompt, variations, referenceImage) {
    return await this.post({
      project,
      prompt,
      variations,
      referenceImage,
    }, '', false);
  }
}

export default new ImageGenerator();
