import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as DashboardIcon } from '../../assets/toolbar/dashboard.svg';
import { HoverButton } from '../Common/BaseButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationPopup from '../Common/ConfirmationPopup';
import { clearBuilder, setSaveRedirect, setSaveRequested } from '../../store/builder';
import { clearImageGenerator } from "../../store/imageGenerator";
import { clearImageImporter } from "../../store/imageImporter";

const StyledToolbarDashboard = styled.div`
  display: flex;
   justify-content: center;
   align-items: center;
   border-right: 1px solid #DCDDE1;
   width: 129px;
`;

const ToolbarDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const events = useSelector(({ builder }) => builder.events);
  const [open, setOpen] = useState(false);

  const handleDashboard = () => {
    if (events.length) {
      setOpen(true);
    } else {
      dispatch(clearImageImporter());
      dispatch(clearImageGenerator());
      dispatch(clearBuilder());
      navigate('/');
    }
  };

  const handleConfirm = async () => {
    await dispatch(setSaveRedirect(true));
    await dispatch(setSaveRequested(true));
    setOpen(false);

    dispatch(clearImageImporter());
    dispatch(clearImageGenerator());
    dispatch(clearBuilder());
  };

  const handleCancel = () => {
    dispatch(clearImageImporter());
    dispatch(clearImageGenerator());
    dispatch(clearBuilder());
    navigate('/');
  };
  
  return (
    <StyledToolbarDashboard>
      <HoverButton width={119} onClick={handleDashboard}>
        <DashboardIcon />
        Dashboard
      </HoverButton>
      <ConfirmationPopup 
        open={open}
        cancel={handleCancel}
        confirm={handleConfirm}
        type='warning'
        icon='save'
        title='Save changes?' 
        description='You are leaving the project without saving your changes.'
        confirmText='Save project'
        cancelText='Discard changes'
      />
    </StyledToolbarDashboard>
  );
};

export default ToolbarDashboard;
