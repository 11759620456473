import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as RedoIcon } from '../../assets/toolbar/redo.svg';
import { ReactComponent as UndoIcon } from '../../assets/toolbar/undo.svg';
import { HoverButton } from '../Common/BaseButton';
import { useDispatch, useSelector } from 'react-redux';
import { redo, undo } from '../../store/builder';
import BaseToolbarIcon from '../Common/BaseToolbarIcon';

const StyledToolbarEdit = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 90px;
  border-left: 1px solid #DCDDE1;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  
  ${ HoverButton } svg {
    width: 18px;
    height: 18px;
  }
`;

const ToolbarEdit = () => {
  const events = useSelector(({ builder }) => builder.events);
  const eventsIndex = useSelector(({ builder }) => builder.eventsIndex);
  const editLayerId = useSelector(({ builder }) => builder.editLayerId);
  const dispatch = useDispatch();

  const allowRedo = events.length && eventsIndex < events.length;
  const allowUndo = events.length && events.length > 0 && eventsIndex > 0;

  const handleUndo = () => {
    dispatch(undo());
  };

  const handleRedo = () => {
    dispatch(redo());
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode == 90 && (e.ctrlKey || e.metaKey) && !e.shiftKey) handleUndo();
    };

    if (!editLayerId && allowUndo)
      window.addEventListener('keydown', handleKeyDown);
    else
      window.removeEventListener('keydown', handleKeyDown);
    
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [editLayerId, allowUndo]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey && e.keyCode == 89) || (e.keyCode == 90 && e.metaKey && e.shiftKey)) handleRedo();
    };
    
    if (!editLayerId && allowRedo)
      window.addEventListener('keydown', handleKeyDown);
    else
      window.removeEventListener('keydown', handleKeyDown);
    
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [editLayerId, allowRedo]);

  return (
    <StyledToolbarEdit>
      <BaseToolbarIcon
        title="Undo"
        onSelect={handleUndo}
        width={40}
        height={40}
        disabled={!allowUndo}
      >
        <UndoIcon stroke={allowUndo ? '#1A1C23' : '#dcdde1'}/>
      </BaseToolbarIcon>
      <BaseToolbarIcon
        title="Redo"
        onSelect={handleRedo}
        width={40}
        height={40}
        disabled={!allowRedo}
      >
        <RedoIcon stroke={allowRedo ? '#1A1C23' : '#dcdde1'}/>
      </BaseToolbarIcon>
    </StyledToolbarEdit>
  );
};

export default ToolbarEdit;
