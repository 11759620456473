import React, { Fragment, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  clearEditedUser,
  setEditedUserEmail,
  setEditedUserName,
  setEditedUserNotes,
  setEditedUserPlan,
  updateUser,
} from "../../store/admin";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../../store/admin";
import { BackButton, SimpleButton } from "../Common/BaseButton";
import BaseTextBox from "../Common/BaseTextBox";
import BaseSelect from "../Common/BaseSelect";
import BaseTextArea from "../Common/BaseTextArea";
import UserFormDeletedProjects from "./UserFormDeletedProjects";

const Container = styled.div`
  margin-bottom: 40px;
  padding-top: 150px;
  display: flex;
  align-items: flex-start;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

const Image = styled.img`
  width: 150px;
  cursor: pointer;
`;

const EmptyImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  cursor: pointer;
  background: rgb(119, 179, 107);
`;

const Field = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  justify-content: flex-end;
`;

const Value = styled(Label)`
  display: flex;
  align-items: center;
  width: 200px;
  justify-content: flex-start;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const UserForm = () => {
  const {
    id,
    name,
    email,
    avatar,
    lastLoggedInAt,
    plan,
    subscriptionEnd,
    installedVersion,
    notes,
  } = useSelector(({ admin }) => admin.user.edited);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId } = useParams();

  useEffect(() => {
    if (isNaN(userId)) {
      dispatch(clearEditedUser());
    } else {
      dispatch(fetchUser(userId));
    }
  }, []);

  const back = () => {
    navigate('/admin/users');
  };

  const save = async () => {
    await dispatch(updateUser({ id, name, email, plan, notes }));
    navigate('/admin/users');
  };

  return (
    <Fragment>
      <Container>
        <ImageContainer>
          { avatar &&
            <Image
              src={process.env.REACT_APP_API_URL + 'images/' + avatar}
              alt=""
            />
          }
          { !avatar &&
            <EmptyImage />
          }
        </ImageContainer>
        <div>
          <Field>
            <Label>
              Name:
            </Label>
            <BaseTextBox
              width={600}
              bottom={0}
              value={name}
              onChange={(val) => dispatch(setEditedUserName(val))}
            />
          </Field>
          <Field>
            <Label>
              Email:
            </Label>
            <Value>
              <BaseTextBox
                width={600}
                bottom={0}
                value={email}
                onChange={(val) => dispatch(setEditedUserEmail(val))}
              />
            </Value>
          </Field>
          <Field>
            <Label>
              Plan:
            </Label>
            <BaseSelect
              width={600}
              bottom={0}
              value={plan}
              options={[{ id: 'Business', name: 'Business' }, { id: 'Standard', name: 'Standard' }]}
              onChange={(val) => dispatch(setEditedUserPlan(val))}
            />
          </Field>
          <Field>
            <Label>
              Installed Version:
            </Label>
            <Value>
              { installedVersion }
            </Value>
          </Field>
          <Field>
            <Label>
              Last Sign in:
            </Label>
            <Value>
              { lastLoggedInAt === null ? 'Never' : lastLoggedInAt }
            </Value>
          </Field>
          <Field>
            <Label>
              Subscription End:
            </Label>
            <Value>
              { subscriptionEnd === null ? 'Lifetime' : subscriptionEnd }
            </Value>
          </Field>
          <Field>
            <Label>
              Notes:
            </Label>
            <Value>
              <BaseTextArea
                placeholder="Add notes about this user"
                onChange={(e) => dispatch(setEditedUserNotes(e.target.value))}
                width={600}
                bottom={0}
                value={notes}
              />
            </Value>
          </Field>
          <Actions>
            <BackButton
              onClick={back}
            />
            <SimpleButton
              onClick={save}
            >
              Save
            </SimpleButton>
          </Actions>
        </div>
      </Container>
      <UserFormDeletedProjects />
    </Fragment>
  );
};

export default UserForm;