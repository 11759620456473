import React, { forwardRef } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: ${({ bottom }) => bottom}px;
  background-color: ${({ background }) => background};

  input {
    background: #FFF;
    border-radius: 4px;
    padding-top: 0;
    padding-bottom: 0;
    padding: 0 ${({ before }) => before ? '4px' : '14px'};
    font-size: 0.875rem;
    height: ${({ height }) => height}px;
    background-color: ${({ background }) => background};
  }
  
  .MuiInputBase-root {
    padding:  0 ${({ before }) => before ? '8px' : 0};
    
    &::before {
      content: '${({ before }) => before}';
      color: #989FAD;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }

  }
  .MuiFormHelperText-root {
    color: #f37171;
  }
  .MuiOutlinedInput-notchedOutline{
    border: 1px solid ${({ borderColor }) => borderColor ? borderColor : '#DCDDE1'};
  }
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #000000;
  margin-bottom: 4px;
  background-color: #FFF;
`;

const ErrorText = styled(Label)`
  margin-bottom: calc(-0.875rem - 4px);
  margin-top: 4px;
  color: #f37171; 
  font-weight: 400;
`;

const BaseTextBox = forwardRef(function BaseTextBox({
  label,
  placeholder,
  value,
  width = 400,
  height = 40,
  bottom = 16,
  before= '',
  background = '#FFF',
  color = 'primary',
  borderColor,
  inputBackground,
  disabled= false,
  onChange,
  onChangeFormik,
  onEnterClick,
  onTabClick,
  onBlur,
  onFormikBlur,
  errorText,
  error,
  icon,
  ...rest
}, ref) {
  const style = {
    width: parseInt(width) + 'px',
    height: parseInt(height) + (errorText?.length ? 20 : 0) + 'px',
  };

  return (
    <Container
      height={height}
      bottom={bottom}
      before={before}
      background={background}
      borderColor={borderColor}
    >
      { label && <Label style={{ backgroundColor: background }}>{ label }</Label>}
      <TextField
        { ...rest }
        value={value}
        disabled={disabled}
        placeholder={placeholder ? placeholder : label}
        variant="outlined"
        style={{ ...style, backgroundColor: background }}
        onChange={(e) => onChangeFormik ? onChangeFormik(e) : onChange(e.target.value)}
        onKeyPress= {(e) => {
          if (onEnterClick && e.key === 'Enter') {
            onEnterClick();
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Tab') {
            e.preventDefault();
            e.stopPropagation();
            if (onTabClick) onTabClick();
          }
        }}
        helperText={errorText}
        inputRef={ref}
        inputProps={{
          onBlur: onFormikBlur ? onFormikBlur : () => onBlur && onBlur(),
          style: inputBackground ? { backgroundColor: inputBackground } : null,
        }}
        InputProps={{
          startAdornment: icon ? (
            <InputAdornment position="end">
              {icon}
            </InputAdornment>
          ) : null,
        }}
        color={color}
      />
      { error && <ErrorText style={{ backgroundColor: background }}>{ error }</ErrorText>}
    </Container>
  );
});

BaseTextBox.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.number,
  bottom: PropTypes.number,
  before: PropTypes.string,
  background: PropTypes.string,
  inputBackground: PropTypes.string,
  color: PropTypes.string,
  borderColor: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeFormik: PropTypes.func,
  onEnterClick: PropTypes.func,
  onTabClick: PropTypes.func,
  onBlur: PropTypes.func,
  onFormikBlur: PropTypes.func,
  errorText: PropTypes.string,
  error: PropTypes.string,
  icon: PropTypes.node,
};

export default BaseTextBox;
