import React, { useEffect, useRef, useState } from 'react';
import { Group, Rect, Text, Transformer } from 'react-konva';
import { deleteLayer, setDuplicateRequested, setMergeDownLayer, setPanel, setSelectedLayer, setTextContent, toggleGenerateTextOpened, toggleLayer, updateLayer } from '../../store/builder';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedText } from '../../store/builder';
import { Html } from 'react-konva-utils';
import FontFaceObserver from 'fontfaceobserver';
import { safeFonts } from '../../assets/fonts';
import { getCursorStyle } from '../../helpers';
import { ReactComponent as GenerateIcon } from '../../assets/toolbar/generate.svg';
import { ReactComponent as RenameIcon } from '../../assets/rename.svg';
import { ReactComponent as HideIcon } from '../../assets/hide.svg';
import { ReactComponent as TrashIcon } from '../../assets/trash.svg';
import { ReactComponent as CopyIcon } from '../../assets/copy.svg';
import { ReactComponent as MergeIcon } from '../../assets/merge.svg';
import ContextMenu from './ContextMenu';

let timeout;
let listener = {};
let cursorListener;
const CanvasText = ({ layer }) => {
  const loading = useSelector(state => state.loader.loading);
  const selectedLayer = useSelector(({ builder }) => builder.selectedLayer);
  const editLayerId = useSelector(state => state.builder.editLayerId);
  const selectedText = useSelector(({ builder }) => builder.selectedText);
  const fontFamilyPreview = useSelector(({ builder }) => builder.fontFamilyPreview);
  const tool = useSelector(state => state.builder.tool);
  const panel = useSelector(state => state.builder.panel);
  const zoom = useSelector(state => state.builder.zoom);
  const generateTextOpened = useSelector(({ builder }) => builder.generateTextOpened);
  const [allowEditing, setAllowEditing] = useState(false);
  const [textPos, setTextPos] = useState({ x: 0, y: 0 });
  const [fontFamily, setFontFamily] = useState();
  const textRef = useRef();
  const shapeRef = useRef();
  const trRef = useRef();
  const dispatch = useDispatch();
  const [gradientX, setGradientXPoints] = useState({ start: 0, end: 100 });
  const [contextMenu, setContextMenu] = useState(false);
  const [contextMenuPos, setContextMenuPos] = useState({ x: 0, y: 0 });

  const isEditing = () => {
    if (selectedText === layer.id && tool === 'text')
      return true;
    if (selectedLayer.id === layer.id && allowEditing && tool === 'select')
      return  allowEditing;
    return false;
  };

  useEffect(() => {
    if(textRef?.current?.textWidth && layer.gradient.applied){
      setGradientXPoints({
        start: (layer.gradient.startPoint * textRef.current.textWidth) / 100,
        end: (layer.gradient.endPoint * textRef.current.textWidth) / 100,
      });
    }
  },[textRef?.current?.textWidth, layer.gradient.applied, layer.gradient.startPoint, layer.gradient.endPoint]);

  useEffect(() => {
    if (selectedLayer.id === layer.id) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [selectedLayer]);

  useEffect(() => {
    if (!textRef.current || allowEditing || !fontFamily || fontFamily !== layer.font.family) return;

    setTimeout(() => {
      if (textRef.current.width() >= layer.width * 1.25 ) {
        textRef.current.width((layer.width));
      }

      const yToSet = Math.max(Math.round((shapeRef.current.height() - textRef.current.height()) / 2), 0);
      if (layer.textAlign === 'center') {
        const xToSet = Math.round((layer.width - textRef.current.width()) / 2);
        setTextPos({ x: xToSet > 0 ? xToSet : 0, y: yToSet });
      } else if (layer.textAlign === 'right') {
        setTextPos({ x: Math.max(layer.width - textRef.current.width(), 0), y: yToSet });
      } else {
        setTextPos({ x: 0, y: yToSet });
      }
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }, 50);
  }, [layer.width, layer.height, layer.textAlign, fontFamily]);

  useEffect(() => {
    if(!layer.font.size || (tool === 'text' ? selectedText !== layer.id : selectedLayer.id !== layer.id) || !textRef.current) return;
    clearTimeout(timeout);
    timeout = setTimeout(()=> dispatch(
      updateLayer({
        id: layer.id,
        width: textRef.current.textWidth + 10 + textPos.x * 2,
        height: textRef.current.textHeight + textPos.y * 2,
      }),
    ), 300);
  }, [layer.content, layer.font.size, fontFamily]);

  useEffect(() => {
    if(loading) return;

    const fontFamilyToSet = 
      selectedText === layer.id && fontFamilyPreview ? 
        fontFamilyPreview : 
        layer.font.family;

    if (safeFonts.includes(fontFamilyToSet))
      return setFontFamily(fontFamilyToSet);

    const font = new FontFaceObserver(fontFamilyToSet);
    font.load(null, 15000).then(() => {
      setFontFamily(fontFamilyToSet);
    });

    return () => setFontFamily();
  }, [layer.font.family, fontFamilyPreview, loading]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      event.preventDefault();
      const code = event.keyCode || event.charCode;

      if ([8, 46].includes(code)) {
        dispatch(deleteLayer(selectedLayer.id)); 
      }
    };

    if(selectedLayer.id === layer.id && !editLayerId && !isEditing())
      window.addEventListener('keydown', handleKeyDown);
    else
      window.removeEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [tool, selectedLayer.id, editLayerId, isEditing]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const code = event.keyCode || event.charCode;
      if (code === 37)
        dispatch(
          updateLayer({
          id: selectedLayer.id,
          x: layer.left - 1,
        }));
      if (code === 39)
        dispatch(
          updateLayer({
          id: selectedLayer.id,
          x: layer.left + 1,
        }));
      if (code === 38)
        dispatch(
          updateLayer({
          id: selectedLayer.id,
          y: layer.top - 1,
        }));
      if (code === 40)
        dispatch(
          updateLayer({
          id: selectedLayer.id,
          y: layer.top + 1,
        }));
    };

    if(tool === 'select' && selectedLayer.id === layer.id && !editLayerId && !isEditing()) {
      window.removeEventListener('keydown', listener[layer.id]);
      window.addEventListener('keydown', handleKeyDown);
      listener[layer.id] = handleKeyDown;
    } else {
      window.removeEventListener('keydown', listener[layer.id]);
    }

  }, [tool, selectedLayer.id, layer.top, layer.left, editLayerId, isEditing]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode == 69 && (e.ctrlKey || e.metaKey)) dispatch(setMergeDownLayer(layer.id));
    };

    if (selectedLayer.id === layer.id)
      window.addEventListener('keydown', handleKeyDown);
    else
      window.removeEventListener('keydown', handleKeyDown);
    
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [selectedLayer.id]);

  const select = () => {
    if (tool === 'select') {
      dispatch(setSelectedLayer({
        id: layer.id,
      }));

      if(panel !== 'text')
        dispatch(setPanel('text'));
    }

    dispatch(setSelectedText(layer));
  };

  const transform = (e) => {
    if (selectedLayer.id !== layer.id) return;
    const node = shapeRef.current;
    const scaleX = Math.round(node.scaleX() * 100) / 100;
    const scaleY = Math.round(node.scaleY() * 100) / 100;

    node.scaleX(1);
    node.scaleY(1);

    const newWidth = node.width() * scaleX;
    const newHeight = node.height() * scaleY;

    if(layer.textAlign !== 'center' || textRef.current.width() <= newWidth ) {
      textRef.current.width(newWidth);
    }

    dispatch(updateLayer({
      id: layer.id,
      x: node.x(),
      y: node.y(),
      width: newWidth,
      height: newHeight,
      rotation: e.target.rotation(),
    }));
  };

  const endDragging = (e) => {
    dispatch(
      updateLayer({
        id: layer.id,
        x: e.target.x(),
        y: e.target.y(),
      }),
    );
  };

  const handleTextChange = ({ target: { value } }) => {
    dispatch(
      updateLayer({
        id: layer.id,
        content: value,
        name: value,
      }),
    );

    if (panel === 'text') {
      dispatch(setTextContent(value));
    }

  };

  const handleTextBlur = () => {
    if (tool === 'select') {
      setAllowEditing(false);

      dispatch(setSelectedLayer({
        id: layer.id,
      }));
    }
  };

  const getStyle = () => {
    const baseStyle = {
      border: 'none',
      padding: '0px',
      margin: '0px',
      background: 'none',
      outline: 'none',
      resize: 'none',
      align: 'left',
      width: textRef.current.width(),
      height: textRef.current.height(),
      textAlign: layer.textAlign,
      color: 'transparent', 
      textTransform: layer.font.caps === 2 && 'uppercase',
      caretColor: layer.color,
      fontSize: layer.font.size,
      lineHeight: layer.font.lineHeight && Math.round(textRef.current?.height() / (layer.font.size * layer.font.lineHeight)) === 1  ? layer.font.lineHeight : 1,
      letterSpacing: layer.font.letterSpacing ? Math.round(layer.font.letterSpacing) : 'normal',
      fontFamily: fontFamily,
      fontWeight: layer.bold && 'bold',
      fontStyle: layer.italic && 'italic',
      overflow: 'hidden',
    };

    return {
      ...baseStyle,
      margintop: '-4px',
    };
  };

  const getGradientPoint = () => {
    if(!textRef?.current?.width() || !textRef?.current?.height()) return {
      start: { x: 0, y: 0 },
      end: { x: 0, y: 0 },
    };
    const radius  = gradientX.end - gradientX.start;
    const x = (radius * Math.sin(Math.PI * 2 * layer.gradient.angle / 360));
    const y = (radius * Math.cos(Math.PI * 2 * layer.gradient.angle / 360));
    const xOffset = textRef.current.width() / 2;
    const yOffset = textRef.current.height() / 2;
    return {
      start: { x: x + xOffset, y: y + yOffset },
      end: { x: -x + xOffset, y: -y + yOffset },
    };
  };

  const handleContextMenu = (e) => {
    const { x, y } = e.target.getStage().getPointerPosition();
    setContextMenu(true);
    setContextMenuPos({ x: Math.round(x * 100 / zoom), y: Math.round(y * 100 / zoom) });
    dispatch(setSelectedLayer({
      id: layer.id,
    }));
  };

  const handleClose = () => {
    setContextMenu(false);
  };

  const items = [
    {
      icon: <GenerateIcon stroke="#7C8394" />,
      label: 'Generate text',
      action: () => {
        dispatch(setPanel('text'));
        if (!generateTextOpened) dispatch(toggleGenerateTextOpened());
      },
    },
    { 
      separator: true,
    },
    {
      icon: <HideIcon />,
      label: 'Hide layer',
      action: () => dispatch(toggleLayer(layer.id)),
    },
    {
      icon: <RenameIcon />,
      label: 'Change text',
      action: () => setAllowEditing(true),
    },
    {
      icon: <CopyIcon />,
      label: 'Duplicate',
      action: () => dispatch(setDuplicateRequested(layer.id)),
    },
    {
      icon: <MergeIcon />,
      label: 'Merge down',
      action: () => dispatch(setMergeDownLayer(layer.id)),
    },
    { 
      separator: true,
    },
    {
      icon: <TrashIcon />,
      label: 'Delete',
      action: () => dispatch(deleteLayer(layer.id)),
    },
  ];

  return (
    <>
      <ContextMenu 
        open={contextMenu}
        position={contextMenuPos}
        onClose={handleClose}
        items={items}
      />
      <Group
        x={layer.left}
        y={layer.top}
        ref={shapeRef}
        onClick={select}
        onTap={select}
        onDblClick={() => tool === 'select' && setAllowEditing(true)}
        onDblTap={() => tool === 'select' && setAllowEditing(true)}
        draggable={selectedLayer.id === layer.id && !allowEditing}
        rotation={layer.rotation ? layer.rotation : 0}
        visible={layer.visible}
        onDragEnd={endDragging}
        onTransformEnd={transform}
        fill={layer.background}
        width={layer.width}
        height={layer.height}
      >
        <Rect
          fill={layer.background}
          width={Math.max(layer.width, textRef.current?.width())}
          height={Math.max(layer.height, textRef.current?.height())}
        />
        {isEditing() && (
          <Html
            groupProps={{ x: textPos.x, y: textPos.y }}
            divProps={
              { style:
                  { opacity: 1, width: layer.width, display: 'flex' },
              }}
          >
              <textarea
                value={layer.content}
                onChange={handleTextChange}
                style={getStyle()}
                onBlur={handleTextBlur}
                autoFocus
              />
          </Html>
        )}
        <Text
          ref={textRef}
          x={textPos.x}
          y={textPos.y}
          letterSpacing={layer.font.letterSpacing}
          text={layer.font.caps === 2 ? layer.content.toUpperCase() : layer.content}
          fill={layer.color}
          fontFamily={fontFamily}
          fontSize={layer.font.size}
          fontStyle={(layer.bold ? 'bold ' : '') + (layer.italic ? 'italic' : '')}
          textDecoration={(layer.underline ? 'underline ' : '') + (layer.lineThrough ? 'line-through' : '')}
          stroke={layer.stroke.applied && layer.stroke.color}
          strokeWidth={layer.stroke.applied && layer.stroke.width}
          shadowOffsetX={fontFamily && layer.shadow.applied && layer.shadow.horizontalOffset}
          shadowOffsetY={fontFamily && layer.shadow.applied && layer.shadow.verticalOffset}
          shadowBlur={fontFamily && layer.shadow.applied && layer.shadow.blur}
          shadowColor={fontFamily && layer.shadow.applied && layer.shadow.color}
          shadowOpacity={fontFamily && layer.shadow.applied && layer.shadow.opacity}
          lineJoin='round'
          fillPriority={layer.gradient.applied ? 'linear-gradient' : 'color' }
          fillLinearGradientStartPoint={layer.gradient.applied && getGradientPoint().start}
          fillLinearGradientEndPoint={layer.gradient.applied && getGradientPoint().end}
          fillLinearGradientColorStops={layer.gradient.applied && [layer.gradient.startPoint / 100, layer.gradient.colorA, layer.gradient.endPoint / 100, layer.gradient.colorB]}
          align={layer.textAlign}
          scaleY={layer.font.scaleY ? layer.font.scaleY : 1}
          scaleX={layer.font.scaleX ? layer.font.scaleX : 1}
          onContextMenu={handleContextMenu}
        />
      </Group>
      {selectedLayer.id === layer.id && !allowEditing && <Transformer
        ref={trRef}
        boundBoxFunc={(oldBox, newBox) => {
          // limit resize
          if (newBox.width < 5 || newBox.height < 5) {
            return oldBox;
          }
          return newBox;
        }}
        onMouseEnter={e => {
          if(e.target.attrs.name === 'rotater _anchor') {
            const container = e.target.getStage().container();
            if(container.className !== 'cursor-rotated-arrow-clicked') {
              container.className = 'cursor-rotated-arrow';
              container.style = getCursorStyle(layer.rotation);
            }
          }
        }}
        onMouseMove={e => {
          if(e.target.attrs.name === 'rotater _anchor') {
            const container = e.target.getStage().container();
            if(container.className !== 'cursor-rotated-arrow-clicked') {
              container.className = 'cursor-rotated-arrow';
              container.style = getCursorStyle(layer.rotation);
            }
          } else if (e.target.attrs?.name?.split(' ')[1] === '_anchor') {
            const container = e.target.getStage().container();
            if(container.className === 'cursor-rotated-arrow') { 
              container.className = '';
              container.style = '';
            }
          }
        }}
        onMouseDown={e => {
          if(e.target.attrs.name === 'rotater _anchor') {
            const container = e.target.getStage().container();
            container.className = 'cursor-rotated-arrow-clicked';
            const interval = setInterval(() => {
              container.style = getCursorStyle(trRef.current.rotation());
            });
            window.removeEventListener('mouseup', cursorListener);
            cursorListener = function() {
              this.clearInterval(interval);
              container.className = '';
              container.style = '';
            };
            window.addEventListener('mouseup', cursorListener);
          }
        }}
        onMouseLeave={e => {
          const container = e.target.getStage().container();
          if(container.className === 'cursor-rotated-arrow') { 
            container.className = '';
            container.style = '';
          }
        }}
      />}
    </>
  );
};

CanvasText.propTypes = {
  layer: PropTypes.object,
};

export default CanvasText;
