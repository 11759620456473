import { ReactComponent as Arrow } from '../../assets/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../assets/arrow-left.svg';
import React, { forwardRef }  from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledBaseButton = styled.button`
  background-color: ${({ disabled }) => disabled ? '#D2D4D8' : '#77B36B'};
  border: 1px solid ${({ disabled }) => disabled ? '#D2D4D8' : 'rgba(0, 0, 0, 0.2)'};
  text-transform: capitalize;
  color: #fff;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  box-shadow: none;
  display: flex;
  justify-content: center;
  gap: 17px;
  align-items: center;
  cursor: ${({ disabled }) => disabled ? '' : 'pointer'};
  border-radius: 6px;

  &:hover {
    background-color: ${({ disabled }) => disabled ? '#D2D4D8' : '#88CC7A'};
  }
`;

const BaseButton = forwardRef(function BaseButton({ 
  children, 
  disabled = false, 
  width = 177, 
  height = 40, 
  onTabClick,
  ...rest 
}, ref){
  return (
    <StyledBaseButton
      ref={ref}
      disabled={disabled}
      {...rest}
      style={{ width: width + 'px', minWidth: width + 'px', height: height + 'px' }}
      onKeyDown={(e) => {
        if (onTabClick && e.key === 'Tab') {
          e.preventDefault();
          e.stopPropagation();
          onTabClick();
        }
      }}
    >
      { children }
    </StyledBaseButton>
  );
});

BaseButton.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  onTabClick: PropTypes.func,
};

export default BaseButton;

export const SimpleButton = forwardRef(function SimpleButton({ children, disabled = false, leftIcon = false, rightIcon = true, ...rest }, ref) {
  return (
    <BaseButton
      disabled={disabled}
      {...rest}
      ref={ref}
    >
      {leftIcon ? <Arrow /> : ''}
      { children }
      {rightIcon ? <Arrow /> : ''}
    </BaseButton>
  );
});

SimpleButton.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  leftIcon: PropTypes.bool,
  rightIcon: PropTypes.bool,
};

export const WhiteButton = styled(SimpleButton)` 
  background-color: #fff;
  color: #3C4049;
  font-weight: 500;

  &:hover {
    color: #77B36B;
  }
`;

export const CancelButton = forwardRef(function CancelButton({ width = 91, ...rest }, ref) {
  return(
    <WhiteButton
      {...rest}
      rightIcon={false}
      width={width}
      ref={ref}
    >
      Cancel
    </WhiteButton>);
});

CancelButton.propTypes = {
  width: PropTypes.number,
};

export const HoverButton = styled(BaseButton)`
  background-color: transparent;
  color: #1A1C23;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;

  &:hover {
    background-color: #DCDDE1;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const OutlinedButton = styled(BaseButton)`
  background-color: #fff;
  color: #1A1C23;
  border-color: #77B36B;

  &:hover {
    background-color: ${({ disabled }) => disabled ? '#D2D4D8' : '#F1F7F0'};
  }
`;

export const RedButton = styled(BaseButton)`
  background-color: #f37171;

  &:hover {
    background-color: ${({ disabled }) => disabled ? '#D2D4D8' : '#f68e8e'};
  }
`;

export const BackButton = (props) => (
  <WhiteButton
  {...props}
  rightIcon={false}
  width={116}
  >
    <ArrowLeft />
    Back
  </WhiteButton>
);
