import { Button, Dialog, DialogContent } from '@mui/material';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import BaseTextBox from '../../components/Common/BaseTextBox';
import BaseButton, { CancelButton } from '../../components/Common/BaseButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { changeProfile, setChangePasswordModal, setChangeProfileModal } from '../../store/user';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { ReactComponent as ImageIcon } from '../../assets/toolbar/import.svg';
import { ReactComponent as AvatarImg } from '../../assets/user-avatar.svg';

const EditProfileDialog = styled(Dialog)`
  .MuiPaper-root {
  }
`;

const FormError = styled.p`
  color: #f37171;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 0;
`;

const Content = styled(DialogContent)`
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
`;

const StyledButton = styled(Button)`
  &.MuiButtonBase-root {
    color: #77B36B;
    text-transform: none;

    &:hover {
      background-color: ${({ disabled }) => disabled ? '#D2D4D8' : '#F1F7F0'};
    }
  }
`;

const UserAvatar = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  justify-content: center;
  width: min-content;
  margin: 0 auto;
  .avatar {
    height: 75px!important;
    width: 75px!important;
    object-fit: cover;
    border-radius: 50%;
  }
  .hoverImage {
    display: none;
  }
  &:hover {
    .avatar {
      filter: invert(25%);
    }
    .hoverImage {
      display: block;
      stroke: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      margin-bottom: auto;
      margin-top: auto;
      height: 30px;
      width: 30px;
    }
  }
`;

const UserAvatarText = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: rgb(124, 131, 148);
  text-align: center;    
  margin-bottom: 15px;
`;

const EmailTextBox = styled(BaseTextBox)`
  input {
    background-color: #F5F5F5;
  }
`;

const validationSchema = yup.object({
    name: yup.string().required('Required field'),
    email: yup.string().required('Required field').email(),
});

const EditProfile = () => {
  const {
    name,
    email,
    avatar,
  } = useSelector(state => state.user);
  const open = useSelector(({ user }) => user.changeProfileModal);
  const error = useSelector(({ user }) => user.changeProfileError);
  const dispatch = useDispatch();
  const uploadRef = useRef();
  const emailRef = useRef();
  const nameRef = useRef();
  const btnRef = useRef();
  const [newAvatar, setNewAvatar] = useState();
  const formik = useFormik({
    initialValues: {
      name,
      email,
    },
    onSubmit: values => {
      dispatch(changeProfile({ ...values, avatar: newAvatar }));
    },
    validationSchema,
  });
  const handleClose = () => {
    formik.handleReset();
    dispatch(setChangeProfileModal(false));
    setNewAvatar();
  };
  const handlePasswordChange = () => {
    dispatch(setChangeProfileModal(false));
    dispatch(setChangePasswordModal(true));
  };
  const selectFiles = () => {
    uploadRef.current.click();
  };
  const handleUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        setNewAvatar(reader.result);
    };
  };
  
  return ( 
      <EditProfileDialog
        open={open}
        onClose={handleClose}
      >
          <Content>
            <UserAvatar onClick={selectFiles}>
              {
                avatar || newAvatar ?
                  <img src={newAvatar || process.env.REACT_APP_API_URL + 'images/' + avatar } alt="" className='avatar' />
                  : <AvatarImg className='avatar' />
              }
              <ImageIcon className="hoverImage" />
              <input
                type="file"
                ref={uploadRef}
                hidden
                onChange={handleUpload}
                accept="image/png, image/gif, image/jpeg"
              />
            </UserAvatar>
            <UserAvatarText>
              Click on avatar image to change profile picture.
            </UserAvatarText>

            {error?.length &&
              <FormError>{error}</FormError>
            }
            <BaseTextBox 
              ref={nameRef}
              width={300} 
              label="Name"
              name="name"
              value={formik.values.name}
              errorText={formik.touched.name && formik.errors.name}
              onChangeFormik={formik.handleChange}
              onFormikBlur={formik.handleBlur}
              onEnterClick={() => emailRef.current.focus()}
              onTabClick={() => emailRef.current.focus()}
            />
            <EmailTextBox
              disabled
              width={300} 
              label="Email"
              type="email"
              name="email"
              value={formik.values.email}
            />
            <StyledButton 
              variant="text" 
              startIcon={<LockOutlinedIcon />}
              onClick={handlePasswordChange}
            >
              Change password
            </StyledButton>
            <ButtonWrap>
              <CancelButton ref={btnRef} onClick={handleClose}>
                Cancel
              </CancelButton>
              <BaseButton 
                disabled={!formik.isValid} 
                onClick={formik.submitForm}
                onTabClick={() => nameRef.current.focus()}
              >
                Save
              </BaseButton>
            </ButtonWrap>
          </Content>
      </EditProfileDialog>
  );
};

export default EditProfile;