import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api';

const initialState = {
  selectedTab: 'import',
  images: [],
  pagination: {
    page: 1,
    perPage: 4,
    isLastPage: false,
    loading: false,
  },
};

export const fetchUploadedImages = createAsyncThunk(
  'imageImporter/fetchUploadedImages',
  async (arg, { getState }) => {
    const { builder, imageImporter } = getState();

    return api.imageUploader.fetch({
      project: builder.project.id,
      limit: imageImporter.pagination.perPage,
    }, '', false);
  },
);

export const fetchUploadedImagesNextPage = createAsyncThunk(
  'imageImporter/fetchUploadedImagesNextPage',
  async (arg, { getState }) => {
    const { builder, imageImporter } = getState();

    return api.imageUploader.fetch({
      project: builder.project.id,
      limit: imageImporter.pagination.perPage,
      offset: imageImporter.pagination.perPage * imageImporter.pagination.page,
    }, '', false);
  },
);

export const uploadImage = createAsyncThunk(
  'imageImporter/uploadImage',
  async ({ image }, { dispatch, getState }) => {
    const { builder } = getState();

    await api.imageUploader.upload(
      image,
      [{
        key: 'project',
        value: builder.project.id,
      }],
    );

    return dispatch(fetchUploadedImages());
  },
);

export const deleteImage = createAsyncThunk(
  'imageImporter/deleteImage',
  async ({ image }, { getState }) => {
    const { builder, imageImporter: { pagination } } = getState();

    await api.imageUploader.delete(
      image,
    );

    return api.imageUploader.fetch({
      project: builder.project.id,
      limit: pagination.perPage * pagination.page,
    }, '', false);
  },
);

export const imageImporter = createSlice({
  name: 'imageImporter',
  initialState,
  reducers: {
    setSelectedTab(state, { payload }) {
      state.selectedTab = payload;
    },
    clearImageImporter(state) {
      state.selectedTab = 'import';
      state.images = [];
      state.pagination = {
        page: 1,
        perPage: 4,
        isLastPage: false,
        loading: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUploadedImages.fulfilled, (state, { payload }) => {
      state.images = payload ? payload : [];
      state.pagination.page = 1;
      state.pagination.isLastPage = false;
    });

    builder.addCase(fetchUploadedImagesNextPage.pending, (state) => {
      state.pagination.loading = true;
    });

    builder.addCase(fetchUploadedImagesNextPage.fulfilled, (state, { payload }) => {
      state.images = [...state.images, ...payload];
      state.pagination.page += 1;
      if (payload.length < state.pagination.perPage) state.pagination.isLastPage = true;
      state.pagination.loading = false;
    });
    
    builder.addCase(deleteImage.fulfilled, (state, { payload }) => {
      state.images = payload ? payload : [];
    });
  },
});

export const {
  setSelectedTab,
  clearImageImporter,
} = imageImporter.actions;

export default imageImporter.reducer;
