import React, { useEffect } from 'react';
import styled from 'styled-components';
import Toolbar from '../components/Builder/Toolbar';
import Generator from '../components/Builder/Generator';
import CanvasPanel from '../components/Builder/CanvasPanel';
import TextPanel from '../components/Builder/TextPanel';
import FilterPanel from '../components/Builder/FilterPanel';
import LibraryPanel from '../components/Builder/LibraryPanel';
import LayersPanel from '../components/Builder/LayersPanel';
import Canvas from '../components/Builder/Canvas';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ExportDialog from '../components/Builder/ExportDialog';
import ImagePanel from '../components/Builder/ImagePanel';
import InitialActions from '../components/Builder/InitialActions';
import { fetchProject } from '../store/builder';

const Container = styled.div`
  position: relative;
  height: calc(100vh - 40px);
  background-color: #DCDDE1;
  margin-top: 40px;
  padding-top: 50px;
`;

const CanvasContainer = styled.div`
  height: calc(100vh - 90px);
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  &.filled{
    > div:first-child > div:first-child {
      background-color: #ffffff;
      background-image:  repeating-linear-gradient(45deg, #e8e8e8 25%, transparent 25%, transparent 75%, #e8e8e8 75%, #e8e8e8), repeating-linear-gradient(45deg, #e8e8e8 25%, #ffffff 25%, #ffffff 75%, #e8e8e8 75%, #e8e8e8);
      background-position: 0 0, 7px 7px;
      background-size: 14px 14px;
    }
  }
`;

const Builder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const project = useSelector(state => state.builder.project);

  useEffect(() => {
    if (projectId === null) {
      navigate('/');
    }
    dispatch(fetchProject({
      id: projectId,
    }));
  }, []);

  if (!project) return;

  const backgroundIsTransparent = !project.background || (project.background.length === 9 && project.background[7] === '0' && project.background[8] === '0');

  return (
    <Container>
      <Toolbar />
      <CanvasContainer className={ backgroundIsTransparent ? 'filled' : ''}>
        <Canvas />
        <InitialActions />
      </CanvasContainer>
      <Generator />
      <ImagePanel />
      <LibraryPanel />
      <FilterPanel />
      <TextPanel />
      <CanvasPanel />
      <LayersPanel />
      <ExportDialog />
    </Container>
  );
};

export default Builder;
