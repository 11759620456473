import React, { Fragment, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  fetchTemplateCategories,
  fetchTemplates, fetchTemplateSizes,
  setTemplateFilterCategory,
  setTemplateFilterSize,
  setTemplateSearch,
} from "../../store/admin";
import PageHeader from "./PageHeader";
import { useDispatch, useSelector } from "react-redux";
import BaseSelect from "../Common/BaseSelect";
import styled from "styled-components";

const Label = styled.div`
  width: 40px;
  text-align: right;
`;

const AssetSection = () => {
  const { search, category, size } = useSelector(({ admin }) => admin.template.filter);
  const categories = useSelector(({ admin }) => admin.template.categories);
  const sizes = useSelector(({ admin }) => admin.template.sizes);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchTemplates());
  }, [search, category, size]);

  useEffect(() => {
    dispatch(fetchTemplateCategories());
    dispatch(fetchTemplateSizes());
  }, []);

  return (
    <Fragment>
      <PageHeader
        title="Templates"
        listPage={location.pathname === '/admin/templates'}
        onAddClicked={() => navigate('/admin/templates/new')}
        onSearch={(val) => dispatch(setTemplateSearch(val))}
      >
        {location.pathname === '/admin/templates' &&
          <Fragment>
            <Label>
              Category:
            </Label>
            <BaseSelect
              width={250}
              value={category}
              options={categories}
              onChange={(val) => dispatch(setTemplateFilterCategory(val))}
            />
            <Label>
              Type:
            </Label>
            <BaseSelect
              width={250}
              value={size}
              options={sizes}
              textKey="label"
              onChange={(val) => dispatch(setTemplateFilterSize(val))}
            />
          </Fragment>
        }
      </PageHeader>
      <Outlet />
    </Fragment>
  );
};

export default AssetSection;