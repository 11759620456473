import ReadOnlyService from './ReadOnlyService';
import { store } from '../../store';
import { decreaseLoading, increaseLoading } from '../../store/loader';

class ModelService extends ReadOnlyService {
  constructor(resource, config) {
    super(resource, config);
  }

  async post(body = {}, action = '', withLoading = true) {
    if(withLoading) store.dispatch(increaseLoading());

    try {
      const { data } = await this.axios.post(
        this.getUrl(action),
        body,
        {
          headers: this.getHeaders(),
        },
      );
      return data;
    } catch (err) {
      return this.handleErrors(err);
    } finally {
      if(withLoading) store.dispatch(decreaseLoading());
    }
  }

  async upload(file , params = []) {
    store.dispatch(increaseLoading());

    try {
      const formData = new FormData();
      formData.append('file', file);

      for (const { key, value } of params) {
        formData.append(key, value);
      }

      const { data } = await this.axios.post(
        this.getUrl(),
        formData,
        {
          headers: this.getHeaders(true),
        },
      );

      return data;
    } catch (err) {
      return this.handleErrors(err);
    } finally {
      store.dispatch(decreaseLoading());
    }
  }

  async patch(id, body) {
    store.dispatch(increaseLoading());

    try {
      const { data } = await this.axios.patch(
        this.getUrl(id),
        body,
        {
          headers: this.getHeaders(),
        },
      );
      return data;
    } catch (err) {
      return this.handleErrors(err);
    } finally {
      store.dispatch(decreaseLoading());
    }
  }

  async delete(id) {
    if (!id){
      return this.handleErrors('Id is not provided');
    }

    store.dispatch(increaseLoading());

    try {
      await this.axios.delete(
        this.getUrl(id),
        {
          headers: this.getHeaders(),
        },
      );
      return true;
    } catch (err) {
      return this.handleErrors(err);
    } finally {
      store.dispatch(decreaseLoading());
    }
  }

  async action(action, body, method = 'patch') {
    store.dispatch(increaseLoading());

    try {
      const { data } = await this.axios[method](this.getUrl(action), body);
      return data;
    } catch (err) {
      return this.handleErrors(err);
    } finally {
      store.dispatch(decreaseLoading());
    }
  }
}

export default ModelService;
