import React from 'react';
import styled from 'styled-components';
import TextGeneratorForm from './TextGeneratorForm';
import TextGeneratorResults from './TextGeneratorResults';
import { toggleWaitOpened } from '../../store/textGenerator';
import BaseProcessingWait from '../Common/BaseProcessingWait';
import { useDispatch, useSelector } from 'react-redux';

const Container = styled.div`
  background-color: #fff;
  border-left: 1px solid #DCDDE1;
  width: 290px;
`;

const TextGenerator = () => {
  const waitOpened = useSelector(state => state.textGenerator.waitOpened);
  const prompt = useSelector(state => state.textGenerator.prompt);
  const dispatch = useDispatch();

  return (
    <Container>
      <TextGeneratorForm />
      <TextGeneratorResults />
      <BaseProcessingWait
        title="Generating text"
        prompt={prompt}
        opened={waitOpened}
        toggleOpened={() => dispatch(toggleWaitOpened())}
      />
    </Container>
  );
};

export default TextGenerator;
