import React from 'react';
import { ReactComponent as PixabayIcon } from '../../assets/pixabay.svg';
import BasePanel from '../Common/BasePanel';
import BaseTabs from '../Common/BaseTabs';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTab } from '../../store/imageImporter';
import ImagePanelImporter from './ImagePanelImporter';
import ImagePanelPixabay from './ImagePanelPixabay';

const ImagePanel = () => {
  const selectedTab = useSelector(state => state.imageImporter.selectedTab);
  const dispatch = useDispatch();

  const tabs = [
    { label: 'Import image', icon: '', value: 'import' },
    { label: 'Pixabay', icon: <PixabayIcon />, value: 'pixabay' },
  ];

  return (
    <BasePanel panel="image" collapsable>
      <BaseTabs
        fixedPosition
        width="280px"
        tabs={tabs}
        activeTab={selectedTab}
        switchTab={(tab) => dispatch(setSelectedTab(tab))}
      />
      { selectedTab === 'import' && <ImagePanelImporter /> }
      { selectedTab === 'pixabay' && <ImagePanelPixabay /> }
    </BasePanel>
  );
};

export default ImagePanel;
