import React from 'react';
import styled from 'styled-components';
import BaseColorPicker from '../Common/BaseColorPicker';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBackground,
  setColor,
  setEditLayerId,
  setFontSize,
  setTextAlign,
  toggleBold,
  toggleItalic,
  toggleLineThrough,
  toggleUnderline,
} from '../../store/builder';
import { ReactComponent as BoldIcon } from '../../assets/font/bold.svg';
import { ReactComponent as CrossIcon } from '../../assets/font/cross.svg';
import { ReactComponent as ItalicIcon } from '../../assets/font/italic.svg';
import { ReactComponent as UnderlineIcon } from '../../assets/font/underline.svg';
import { ReactComponent as LeftIcon } from '../../assets/font/left.svg';
import { ReactComponent as CenterIcon } from '../../assets/font/center.svg';
import { ReactComponent as RightIcon } from '../../assets/font/right.svg';
import BaseAutocomplete from '../Common/BaseAutocomplete';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const Element = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #3C4049;
  flex: 1;
`;

const Option = styled.div`
  background: #FFFFFF;
  border: 1px solid #DCDDE1;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  
  svg {
    fill: #989FAD;
    
    line {
      stroke: #989FAD;
    }
  }
  
  &.underline {
    svg {
      fill: none;

      path {
        stroke: #989FAD;
      }
    }
  }
  
  &.selected {
    border-color: #1A1C23;
  
    svg {
      fill: #1A1C23;
      
      line {
        stroke: #1A1C23;
      }
    }

    &.underline {
      svg {
        fill: none;

        path {
          stroke: #1A1C23;
        }
      }
    }
  }
`;

const TextStylePanelFont = () => {
  const text = useSelector(state => state.builder.text);
  const editLayerId = useSelector(({ builder }) => builder.editLayerId);
  const dispatch = useDispatch();

  const handleClick = () => {
    if (!text.id || editLayerId === text.id) return;
    dispatch(setEditLayerId(text.id));
  };

  const handleClose = () => {
    if (!editLayerId) return;
    dispatch(setEditLayerId(false));
  };

  return (
    <Container>
      <Element>
        <Label>Font size</Label>
         <BaseAutocomplete
            disablePortal
            disableClearable
            options={new Array(999).fill('').map((_, i) => `${++i}`)}
            sx={{ width: 80 }}
            value={text.font.size}
            onChange={(value) => dispatch(setFontSize(value))}  
            type="number"  
            onClose={handleClose}
            onOpen={handleClick}
          />
      </Element>
      <Element>
        <Label>Font style</Label>
        <Option
          className={text.bold ? 'selected' : ''}
          onClick={() => dispatch(toggleBold())}
        >
          <BoldIcon />
        </Option>
        <Option
          className={text.italic ? 'selected' : ''}
          onClick={() => dispatch(toggleItalic())}
        >
          <ItalicIcon />
        </Option>
        <Option
          className={text.lineThrough ? 'selected' : ''}
          onClick={() => dispatch(toggleLineThrough())}
        >
          <CrossIcon />
        </Option>
        <Option
          className={'underline ' + (text.underline ? 'selected' : '')}
          onClick={() => dispatch(toggleUnderline())}
        >
          <UnderlineIcon />
        </Option>
      </Element>
      <Element>
        <Label>Text align</Label>
        <Option
          className={text.textAlign === 'left' ? 'selected' : ''}
          onClick={() => dispatch(setTextAlign('left'))}
        >
          <LeftIcon />
        </Option>
        <Option
          className={text.textAlign === 'center' ? 'selected' : ''}
          onClick={() => dispatch(setTextAlign('center'))}
        >
          <CenterIcon />
        </Option>
        <Option
          className={text.textAlign === 'right' ? 'selected' : ''}
          onClick={() => dispatch(setTextAlign('right'))}
        >
          <RightIcon />
        </Option>
      </Element>
      <Element>
        <Label>Font color</Label>
        <BaseColorPicker
          color={text.color}
          setColor={(color) => dispatch(setColor(color))}
        />
      </Element>
      <Element>
        <Label>Background color</Label>
        <BaseColorPicker
          color={text.background}
          setColor={(color) => dispatch(setBackground(color))}
        />
      </Element>
    </Container>
  );
};

export default TextStylePanelFont;
