import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ExportIcon } from '../../assets/toolbar/export.svg';
import { HoverButton } from '../Common/BaseButton';
import { useDispatch } from 'react-redux';
import { emptySelectedLayer, setExportDialog, setPanel } from '../../store/builder';

const StyledToolbarExport = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   border-left: 1px solid #DCDDE1;
   width: 123px;
`;

const ToolbarExport = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(emptySelectedLayer());
    dispatch(setPanel(null));
    dispatch(setExportDialog(true));
  };

  return (
    <StyledToolbarExport onClick={handleClick}>
      <HoverButton width={115}>
        Export
        <ExportIcon />
      </HoverButton>
    </StyledToolbarExport>
  );
};

export default ToolbarExport;
