import React, { useEffect, useRef, useState } from 'react';
import { Group, Image, Line, Transformer } from 'react-konva';
import { deleteLayer, emptyBackgroundPreview, emptyLasso, setDuplicateRequested, setEditLayerId, setEditNameModal, setFlipHorizontalRequested, setFlipVerticalRequested, setLasso, setMergeDownLayer, setPanel, setSelectedLayer, toggleLayer, updateLayer } from '../../store/builder';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useImage from 'use-image';
import Konva from 'konva';
import { Html } from 'react-konva-utils';
import ReactLassoSelect, { getCanvas } from 'react-lasso-select';
import { calculatePixelRatio, getCursorStyle } from '../../helpers';
import { ReactComponent as TrashIcon } from '../../assets/trash.svg';
import { ReactComponent as CopyIcon } from '../../assets/copy.svg';
import { ReactComponent as FlipHorizontal } from '../../assets/flip-horizontal.svg';
import { ReactComponent as FlipVertical } from '../../assets/flip-vertical.svg';
import { ReactComponent as RenameIcon } from '../../assets/rename.svg';
import { ReactComponent as HideIcon } from '../../assets/hide.svg';
import { ReactComponent as MergeIcon } from '../../assets/merge.svg';
import ContextMenu from './ContextMenu';

let listener = {};
let cursorListener;
const CanvasImage = ({ layer, lines, removeLines, addLine, stageRef }) => {
  const selectedLayer = useSelector(({ builder }) => builder.selectedLayer);
  const editLayerId = useSelector(state => state.builder.editLayerId);
  const imageBackgroundPreview = useSelector(state => state.builder.imageBackgroundPreview);
  const flipVerticalRequested = useSelector(({ builder }) => builder.flipVerticalRequested);
  const flipHorizontalRequested = useSelector(({ builder }) => builder.flipHorizontalRequested);
  const tool = useSelector(state => state.builder.tool);
  const panel = useSelector(state => state.builder.panel);
  const lasso = useSelector(state => state.builder.lasso);
  const zoom = useSelector(state => state.builder.zoom);
  const project = useSelector(state => state.builder.project);
  const shapeRef = useRef();
  const linesRef = useRef([]);
  const trRef = useRef();
  const groupRef = useRef();
  const dispatch = useDispatch();
  const [image] = useImage(selectedLayer.id === layer.id && imageBackgroundPreview ? imageBackgroundPreview : layer.content); 
  const [patternImage] = useImage('./assets/tiles.svg'); 
  const [lastTool, setLastTool] = useState();
  const [points, setPoints] = useState([]);
  const [clipboard, setClipboard] = useState(null);
  const [contextMenu, setContextMenu] = useState(false);
  const [contextMenuPos, setContextMenuPos] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (selectedLayer.id === layer.id) {
      trRef.current.nodes( [shapeRef.current] );
      trRef.current.getLayer().batchDraw();
    }
  }, [selectedLayer]);

  useEffect(() => {
    shapeRef.current.cache({ imageSmoothingEnabled: false });
  }, [image, lines]);

  useEffect(() => {
    if(selectedLayer.id === layer.id)
      shapeRef.current.cache({ imageSmoothingEnabled: false });
  }, [selectedLayer.id, tool]);

  useEffect(() => {
    linesRef.current = linesRef.current.slice(0, lines.length);
  }, [lines]);

  useEffect(() => {
    if(['eraser', 'lasso'].includes(tool))
      setLastTool(tool);
  }, [tool]);

  useEffect(() => {
    // update image src after it was erased
    if(!groupRef.current || !lines.length) return;
    groupRef.current.rotate(0);
    shapeRef.current.opacity(1);

    stageRef.current.scaleX(1);
    stageRef.current.scaleY(1);

    dispatch(updateLayer({
      id: layer.id,
      content: groupRef.current.toDataURL({ 
        x: layer.left, 
        y: layer.top,
        width: layer.width,
        height: layer.height,
        pixelRatio: calculatePixelRatio(layer.width, layer.height, project.width, project.height),
      }),
    }));

    stageRef.current.scaleX(zoom / 100);
    stageRef.current.scaleY(zoom / 100);

    shapeRef.current.opacity(layer.opacity / 100);
    removeLines(layer.id);
  }, [tool]);

  useEffect(() => {
    if((points.length || clipboard?.length) && (selectedLayer.id !== layer.id || tool !== 'eraser')) {
      setPoints([]);
      setClipboard(null);
    }
  }, [selectedLayer.id, tool]);

  const handleAddLine = () => {        
    const pos = shapeRef.current.position();
    const linePoints = [];
    for (const point of points) {
      linePoints.push((point.x * 100 / zoom + (pos.x)), (point.y * 100 / zoom + (pos.y)));
    } 
    addLine(linePoints);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      event.preventDefault();
      const code = event.which || event.keyCode;
      let charCode = String.fromCharCode(code).toLowerCase();

      if ((event.ctrlKey || event.metaKey) && charCode === 'x' && clipboard) {
        dispatch(setLasso({ clipboard, name: layer.name }));
        handleAddLine();
        setPoints([]);
      } else if ((event.ctrlKey || event.metaKey) && charCode === 'c' && clipboard) {
        dispatch(setLasso({ clipboard, name: layer.name }));
      } else if ((event.ctrlKey || event.metaKey) && charCode === 'd' || [8, 46].includes(code)) {
        dispatch(emptyLasso());
        handleAddLine();
        setPoints([]);
      } else if (code === 27) {
        setPoints([]);
        dispatch(emptyLasso());
      }
    };

    if (tool === 'lasso' && selectedLayer.id === layer.id)
      window.addEventListener('keydown', handleKeyDown);
    else
      window.removeEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [tool, selectedLayer.id, clipboard]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const code = event.keyCode || event.charCode;
      if (code === 37)
        dispatch(
          updateLayer({
          id: selectedLayer.id,
          x: layer.left - 1,
        }));
      if (code === 39)
        dispatch(
          updateLayer({
          id: selectedLayer.id,
          x: layer.left + 1,
        }));
      if (code === 38)
        dispatch(
          updateLayer({
          id: selectedLayer.id,
          y: layer.top - 1,
        }));
      if (code === 40)
        dispatch(
          updateLayer({
          id: selectedLayer.id,
          y: layer.top + 1,
        }));
    };

    if(tool === 'select' && !editLayerId && selectedLayer.id === layer.id) {
      window.removeEventListener('keydown', listener[layer.id]);
      window.addEventListener('keydown', handleKeyDown);
      listener[layer.id] = handleKeyDown;
    } else {
      window.removeEventListener('keydown', listener[layer.id]);
    }

  }, [tool, selectedLayer.id, editLayerId, layer.top, layer.left]);

  useEffect(() => {
    if(!flipVerticalRequested || selectedLayer.id !== layer.id) return;
    shapeRef.current.scaleY(-1);
    shapeRef.current.offsetY(shapeRef.current.width());
    shapeRef.current.opacity(1);
    shapeRef.current.filters([]);
    dispatch(updateLayer({
      id: layer.id,
      content: shapeRef.current.toDataURL(),
    }));
    shapeRef.current.scaleY(1);
    shapeRef.current.offsetY(0);
    shapeRef.current.opacity(layer.opacity / 100);
    shapeRef.current.filters(filters);
    dispatch(setFlipVerticalRequested(false));
  }, [flipVerticalRequested]);

  useEffect(() => {
    if(!flipHorizontalRequested || selectedLayer.id !== layer.id) return;
    shapeRef.current.scaleX(-1);
    shapeRef.current.offsetX(shapeRef.current.width());
    shapeRef.current.opacity(1);
    shapeRef.current.filters([]);
    dispatch(updateLayer({
      id: layer.id,
      content: shapeRef.current.toDataURL(),
    }));
    shapeRef.current.opacity(layer.opacity / 100);
    shapeRef.current.scaleX(1);
    shapeRef.current.offsetX(0);
    shapeRef.current.filters(filters);
    dispatch(setFlipHorizontalRequested(false));
  }, [flipHorizontalRequested]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      event.preventDefault();
      const code = event.keyCode || event.charCode;

      if ([8, 46].includes(code)) {
        dispatch(deleteLayer(selectedLayer.id)); 
      }
    };

    if(selectedLayer.id === layer.id && !editLayerId)
      window.addEventListener('keydown', handleKeyDown);
    else
      window.removeEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [tool, selectedLayer.id, editLayerId]);
  
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode == 69 && (e.ctrlKey || e.metaKey)) dispatch(setMergeDownLayer(layer.id));
    };

    if (selectedLayer.id === layer.id)
      window.addEventListener('keydown', handleKeyDown);
    else
      window.removeEventListener('keydown', handleKeyDown);
    
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [selectedLayer.id]);

  const select = () => {
    if (selectedLayer.id === layer.id || panel === 'text' && !selectedLayer.id) return;
    if (imageBackgroundPreview)
      dispatch(emptyBackgroundPreview());
    dispatch(setSelectedLayer({
      id: layer.id,
    }));
    /*if(panel !== 'filter')
      dispatch(setPanel('filter'));*/
  };

  const handleContextMenu = (e) => {
    const { x, y } = e.target.getStage().getPointerPosition();
    setContextMenu(true);
    setContextMenuPos({ x: Math.round(x * 100 / zoom), y: Math.round(y * 100 / zoom) });
    dispatch(setSelectedLayer({
      id: layer.id,
    }));
  };

  const handleClose = () => {
    setContextMenu(false);
  };

  const closeFilterPanel = () => {
    if (panel === 'filter') dispatch(setPanel(null));
  };

  const items = [
    {
      icon: <CopyIcon />,
      label: 'Make a copy',
      action: () => dispatch(setDuplicateRequested(layer.id)),
    },
    {
      icon: <MergeIcon />,
      label: 'Merge down',
      action: () => dispatch(setMergeDownLayer(layer.id)),
    },
    {
      icon: <FlipHorizontal />,
      label: 'Flip horizontally',
      action: () => dispatch(setFlipHorizontalRequested(true)),
    },
    {
      icon: <FlipVertical />,
      label: 'Flip vertically',
      action: () => dispatch(setFlipVerticalRequested(true)),
    },
    { 
      separator: true,
    },
    {
      icon: <HideIcon />,
      label: 'Hide layer',
      action: () => closeFilterPanel(dispatch(toggleLayer(layer.id))),
    },
    {
      icon: <RenameIcon />,
      label: 'Rename layer',
      action: () => {
        dispatch(setEditLayerId(layer.id));
        dispatch(setEditNameModal(true));
      },
    },
    { 
      separator: true,
    },
    {
      icon: <TrashIcon />,
      label: 'Delete',
      action: () => closeFilterPanel(dispatch(deleteLayer(layer.id))),
    },
  ];

  let filters = [];

  if (layer.filter?.name) {
    const name = layer.filter.name;

    if (name === 'Saturation')
      filters.push(Konva.Filters.HSL, Konva.Filters.HSV);
    else
      filters.push(Konva.Filters[name]);
  }

  if (!!lines.length && (selectedLayer.id !== layer.id || tool === 'erase')) {
    filters = [];
  }

  const ImageComponent = (rotate, x, y) =>
  <Image
    onClick={select}
    onTap={select}
    onContextMenu={handleContextMenu}
    ref={shapeRef}
    draggable={selectedLayer.id === layer.id && tool === 'select'}
    x={x}
    y={y}
    width={layer.width}
    height={layer.height}
    globalCompositeOperation={layer.blendingMode}
    image={image}
    filters={filters}
    blurRadius={layer.filter?.blurRadius}
    brightness={(layer.filter?.brightness ? layer.filter?.brightness - 50 : 0) / 100}
    contrast={layer.filter?.contrast - 50}
    embossStrength={(layer.filter?.embossStrength ?? 0) / 100}
    embossWhiteLevel={(layer.filter?.embossWhiteLevel ?? 0) / 100}
    enhance={(layer.filter?.enhance ?? 0) / 100}
    noise={(layer.filter?.noise ?? 0) / 100}
    saturation={(layer.filter?.saturation ? layer.filter.saturation - 50 : 0) / 100}
    value={(layer.filter?.value ? layer.filter.value - 50 : 0) / 100}
    luminance={(layer.filter?.luminance ? layer.filter.luminance - 50 : 0) / 100}
    rotation={rotate}
    visible={layer.visible}
    opacity={typeof layer.opacity === 'number' ? layer.opacity / 100 : 1}
    onDragEnd={e => {
      if (tool === 'select') {
        dispatch(updateLayer({ id: layer.id, x: e.target.x(), y: e.target.y() }));
      }
    }}
    onTransformEnd={(e) => {
      if (tool === 'select') {
        const node = shapeRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        node.scaleX(1);
        node.scaleY(1);
        const width = Math.max(5, node.width() * scaleX);
        const height = Math.max(node.height() * scaleY);
        node.width(width);
        node.height(height);

        dispatch(updateLayer({
          id: layer.id,
          x: node.x(),
          y: node.y(),
          width,
          height,
          rotation: e.target.rotation(),
        }));

        node.cache({ imageSmoothingEnabled: false });
      }
    }}
    fillPatternImage={tool === 'background' && selectedLayer.id === layer.id && imageBackgroundPreview && patternImage}
  />;

  return (
    <>   
      <ContextMenu 
        open={contextMenu}
        position={contextMenuPos}
        onClose={handleClose}
        items={items}
      />
      {tool === 'lasso' && selectedLayer.id === layer.id &&
        <Html 
          groupProps={{ x: shapeRef.current?.x(), y: shapeRef.current?.y(), rotation: layer.rotation }}
        >
          <ReactLassoSelect
            value={points}
            disabled={!!lasso.clipboard?.length}
            src={shapeRef.current?.toDataURL()}
            onChange={(path) => {
              setPoints(path);
              if(!!lasso.points.length || lasso.clipboard)
                dispatch(emptyLasso());
            }}
            style={{ cursor: 'default!important' }}
            onComplete={(path) => {
              if (!path.length) return;
              getCanvas(shapeRef.current?.toDataURL(), path, (err, canvas) => {
                if (!err) {
                  setClipboard(canvas.toDataURL('image/png'));
                }
              });
            }}
            imageStyle={{ width: `${layer.width}px`, cursor: 'default!important', outline: '1px solid #04a0ff' }}
          />
        </Html>
      }
       {lines.length || (['eraser', 'lasso'].includes(tool) && selectedLayer.id === layer.id) ? 
         <Group 
           ref={groupRef}  
           x={tool === 'lasso' && selectedLayer.id === layer.id && layer.rotation ?
             -10000 :
             lastTool === 'eraser' && layer.left
           }
           rotation={layer.rotation && lastTool === 'eraser' && selectedLayer.id === layer.id ? layer.rotation : 0}
           width={layer.width}
           height={layer.height}
           y={lastTool === 'eraser' && layer.top}
         >
           {ImageComponent(
             0, 
             lastTool === 'eraser' ? 0 : layer.left,  
             lastTool === 'eraser' ? 0 : layer.top,
           )}
           {lines.map((line, i) =>
             <Line
               ref={el => linesRef.current[i] = el} 
               key={i}
               points={line.points}
               stroke={line.tool === 'eraser' ? '#fff' : null}
               strokeWidth={line.strokeWidth}
               tension={line.tool === 'eraser' ? 0.1 : 0}
               lineCap={line.tool === 'eraser' ? 'round' : 'square'}
               globalCompositeOperation={
                 'destination-out' 
               }
               closed={line.tool === 'lasso'}
               fill={line.tool === 'lasso' ? '#fff' : null}
               rotation={lastTool === 'eraser' && layer.rotation ? -1 * layer.rotation : 0} // - good
             />,
           )}
         </Group>
         : ImageComponent(
           layer.rotation && !((flipVerticalRequested || flipHorizontalRequested) && selectedLayer.id === layer.id) ? layer.rotation : 0,
           layer.left,
           layer.top,
         )
        } 
      
      {selectedLayer.id === layer.id && <Transformer
        ref={trRef}
        rotateEnabled={tool === 'select'}
        resizeEnabled={tool === 'select'}
        boundBoxFunc={(oldBox, newBox) => {
          // limit resize
          if (newBox.width < 5 || newBox.height < 5) {
            return oldBox;
          }
          return newBox;
        }
      }

      onMouseEnter={e => {
        if(e.target.attrs.name === 'rotater _anchor') {
          const container = e.target.getStage().container();
          if(container.className !== 'cursor-rotated-arrow-clicked') {
            container.className = 'cursor-rotated-arrow';
            container.style = getCursorStyle(layer.rotation);
          }
        }
      }}
      onMouseMove={e => {
        if(e.target.attrs.name === 'rotater _anchor') {
          const container = e.target.getStage().container();
          if(container.className !== 'cursor-rotated-arrow-clicked') {
            container.className = 'cursor-rotated-arrow';
            container.style = getCursorStyle(layer.rotation);
          }
        } else if (e.target.attrs?.name?.split(' ')[1] === '_anchor') {
          const container = e.target.getStage().container();
          if(container.className === 'cursor-rotated-arrow') { 
            container.className = '';
            container.style = '';
          }
        }
      }}
      onMouseDown={e => {
        if(e.target.attrs.name === 'rotater _anchor') {
          const container = e.target.getStage().container();
          container.className = 'cursor-rotated-arrow-clicked';
          const interval = setInterval(() => {
            container.style = getCursorStyle(trRef.current.rotation());
          });
          window.removeEventListener('mouseup', cursorListener);
          cursorListener = function() {
            this.clearInterval(interval);
            container.className = '';
            container.style = '';
          };
          window.addEventListener('mouseup', cursorListener);
        }
      }}
      onMouseLeave={e => {
        const container = e.target.getStage().container();
        if(container.className === 'cursor-rotated-arrow') { 
          container.className = '';
          container.style = '';
        }
      }}
      />}
    </>
  );
};

CanvasImage.propTypes = {
  layer: PropTypes.object,
  selectable: PropTypes.bool,
  lines: PropTypes.array,
  removeLines: PropTypes.func,
  addLine: PropTypes.func,
  stageRef: PropTypes.object,
  imageBackgroundPreview: PropTypes.string,
};

export default CanvasImage;
