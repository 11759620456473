import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@mui/material';

function FormError({ text, width = 400, marginBottom = 16 }) {
  const style = {
    width: Number.isNaN(width) ? width : width + 'px',
    marginBottom: Number.isNaN(marginBottom) ? marginBottom : marginBottom + 'px',
  };

  return (
    <Alert variant="filled" severity="error" style={style}>
      {text}
    </Alert>
  );
}

FormError.propTypes = {
  text: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FormError;
