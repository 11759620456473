import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';

const StyledAutocomplete = styled(Autocomplete)`
    .MuiFormControl-root{
        .MuiOutlinedInput-root {
            padding: 1px 4px;
            background-color: #f5f5f5;
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
        .MuiAutocomplete-popupIndicator {
            svg {
                fill: none;
                path {
                    d: path('M1 1L5 5L9 1');
                    stroke: #1A1C23; 
                    stroke-width: 1.5;
                    stroke-linecap: round;
                    transform: translate(7px, 9px);
                }
            }
        }
    }
`;

const BaseAutocomplete = ({ 
    options, 
    value, 
    width = 248, 
    type = undefined, 
    sx = undefined,
    onChange, 
    onHover, 
    onClose, 
    disablePortal = false,
    disableClearable = false,
    onOpen,
    blurOnSelect = true,
}) => {
    return (
        <StyledAutocomplete
            autoHighlight
            autoSelect
            disablePortal={disablePortal}
            disableClearable={disableClearable}
            sx={sx}
            options={options}
            value={value}
            width={width}
            onChange={(e, value) => onChange(value)}
            onHighlightChange={onHover}
            onClose={onClose}
            renderInput={(params) => 
                <TextField {...params} type={type}/>
            }
            onOpen={onOpen}
            blurOnSelect={blurOnSelect}
        />
    );
  };
  
  BaseAutocomplete.propTypes = {
    options: PropTypes.array, 
    value: PropTypes.string, 
    type: PropTypes.string, 
    width: PropTypes.number, 
    onChange: PropTypes.func,
    onHover: PropTypes.func,
    onClose: PropTypes.func,
    disablePortal: PropTypes.bool,
    disableClearable: PropTypes.bool,
    freeSolo: PropTypes.bool,
    sx: PropTypes.object,
    onOpen: PropTypes.object,
    blurOnSelect: PropTypes.bool,
  };
  
  export default BaseAutocomplete;