import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as DeleteIcon } from "../../assets/times.svg";
import { ReactComponent as RestoreIcon } from "../../assets/restore.svg";
import { deleteProject, fetchDeletedProjects, updateProject } from "../../store/admin";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import BaseListRow from "./BaseListRow";

const Container = styled.div`
  border-top: 2px solid #3C4049;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: #3C4049;
`;

const Note = styled.p`
  font-size: 1.2rem;
  text-align: center;
  color: #3C4049;
  padding: 40px 0;
`;

const Project = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const UserFormDeletedProjects = () => {
  const { userId } = useParams();

  if (isNaN(userId)) {
    return <></>;
  }

  const projects = useSelector(({ admin }) => admin.user.edited.deletedProjects);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDeletedProjects({ id: userId }));
  }, []);

  const removeProject = async ({ id }) => {
    await dispatch(deleteProject(id));
    dispatch(fetchDeletedProjects({ id: userId }));
  };

  const restoreProject = async ({ id }) => {
    await dispatch(updateProject({
      id,
      deleted: 0,
    }));

    dispatch(fetchDeletedProjects({ id: userId }));
  };

  const columns = [
    {
      label: '',
      key: 'preview',
      isImage: true,
      width: 70,
    },
    {
      label: 'Name',
      key: 'name',
      isMain: true,
    },
    {
      label: '',
      icon: <DeleteIcon />,
      action: removeProject,
      width: 30,
    },
    {
      label: '',
      icon: <RestoreIcon />,
      action: restoreProject,
      width: 30,
    },
  ];

  return (
    <Container>
      <Title>
        Deleted Projects
      </Title>
      { projects.length === 0 &&
        <Note>
          This user doesn&apos;t have any deleted project!
        </Note>
      }
      { projects.length > 0 &&
        <Project>
          {
            projects.map((item, index) => (
              <BaseListRow
                key={index}
                item={item}
                columns={columns}
              />
            ))
          }
        </Project>
      }
    </Container>
  );
};

export default UserFormDeletedProjects;