import React, { useEffect } from 'react';
import RecentProjectsNew from './RecentProjectsNew';
import RecentProjectsCard from './RecentProjectsCard';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRecentProjects } from '../../store/project';

const StyledRecentProjects = styled.div`
  grid-area: recent;
  margin-top: 37px;

  section {
    margin-top: 24px;
    display: flex;
    gap: 8px;
  }
`;

const DashboardTitle = styled.h2`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: #3C4049;
`;

function RecentProjects() {
  const projects = useSelector(state => state.project.recentProjects);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRecentProjects());
  }, []);

  return (
    <StyledRecentProjects>
      <DashboardTitle>Recently Opened Projects</DashboardTitle>
      <section>
        <RecentProjectsNew />
        {
          projects.map((project, index) => (
            <RecentProjectsCard
              key={index}
              {...project}
            />
          ))
        }
      </section>
    </StyledRecentProjects>
  );
}

export default RecentProjects;
