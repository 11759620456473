import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BaseMenuTrigger from './BaseMenuTrigger';
import { ReactComponent as TrashIcon } from '../../assets/trash.svg';
import { useDispatch } from 'react-redux';
import { deleteImage } from '../../store/imageImporter';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 13px;
  width: 248px;
  height: 68px;
  border: 1px solid #DCDDE1;
  border-radius: 6px;
  padding: 4px;
`;

const Image = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 2px;
  object-fit: cover;
`;

const Details = styled.div`
  flex: 1;
  font-size: 0.75rem;
  line-height: 0.875rem;
`;

const Name = styled.div`
  font-weight: 500;
  color: #3C4049;
`;

const Size = styled.div`
  font-weight: 400;
  color: #7C8394;
`;

const Menu = styled.div`
  height: 100%;
`;

const BaseImageItem = ({ image, draggable, onDragStart }) => {
  const dispatch = useDispatch();

  const items = [
    {
      icon: <TrashIcon />,
      label: 'Delete',
      action: () => dispatch(deleteImage({ image: image.id })),
    },
  ];

  return (
    <Container
      draggable={draggable}
      onDragStart={onDragStart}
      style={draggable ? { cursor: 'move' } : {}}
    >
      <Image
        src={image.content ? image.content : image.url}
        alt={image.name}
        draggable={false}
      />
      <Details>
        <Name>{ image.name }</Name>
        <Size>{ image.size }Kb</Size>
      </Details>
      <Menu>
        <BaseMenuTrigger items={items} />
      </Menu>
    </Container>
  );
};

BaseImageItem.propTypes = {
  image: PropTypes.object,
  draggable: PropTypes.bool,
  onDragStart: PropTypes.func,
};

export default BaseImageItem;
