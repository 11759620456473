import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@mui/material';
import styled from 'styled-components';

const StyledCheckBox = styled.div`
  display: flex;
  align-items: center;

  span {
    padding: 0 8px 0 0;
    color: #DCDDE1;
  }

  div {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #000;
  }
`;

function BaseCheckBox({ label, onChange }) {
  return (
    <StyledCheckBox>
      <Checkbox onChange={({ target: { checked } }) => onChange(checked)} />
      <div>{ label }</div>
    </StyledCheckBox>
  );
}

BaseCheckBox.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default BaseCheckBox;
