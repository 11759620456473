import React from 'react';
import styled from 'styled-components';
import { ReactComponent as SaveIcon } from '../../assets/toolbar/save.svg';
import BaseButton from '../Common/BaseButton';
import { setSaveRequested } from '../../store/builder';
import { useDispatch } from 'react-redux';

const Container = styled(BaseButton)`
  border: none;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;
  border-radius: 0;
  margin-top: -1px;
  
  svg {
    stroke: #fff;
  }
`;

const ToolbarSave = () => {
  const dispatch = useDispatch();

  return (
    <Container
      width={147}
      height={50}
      onClick={() => dispatch(setSaveRequested(true))}
    >
      Save Project
      <SaveIcon />
    </Container>
  );
};

export default ToolbarSave;
