import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MenuItem, Select } from '@mui/material';
import { ReactComponent as CaretIcon } from '../../assets/caret-down.svg';

const Container = styled.div`
  
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #3C4049;
  margin-bottom: 8px;
`;

const DropDown = styled(Select)`
  background: #F5F5F5;
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  color: #1A1C23 !important;
  height: 40px;
  
  .MuiSelect-icon {
    right: 10px;
    top: calc(50% - 0.3em);
  }

  svg {
    stroke: #1A1C23;
  }
`;

const Item = styled(MenuItem)`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #1A1C23;
`;

const BaseSelect = ({ label, value, options, valueKey = 'id', textKey = 'name', width, onChange }) => {
  return (
    <Container>
      {
        label && <Label>
          { label }
        </Label>
      }
      <DropDown
        value={value}
        style={{ width: width ? width + 'px' : '100%' }}
        IconComponent={CaretIcon}
        onChange={(e) => onChange(e.target.value)}
      >
        {
          options.map((option, index) => (
            <Item value={typeof option !== 'object' ? option : option[valueKey]} key={index}>
              { typeof option !== 'object' ? option : option[textKey] }
            </Item>
          ))
        }
      </DropDown>
    </Container>
  );
};

BaseSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array,
  valueKey: PropTypes.string,
  textKey: PropTypes.string,
  width: PropTypes.number,
};

export default BaseSelect;
