import React, { Fragment, useEffect, useState } from 'react';
import BaseList from "./BaseList";
import { useDispatch, useSelector } from "react-redux";
import { decreaseAssetPage, fetchAssets, increaseAssetPage } from "../../store/admin";
import { ReactComponent as PencilIcon } from "../../assets/pencil.svg";
import { ReactComponent as PreviewIcon } from "../../assets/preview.svg";
import { useNavigate } from "react-router-dom";
import AssetListPreview from "./AssetListPreview";

const AssetList = () => {
  const [previewedAsset, setPreviewedAsset] = useState(null);
  const { page, assets } = useSelector(( { admin }) => admin.asset);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAssets());
  }, []);

  const goToEdit = ({ id }) => {
    navigate('/admin/assets/' + id);
  };

  const showPreview = (item) => {
    setPreviewedAsset(item);
  };

  const previous = () => {
    dispatch(decreaseAssetPage());
    dispatch(fetchAssets());
  };

  const next = () => {
    dispatch(increaseAssetPage());
    dispatch(fetchAssets());
  };

  const columns = [
    {
      label: '',
      key: 'content',
      isBase64: true,
      action: showPreview,
    },
    {
      label: 'Name',
      key: 'name',
      isMain: true,
      action: goToEdit,
    },
    {
      label: '',
      icon: <PreviewIcon />,
      action: showPreview,
    },
    {
      label: '',
      icon: <PencilIcon />,
      action: goToEdit,
    },
  ];

  return (
    <Fragment>
      <BaseList
        columns={columns}
        data={assets}
        currentPage={page}
        previous={previous}
        next={next}
      />
      { previewedAsset !== null &&
        <AssetListPreview
          image={previewedAsset.content}
          name={previewedAsset.name}
          width={1000}
          height={1000}
          open={previewedAsset}
          handleClose={() => setPreviewedAsset(null)}
        />
      }
    </Fragment>
  );
};

export default AssetList;