import React, { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Outlet, Navigate } from 'react-router-dom';
import Background from '../assets/login-bg.svg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../store/user';
import Header from './Header/Header';

const StyledLayout = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  background-size: 60% 100%;
  background-repeat: no-repeat;
  background-image: url(${Background});
  background-position-x: right;
  background-color: #eeeae6;
`;

export const LoggedOutContent = styled.div`
  width: 696px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  h1 {
    width: 400px;
    font-size: 2.5rem;
    line-height: 130%;
    font-weight: 600;
    margin: 0;
  }
  
  h2 {
    width: 400px;
    font-size: 1rem;
    line-height: 1.17rem;
    font-weight: 500;
    padding-bottom: 16px;
    border-bottom: 1px solid #DCDDE1;
    margin-bottom: 16px;
  }
`;

export const LoggedOutActions = styled.div`
  width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 22px;
  margin-bottom: 16px;
`;

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #eeeae6;
  }
`;

const LoggedOutLayout = () => {
  const token = useSelector(state => state.user.token);
  const id = useSelector(state => state.user.id);
  const dispatch = useDispatch();

  useEffect(() => {
    if(token && !id) {
      dispatch(fetchUser());
    }
  }, [token, id]);

  if (token && id) 
    return <Navigate to={'/'} replace />;

  if (token !== null) 
    return <></>;

  return (
    <StyledLayout>
      <GlobalStyle />
      <Header />
      <Outlet />
    </StyledLayout>
  );
};

export default LoggedOutLayout;
