import React from 'react';
import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #7C8394;
  cursor: pointer;
  flex: 1;
  
  &.normal {
    flex: 0 0 ${({ width }) => width}px;
  }
`;

const BaseListHeaderColumn = ({ width, isMain, children }) => {
  return (
    <Container
      className={isMain ? '' : 'normal'}
      width={width}
    >
      {children}
    </Container>
  );
};

BaseListHeaderColumn.propTypes = {
  children: PropTypes.any,
  width: PropTypes.number,
  isMain: PropTypes.bool,
};

export default BaseListHeaderColumn;