import React, { useEffect } from 'react';
import styled from 'styled-components';
import BaseTextBox from '../Common/BaseTextBox';
import BaseButton from '../Common/BaseButton';
import { useDispatch, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { fetchResults, setPerPage, setSearch } from '../../store/pixabay';
import { setDraggedImage } from '../../store/builder';
import Loader from '../Common/Loader';
import { transformToMultiple } from '../../helpers';

const Container = styled.div`
  padding: 12px;
  margin-top: 26px;
`;

const Search = styled.div`
  display: flex;
  padding-top: 20px;
  gap: 10px;
  position: fixed;
  background-color: #fff;
  width: 268px;
  z-index: 99999;
`;

const Results = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  margin-top: 80px;
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 6px;
  cursor: pointer;
  
  &:hover {
    opacity: 0.8;
  }
`;

const ImagePanelPixabay = () => {
  const pixabay = useSelector(state => state.pixabay);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPerPage(
      transformToMultiple(
        Math.round((window.innerHeight - 50) / 30), 3),
      ));
  }, []);

  const { ref } = useInView({
    onChange: (inView) => {
      if (inView && !pixabay.loading) {
        dispatch(fetchResults());
      }
    },
  });

  return (
    <Container>
      <Search>
        <BaseTextBox
          value={pixabay.search}
          placeholder="Search images"
          width={185}
          onChange={(value) => dispatch(setSearch(value))}
          onEnterClick={() => dispatch(fetchResults())}
        />
        <BaseButton
          width={60}
          onClick={() => dispatch(fetchResults())}
        >
          Search
        </BaseButton>
      </Search>
      <Results>
        {
          pixabay.results.map((item, index) => (
            <Image
              draggable
              key={index}
              src={item}
              alt='...'
              onDragStart={() => {
                dispatch(setDraggedImage({ content: item, name: pixabay.search }));
              }}
            />
          ))
        }
      </Results>
      {
        pixabay.page > 0 &&
        <Loader ref={ref} />
      }
    </Container>
  );
};

export default ImagePanelPixabay;
