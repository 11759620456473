import React from 'react';
import styled from 'styled-components';
import TextGeneratorForm from '../components/Builder/TextGeneratorForm';
import TextGeneratorResults from '../components/Builder/TextGeneratorResults';

const GeneratorWrapper = styled.div`
  > div:first-child {
    height: fit-content;
  }
`;

const Generator = () => {
  return (
  <GeneratorWrapper>
    <TextGeneratorForm />
    <TextGeneratorResults />
  </GeneratorWrapper>
  );
};

export default Generator;
