import React, { useEffect } from 'react';
import BaseList from "./BaseList";
import { useDispatch, useSelector } from "react-redux";
import {
  decreaseUserPage,
  fetchUsers,
  increaseUserPage,
  updateUser,
} from "../../store/admin";
import { ReactComponent as PencilIcon } from "../../assets/pencil.svg";
import { useNavigate } from "react-router-dom";

const UserList = () => {
  const { page, users } = useSelector(( { admin }) => admin.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  const toggleEnable = (id, currentValue) => {
    dispatch(updateUser({
      id,
      enabled: !currentValue,
    }));
  };

  const goToEdit = ({ id }) => {
    navigate('/admin/users/' + id);
  };

  const previous = () => {
    dispatch(decreaseUserPage());
    dispatch(fetchUsers());
  };

  const next = () => {
    dispatch(increaseUserPage());
    dispatch(fetchUsers());
  };
  const columns = [
    {
      label: '',
      key: 'avatar',
      isImage: true,
      width: 70,
      action: goToEdit,
    },
    {
      label: 'Name',
      key: 'name',
      isMain: true,
      action: goToEdit,
    },
    {
      label: 'Email',
      key: 'email',
      width: 200,
    },
    {
      label: 'Plan',
      key: 'plan',
      width: 100,
    },
    {
      label: 'End Date',
      key: 'subscriptionEnd',
      width: 100,
      isDate: true,
    },
    {
      label: 'Admin',
      key: 'admin',
      width: 100,
      isBoolean: true,
    },
    {
      label: 'Enabled',
      key: 'enabled',
      width: 100,
      isBoolean: true,
      action: toggleEnable,
    },
    {
      label: '',
      icon: <PencilIcon />,
      action: goToEdit,
      width: 30,
    },
  ];

  return (
    <BaseList
      columns={columns}
      data={users}
      currentPage={page}
      previous={previous}
      next={next}
    />
  );
};

export default UserList;