import React from 'react';
import styled from 'styled-components';
import { Slider } from '@mui/material';
import PropTypes from 'prop-types';
import { styled as muiStyled } from '@mui/material/styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #3C4049;
`;

const Label = styled.div`

`;

const Count = styled.div`

`;

const StyledSlider = muiStyled(Slider)({
  color: '#77B36B',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
    height: 4,
  },
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: '#77B36B',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-rail': {
    backgroundColor: '#DCDDE1',
    height: 4,
  },
});

const BaseSlider = ({ label, name, value, onChange, defaultValue, step, min, max, ...rest }) => {
  return (
    <Container {...rest}>
      {
        label && <LabelContainer>
          <Label>
            { label }
          </Label>
          <Count>
            {value} {name}{value === 1 ? '' : 's'}
          </Count>
        </LabelContainer>
      }
      <StyledSlider
        onChange={(e) => onChange(e.target.value)}
        value={value}
        defaultValue={defaultValue}
        step={step}
        min={min}
        max={max}
      />
    </Container>
  );
};

BaseSlider.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  defaultValue: PropTypes.number,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default BaseSlider;
