import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filters: [
    {
      name: 'Blur',
      parameters: [
        {
          key: 'blurRadius',
          label: 'Blur effect',
          min: 0,
          max: 100,
        },
      ],
    },
    {
      name: 'Brighten',
      parameters: [
        {
          key: 'brightness',
          label: 'Brightness',
          min: 0,
          max: 100,
        },
      ],
    },
    {
      name: 'Contrast',
      parameters: [
        {
          key: 'contrast',
          label: 'Contrast',
          min: 0,
          max: 100,
        },
      ],
    },
    {
      name: 'Emboss',
      parameters: [
        {
          key: 'embossStrength',
          label: 'Strength',
          min: 0,
          max: 100,
        },
        {
          key: 'embossWhiteLevel',
          label: 'White Level',
          min: 0,
          max: 100,
        },
      ],
    },
    {
      name: 'Enhance',
      parameters: [
        {
          key: 'enhance',
          label: 'Enhance',
          min: 0,
          max: 100,
        },
      ],
    },
    {
      name: 'Grayscale',
    },
    {
      name: 'Invert',
    },
    {
      name: 'Noise',
      parameters: [
        {
          key: 'noise',
          label: 'Noise',
          min: 0,
          max: 100,
        },
      ],
    },
    {
      name: 'Saturation',
      parameters: [
        {
          key: 'saturation',
          label: 'Saturation',
          min: 0,
          max: 100,
        },
        {
          key: 'value',
          label: 'Value',
          min: 0,
          max: 100,
        },
        {
          key: 'luminance',
          label: 'Luminance',
          min: 0,
          max: 100,
        },
      ],
    },
  ],
};

export const filter = createSlice({
  name: 'filter',
  initialState,
});

export const {
  setFilter,
} = filter.actions;

export default filter.reducer;
